import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../../../../components/Input";
import styles from "./QuotesForm.module.scss";
import { returnNumber } from "../../../../../../../utils/validations";

function InputField(props) {
  const { languageCode, stageId, personId, rateBandId } = props;

  return (
    <div className={styles.item}>
      <Field
        component={Input}
        max="999"
        min="0"
        name={`quotes[${languageCode}][${stageId}][${personId}][${rateBandId}]`}
        parse={returnNumber}
        step="0.01"
        type="number"
      />
    </div>
  );
}

function AssigneeRow({
  personId,
  firstName,
  lastName,
  languageCode,
  stageId,
  rateBands,
}) {
  return (
    <div className={styles.assigneeRow}>
      <div className={styles.item}>
        {firstName} {lastName}
      </div>

      {rateBands.map(({ rateBandId }) => (
        <InputField
          key={rateBandId}
          personId={personId}
          s
          stageId={stageId}
          rateBandId={rateBandId}
          languageCode={languageCode}
        />
      ))}
    </div>
  );
}

function StageSection({
  stageId,
  languageCode,
  stageName,
  assignees,
  rateBands,
}) {
  const [isOpen, open] = useState(false);
  const toggle = () => {
    open(!isOpen);
  };
  return (
    <div>
      <div className={styles.stageName} onClick={toggle}>
        <span>{stageName}</span>
        <svg className={styles.toggle} height="15px" width="15px">
          {" "}
          {isOpen ? (
            <path d="M1 7h14v2h-14v-2z" fill="#fff" />
          ) : (
            <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#fff" />
          )}
        </svg>
      </div>
      {isOpen &&
        assignees.map(({ firstName, lastName, personId }) => (
          <AssigneeRow
            key={personId}
            firstName={firstName}
            lastName={lastName}
            personId={personId}
            languageCode={languageCode}
            stageId={stageId}
            rateBands={rateBands}
          />
        ))}
    </div>
  );
}

function LanguageSection({ languageCode, languageName, stages, rateBands }) {
  return (
    <div>
      <div className={styles.languageName}>{languageName}</div>

      {stages.map(({ stageId, stageName, assignees }) => (
        <StageSection
          key={stageName}
          stageName={stageName}
          stageId={stageId}
          languageCode={languageCode}
          assignees={assignees}
          rateBands={rateBands}
        />
      ))}
    </div>
  );
}

function QuotesForm({ quotedRateAssignees, handleSubmit, rateBands }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.quotesTable}>
        <div className={styles.headerRow}>
          <div className={styles.item}>Rate bands</div>

          {rateBands.map(({ rateBandId, rateBandName }) => (
            <div key={rateBandId} className={styles.item}>
              {rateBandName}
            </div>
          ))}
        </div>

        {quotedRateAssignees.map(({ languageCode, languageName, stages }) => (
          <LanguageSection
            key={languageName}
            languageName={languageName}
            stages={stages}
            rateBands={rateBands}
            languageCode={languageCode}
          />
        ))}
      </div>

      <div className={styles.buttonContainer}>
        <div className={styles.alignRight}>
          <button className={styles.submit} type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  form: "quotesForm",
  enableReinitialize: true,
})(QuotesForm);
