import { connect } from "react-redux";
import { createSelector } from "reselect";
import ExportFormat from "../components/ExportFormat";
import WithData from "../../../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { updateExportFormat } from "../../../../../../modules/exportFormats";
import {
  accountById,
  isProjectLocalisation,
  orderFormById,
  parentAccountById,
  projectById,
  projectTaskFieldsSelector,
  exportFormatById,
} from "../../../../../../utils/entitySelector";

const initialValuesSelector = createSelector(
  exportFormatById,
  projectTaskFieldsSelector,
  (exportFormat, taskFields) => {
    const { docExportName, docExportTaskFieldId, docExportBriefingFields } =
      exportFormat;
    const tf =
      taskFields.find((tf) => tf.taskFieldId === docExportTaskFieldId) || {};

    return {
      ...exportFormat,
      exportNameConfiguration:
        docExportName === "DELIVERABLE_ID" ? "DELIVERABLE_ID" : tf.taskFieldId,
      docExportBriefingFields: docExportBriefingFields || "REMOVE",
    };
  }
);

const mapDispatchToProps = (dispatch) => ({
  getInitialData: (params) => dispatch(getInitialData(params)),
  updateExportFormat: (values, history) =>
    dispatch(updateExportFormat(values, history)),
});

const mapStateToProps = (state, ownProps) => {
  const {
    history,
    match: { params },
  } = ownProps;
  const projectId = Number(params.projectId);

  const project = projectById(state, projectId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);
  const isLocalisationProject = isProjectLocalisation(state, projectId);
  const initialValues = initialValuesSelector(state, projectId);

  return {
    account,
    isLocalisationProject,
    initialValues,
    history,
    orderForm,
    params,
    parentAccount,
    project,
  };
};

const ConnectedExportFormat = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ExportFormat));
export default ConnectedExportFormat;
