module.exports = {
  frontend: {
    __API_GATEWAY__: process.env.DOMAIN_API_GATEWAY,
    __QUILL_CLOUD_FRONTEND__: process.env.DOMAIN_FRONTEND,
    __SENTRY_DSN__: process.env.SENTRY_DSN,
    __GA_TAG__: process.env.GA_TAG,
    __PROJECT_ID__: process.env.PROJECT_ID,
    __REACT_APP_AUTH0_DOMAIN__: process.env.REACT_APP_AUTH0_DOMAIN,
    __REACT_APP_AUTH0_CLIENT_ID__: process.env.REACT_APP_AUTH0_CLIENT_ID,
    __REACT_APP_API_GATEWAY__: process.env.REACT_APP_API_GATEWAY,
    __REACT_APP_INTERCOM_ID__: process.env.REACT_APP_INTERCOM_ID,
  },
  backend: {
    apiGateway: process.env.DOMAIN_API_GATEWAY,
  },
};
