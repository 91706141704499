export const unallocatedParentDeliverables = (
  parentDeliverables,
  deliverables,
  assignmentLanguageCode,
  isLocalisation
) => {
  const allocatedParentDeliverableIds = {};
  let parentDeliverableCount = 0;

  deliverables.forEach(({ allocated, languageCode, parentDeliverableId }) => {
    if (!isLocalisation) {
      if (allocatedParentDeliverableIds[parentDeliverableId]) return;

      if (allocated && parentDeliverables[parentDeliverableId]) {
        allocatedParentDeliverableIds[parentDeliverableId] = true;
      }
    } else if (
      languageCode === assignmentLanguageCode &&
      !allocated &&
      parentDeliverables[parentDeliverableId]
    ) {
      parentDeliverableCount++;
    }
  });

  if (!isLocalisation) {
    return (
      Object.keys(parentDeliverables).length -
      Object.keys(allocatedParentDeliverableIds).length
    );
  } else {
    return parentDeliverableCount;
  }
};
