import React, { memo } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import Input from "../../../../../../../components/Input";
import styles from "./WordRatesForm.module.scss";
import CurrencyDisplay from "../../../../../../../components/CurrencyDisplay";

const WordRatesForm = (props) => (
  <div className={styles.wordRateContainer}>
    <div className={`${styles.languageContainer} ${styles.header}`}>
      <div className={styles.language}>Language</div>
      <div className={styles.rate}>
        Word rate (<CurrencyDisplay projectId={props.projectId} />)
      </div>
    </div>

    <form onSubmit={props.handleSubmit}>
      {props.projectLanguages.map(({ languageCode, languageName }) => (
        <div key={languageCode} className={styles.languageContainer}>
          <div className={styles.language}>{languageName}</div>
          <div className={styles.rate}>
            <Field
              className={styles.stylelessInput}
              component={Input}
              max="999"
              min="0"
              name={languageCode}
              parse={(value) => Number(value)}
              step="0.001"
              type="number"
            />
          </div>
        </div>
      ))}

      <div className={styles.buttonGroupContainer}>
        <div className={styles.buttonGroupRight}>
          <button className={styles.button} type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
);

WordRatesForm.propTypes = {
  projectLanguages: PropTypes.array,
};

export default reduxForm({
  form: "wordRates",
})(memo(WordRatesForm));
