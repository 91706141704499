import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./CommentGroup.module.scss";
import { UTCToLocalTimeString } from "../../../../../../utils/entitySelector";

function Comment({ userId, comment, isResolved, onCommentArchiveRequest }) {
  const {
    archived,
    commentId,
    commenterFullName,
    createDate,
    personId: commenterId,
    text,
  } = comment;

  const isCurrentUser = commenterId === userId;

  return (
    <div
      key={commentId}
      className={classNames({
        [styles.archived]: archived,
        [styles.comment]: true,
        [styles.isCurrentUser]: isCurrentUser,
        [styles.isResolved]: isResolved,
      })}
    >
      <div className={styles.commenter}>
        <span className={styles.commenterName}>
          {isCurrentUser ? "You" : commenterFullName}{" "}
        </span>
        <span className={styles.timeAgo}>
          {UTCToLocalTimeString(createDate)}
        </span>
      </div>

      <div className={styles.content}>
        {isCurrentUser && !archived && (
          <svg
            className={styles.archiveButton}
            height="12"
            onClick={() => onCommentArchiveRequest(commentId)}
            version="1.1"
            viewBox="0 0 16 16"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* eslint-disable max-len */}
            <path
              d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z"
              fill="#ff0000"
            >
              <title>Delete comment</title>
            </path>
            {/* eslint-enable max-len */}
          </svg>
        )}

        <div
          className={classNames({
            [styles.text]: !isResolved,
            [styles.isResolved]: isResolved,
          })}
        >
          {archived ? "This comment has been deleted" : text}
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape({
    archived: PropTypes.bool.isRequired,
    commentId: PropTypes.number.isRequired,
    commenterFullName: PropTypes.string,
    createDate: PropTypes.string.isRequired,
    personId: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  isResolved: PropTypes.bool.isRequired,
  onCommentArchiveRequest: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default memo(Comment);
