import PropTypes from "prop-types";
import React, { Component } from "react";
import { formatDate } from "../../../utils/date";
import styles from "./BatchFeedbackPanel.module.scss";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import { ModalType } from "../../../modules/modal";

class BatchFeedbackPanel extends Component {
  constructor(props) {
    super(props);

    this.fetchDataInterval = 1000 * 60;
    this.MAX_CHARS = 500;
    this.state = {
      charCount: 0,
      commentToDelete: null,
    };
  }

  async componentDidMount() {
    await this.props.getInitialData();

    // Periodically make new requests so that we do not display stale data
    this.fetchDataPeriodically = setInterval(() => {
      if (this.props.display) {
        this.props.getInitialData();
      }
    }, this.fetchDataInterval);
  }

  componentWillUnmount() {
    clearInterval(this.fetchDataPeriodically);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.display && nextProps.display) {
      this.props.getInitialData();
    }
  }

  addComment = async (e, w) => {
    this.setState({ charCount: 0 });

    const { personId, commentGroupId, createBatchComment, languageCode } =
      this.props;
    const { commentBox } = this.refs;
    const comment = commentBox.value;

    createBatchComment(
      comment,
      personId,
      commentGroupId,
      languageCode,
      commentBox
    );
  };

  handleCloseModal = () => {
    this.setState({ commentToDelete: null });
    this.props.hideModal();
  };

  handleConfirmModal = () => {
    this.props.archiveBatchComment(this.state.commentToDelete);
    this.handleCloseModal();
  };

  onCommentArchiveRequest = (commentId) => {
    this.setState({ commentToDelete: commentId });
    this.props.showModal(ModalType.DeleteBatchFeedback);
  };

  updateCharCount = (e) => {
    const { value } = this.refs.commentBox;
    this.setState({ charCount: value.length });
  };

  render() {
    if (!this.props.display) return null;
    const disabled =
      this.state.charCount > this.MAX_CHARS || this.state.charCount === 0;
    const { personId: loggedInUserId } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.subcontainer}>
          <div className={styles.topPanel}>
            <div className={styles.heading}>Feedback</div>
            <div
              className={styles.close}
              onClick={this.props.hideBatchFeedbackPanel}
            >
              Close
            </div>
          </div>
          <div className={styles.commentsContainer}>
            {this.props.comments &&
              this.props.comments.map(
                ({
                  commentId,
                  commenterFullName,
                  createDate,
                  personId,
                  text,
                }) => (
                  <div key={commentId} className={styles.commentContainer}>
                    <div
                      className={`${styles.commenter} ${
                        this.props.personId === personId ? styles.user : ""
                      }`}
                    >
                      {commenterFullName}
                    </div>
                    <div className={styles.date}>
                      {formatDate(createDate, 19).replace(/T/, " ")}
                    </div>
                    <div className={styles.commentBlock}>
                      {loggedInUserId === personId && (
                        <Icon
                          name="Close"
                          size="lg"
                          onClick={() =>
                            this.onCommentArchiveRequest(commentId)
                          }
                        />
                      )}
                      <div className={styles.comment}>{text}</div>
                    </div>
                  </div>
                )
              )}
          </div>
          <div className={styles.textareaContainer}>
            <textarea
              ref={"commentBox"}
              className={styles.textarea}
              onChange={this.updateCharCount}
            />
            <div className={styles.btnContainer}>
              <div className={styles.charCount}>
                <span className={disabled ? styles.invalid : styles.valid}>
                  {this.state.charCount}
                </span>{" "}
                / {this.MAX_CHARS}
              </div>
              <button
                className={styles.btn}
                disabled={disabled}
                onClick={this.addComment}
              >
                Add comment
              </button>
            </div>
          </div>
        </div>

        {/* Modal confirmation of deleting batch feedback comment */}
        <Modal
          type={ModalType.DeleteBatchFeedback}
          title={"Delete this comment?"}
          body={
            <div>
              Are you sure you want to delete this comment? This cannot be
              undone.
            </div>
          }
          footer={
            <div>
              <button
                className={styles.modalCancel}
                onClick={this.handleCloseModal}
              >
                Cancel
              </button>
              <button
                className={styles.modalConfirm}
                onClick={this.handleConfirmModal}
              >
                Delete
              </button>
            </div>
          }
        />
      </div>
    );
  }
}

BatchFeedbackPanel.propTypes = {
  commentGroupId: PropTypes.number,
  comments: PropTypes.array,
  createBatchComment: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  getInitialData: PropTypes.func.isRequired,
  hideBatchFeedbackPanel: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
  onCommentArchiveRequest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default BatchFeedbackPanel;
