import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  archiveComment,
  createComment,
} from "../../../../../../modules/comments";
import {
  createCommentGroup,
  taskCommentGroupsSelectorFactory,
  resolveCommentGroup,
} from "../../../../../../modules/commentGroups";
import { showModal, hideModal } from "../../../../../../modules/modal";
import { nullArray } from "../../../../../../utils/nullObjects";
import CommentGroup from "../components/CommentGroup";

function mapDispatchToProps(dispatch) {
  return {
    archiveComment: (commentId) => {
      return dispatch(archiveComment(commentId));
    },
    createComment: (commentGroupId, personId, commentText) => {
      return dispatch(createComment(commentGroupId, personId, commentText));
    },
    createCommentGroup: (group) => dispatch(createCommentGroup(group)),
    resolveCommentGroup: (data) => dispatch(resolveCommentGroup(data)),
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
  };
}

/**
 * @param   {Object}    state
 * @param   {number}    deliverableId
 * @param   {number}    stageId
 * @param   {number}    taskFieldId
 * @param   {number}    commentGroupId
 * @returns {Object[]}  comments
 */
const commentsSelector = createSelector(
  taskCommentGroupsSelectorFactory(),
  (_state, _deliverableId, _stageId, _taskFieldId, commentGroupId) =>
    commentGroupId,
  (commentGroups, commentGroupId) => {
    const group = commentGroups[commentGroupId];
    return group ? group.comments : nullArray;
  }
);

const isResolvedSelector = createSelector(
  (state) => state.commentGroups.entities,
  (_state, commentGroupId) => commentGroupId,
  (commentGroups, commentGroupId) => {
    return Object.values(commentGroups).some(
      (cg) => cg.commentGroupId === commentGroupId && cg.resolved
    );
  }
);

function mapStateToProps(
  state,
  { commentGroupId, deliverableId, stageId, taskFieldId }
) {
  const comments = commentsSelector(
    state,
    deliverableId,
    stageId,
    taskFieldId,
    commentGroupId
  );
  const isResolved = isResolvedSelector(state, commentGroupId);

  return {
    comments,
    isResolved,
    modal: state.modal,
    userId: Number(state.me),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentGroup);
