import { connect } from "react-redux";
import {
  batchesDropdownSelector,
  accountById,
  clientDeadlineStagesSelector,
  entityBuilder,
  orderFormById,
  parentAccountById,
  projectBriefingFieldsSelector,
  projectById,
  projectKeywordGroupsSelector,
  rateBandsDropdownSelector,
  sourceFieldsSelector,
} from "../../../../../../../utils/entitySelector";
import { createSelector } from "reselect";
import ParentDeliverablesNew from "../components/ParentDeliverablesNew";

import WithData from "../../../../../../../decorators/WithData";

import { getInitialData } from "../modules/getInitialData";
import { showModal, hideModal } from "../../../../../../../modules/modal";
import { createBatch } from "../../../../../../../modules/batches.js";
import {
  createParentDeliverable,
  clearParentDeliverablesForm,
} from "../../../../../../../modules/parentDeliverables.js";
import parseQuery from "../../../../../../../utils/parseQuery";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    createBatch: (data) =>
      dispatch(createBatch(data, "parentDeliverablesForm")),
    clearParentDeliverablesForm: () => dispatch(clearParentDeliverablesForm()),
    createParentDeliverable: (data) =>
      dispatch(
        createParentDeliverable(data, ownProps.history, ownProps.match.params)
      ),
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
  };
};

const initialValuesSelector = createSelector(
  (briefingFields, keywordGroups, projectId) =>
    briefingFields.map((bf) => ({
      briefingFieldId: bf.briefingFieldId,
      fieldValue: "",
    })),
  (briefingFields, sourceFields, keywordGroups, projectId) =>
    sourceFields.map((sf) => ({
      sourceFieldId: sf.sourceFieldId,
      fieldValue: "",
    })),
  (briefingFields, sourceFields, keywordGroups, projectId) =>
    keywordGroups.map((kg) => ({
      keywordGroupId: kg.keywordGroupId,
      word: [],
    })),
  (briefingFields, sourceFields, keywordGroups, projectId) => projectId,
  (briefingIds, sourceFieldIds, keywordIds, projectId) => ({
    projectId,
    parentDeliverableBriefingFields: briefingIds,
    parentDeliverableSourceFields: sourceFieldIds,
    keywords: keywordIds,
  })
);

const mapStateToProps = (state, ownProps) => {
  const { featureToggles } = state;
  const projectId = Number(ownProps.match.params.projectId);

  const { orderFormId, workflowId, projectName, sourceLanguage } = projectById(
    state,
    projectId
  );
  const batches = batchesDropdownSelector(state, projectId);
  const briefingFields = projectBriefingFieldsSelector(state, projectId);
  const keywordGroups = projectKeywordGroupsSelector(state, projectId);
  const sourceFields = sourceFieldsSelector(state, projectId).filter(
    ({ taskFieldFormat }) => taskFieldFormat !== "Image"
  );
  const initialValues = initialValuesSelector(
    briefingFields,
    sourceFields,
    keywordGroups,
    projectId
  );
  const briefingFieldEntities = entityBuilder(
    briefingFields,
    "briefingFieldId"
  );
  const sourceFieldEntities = entityBuilder(sourceFields, "sourceFieldId");
  const rateBands = rateBandsDropdownSelector(state, projectId);

  const { accountId, orderFormNumber } = orderFormById(state, orderFormId);
  const { accountName, parentAccountId } = accountById(state, accountId);
  const { parentAccountName } = parentAccountById(state, parentAccountId);

  const clientDeadlineStages = clientDeadlineStagesSelector(state, workflowId);

  if (rateBands.length === 1) {
    initialValues.rateBandId = rateBands[0].value;
  }

  initialValues.featureToggles = featureToggles;

  initialValues.batchId = parseQuery(
    ownProps.location.search,
    "batchId",
    Number
  );

  return {
    accountName,
    batches,
    clientDeadlineStages,
    featureToggles,
    initialValues,
    orderFormNumber,
    parentAccountName,
    projectId,
    projectName,
    sourceLanguage,
    workflowId,
    rateBands,
    // this is needed because when the component uses refs and is unaware
    // when the child form has changed. Hence the form 'invalid' params does not updateParentDeliverable
    // passing in the form values allows a rerender.
    briefingFields: briefingFieldEntities,
    form: state.form,
    keywordGroups,
    modal: state.modal,
    sourceFields: sourceFieldEntities,
  };
};

const ParentDeliverableNewWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(ParentDeliverablesNew));
ParentDeliverableNewWithData.getInitialData = getInitialData;
export default ParentDeliverableNewWithData;
