import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { enableBatching } from "redux-batched-actions";
import addSentryUserMiddleware from "./middleware/addSentryUser";
import sentryReduxLogger from "./middleware/sentryReduxLogger";
import modalReducer from "../modules/modal";
import stageCommentGroupReducer from "../modules/stageCommentGroups";
import featureToggleReducer from "../modules/featureToggles";
import constantReducer from "./constantData";
import errorsReducer from "../modules/errors";
import requestTrackerReducer from "../modules/requestTracker";

import {
  accountActionHandlers,
  accountInitialState,
} from "../modules/accounts";
import {
  appComponentActionHandlers,
  appComponentInitialState,
} from "../modules/appComponents";
import {
  assignmentGroupActionHandlers,
  assignmentGroupInitialState,
} from "../modules/assignmentGroups";
import {
  assignmentActionHandlers,
  assignmentInitialState,
} from "../modules/assignments";
import {
  attachmentActionHandlers,
  attachmentInitialState,
} from "../modules/attachments";
import {
  automatedRemindersActionHandlers,
  automatedRemindersInitialState,
} from "../modules/automatedReminders";
import {
  bannedWordActionHandlers,
  bannedWordInitialState,
} from "../modules/bannedWords";
import {
  batchDeadlineActionHandlers,
  batchDeadlineInitialState,
} from "../modules/batchDeadlines";
import { batchActionHandlers, batchInitialState } from "../modules/batches";
import {
  briefingFieldActionHandlers,
  briefingFieldInitialState,
} from "../modules/briefingFields";
import {
  clientBatchDetailsActionHandlers,
  clientBatchDetailsInitialState,
} from "../modules/clientBatchDetails";
import {
  commentGroupActionHandlers,
  commentGroupInitialState,
} from "../modules/commentGroups";
import { commentActionHandlers, commentInitialState } from "modules/comments";
import {
  defaultAssigneeActionHandlers,
  defaultAssigneeInitialState,
} from "../modules/defaultAssignees";
import {
  deliverableActionHandlers,
  deliverableInitialState,
} from "../modules/deliverables";
import {
  dropdownActionHandlers,
  dropdownInitialState,
} from "../modules/dropdowns";
import { editorActionHandlers, editorInitialState } from "modules/editor";
import {
  exportFormatActionHandlers,
  exportFormatInitialState,
} from "../modules/exportFormats";
import {
  freelancerDashboardActionHandlers,
  freelancerDashboardInitialState,
} from "../modules/freelancerDashboard";
import { gCloudActionHandlers, gCloudInitialState } from "../modules/gCloud";
import { gradesActionHandlers, gradesInitialState } from "../modules/grades";
import {
  keywordActionHandlers,
  keywordInitialState,
} from "../modules/keywords";
import {
  keywordGroupActionHandlers,
  keywordGroupInitialState,
} from "../modules/keywordGroups";
import {
  messageActionHandlers,
  messageInitialState,
} from "../modules/messages";
import {
  messageActionHandlers as messageActionHandlersV2,
  messageInitialState as messageInitialStateV2,
} from "../modules/messagesV2";
import { meActionHandlers, meInitialState } from "../modules/me";
import {
  orderFormActionHandlers,
  orderFormInitialState,
} from "../modules/orderForms";
import {
  parentAccountActionHandlers,
  parentAccountInitialState,
} from "../modules/parentAccounts";
import {
  parentDeliverableBriefingFieldActionHandlers,
  parentDeliverableBriefingFieldInitialState,
} from "../modules/parentDeliverableBriefingFields";
import {
  parentDeliverableSourceFieldActionHandlers,
  parentDeliverableSourceFieldInitialState,
} from "../modules/parentDeliverableSourceFields";
import {
  parentDeliverableActionHandlers,
  parentDeliverableInitialState,
} from "../modules/parentDeliverables";
import {
  paymentActionHandlers,
  paymentInitialState,
} from "../modules/payments";
import { peopleActionHandlers, peopleInitialState } from "../modules/people";
import {
  personAccountActionHandlers,
  personAccountInitialState,
} from "../modules/personAccounts";
import {
  personNativeLanguageActionHandlers,
  personNativeLanguageInitialState,
} from "../modules/personNativeLanguages";
import {
  personRolesActionHandlers,
  personRolesInitialState,
} from "../modules/personRoles";
import {
  projectLanguageActionHandlers,
  projectLanguageInitialState,
} from "../modules/projectLanguages";
import {
  quotedRatesActionHandlers,
  quotedRatesInitialState,
} from "../modules/quotedRates";
import {
  projectActionHandlers,
  projectInitialState,
} from "../modules/projects";
import { rateActionHandlers, rateInitialState } from "../modules/rates";
import {
  projectTeamActionHandlers,
  projectTeamInitialState,
} from "../modules/projectTeams";
import {
  rateBandActionHandlers,
  rateBandInitialState,
} from "../modules/rateBands";
import {
  rateBandItemActionHandlers,
  rateBandItemInitialState,
} from "../modules/rateBandItems";
import { searchActionHandlers, searchInitialState } from "../modules/search";
import {
  sourceFieldActionHandlers,
  sourceFieldInitialState,
} from "../modules/sourceFields";
import {
  taskFieldActionHandlers,
  taskFieldInitialState,
} from "../modules/taskFields";
import {
  transitionLogsActionHandlers,
  transitionLogsInitialState,
} from "../modules/transitionLogs";
import {
  taskFieldContentActionHandlers,
  taskFieldContentInitialState,
} from "../modules/taskFieldContent";
import {
  transitionHistoryActionHandlers,
  transitionHistoryInitialState,
} from "../modules/transitionHistory";
import {
  translationsActionHandlers,
  translationsInitialState,
} from "../modules/translations";
import {
  versionHistoryActionHandlers,
  versionHistoryInitialState,
} from "../modules/versionHistory";
import {
  projectTotalsActionHandlers,
  projectTotalsInitialState,
} from "../modules/projectTotals";
import { tabsActionHandlers, tabsInitialState } from "../modules/tabs";
import {
  wordRatesActionHandlers,
  wordRatesInitialState,
} from "../modules/wordRates";
import {
  webSpellCheckerActionHandlers,
  webSpellCheckerInitialState,
} from "../modules/webSpellChecker";
import {
  taskFieldRateBandActionHandlers,
  taskFieldRateBandInitialState,
} from "../modules/taskFieldRateBands";
import {
  grammarCheckIgnoreActionHandlers,
  grammarCheckIgnoreInitialState,
} from "../modules/grammarCheckIgnores";
import {
  emailLogsActionHandlers,
  emailLogsInitialState,
} from "../modules/emailLogs";
import {
  downloadActionHandlers,
  downloadInitialState,
} from "../modules/downloads";
import { hiddenActionHandlers, hiddenInitialState } from "../modules/hidden";
import {
  favouritesActionHandlers,
  favouritesInitialState,
} from "../modules/favourites";
import {
  locationActionHandlers,
  locationInitialState,
} from "../modules/routes";
import {
  glossaryActionHandlers,
  glossaryInitialState,
} from "../modules/glossary";
import {
  aiEnhancementInitialState,
  AiEnhancementHandler,
} from "../modules/AiEnhancement";
import {
  rateBandVolumeActionHandlers,
  rateBandVolumeInitialState,
} from "../modules/rateBandVolume";

let store;

export const makeRootReducer = (asyncReducers) => {
  /* eslint-disable max-len */
  return enableBatching(
    combineReducers({
      accounts: createReducer(
        "accounts",
        accountActionHandlers,
        accountInitialState
      ),
      appComponents: createReducer(
        "appComponents",
        appComponentActionHandlers,
        appComponentInitialState
      ),
      aiEnhancement: createReducer(
        "aiEnhancement",
        AiEnhancementHandler,
        aiEnhancementInitialState
      ),
      assignmentGroups: createReducer(
        "assignmentGroups",
        assignmentGroupActionHandlers,
        assignmentGroupInitialState
      ),
      assignments: createReducer(
        "assignments",
        assignmentActionHandlers,
        assignmentInitialState
      ),
      attachments: createReducer(
        "attachments",
        attachmentActionHandlers,
        attachmentInitialState
      ),
      automatedReminders: createReducer(
        "automatedReminders",
        automatedRemindersActionHandlers,
        automatedRemindersInitialState
      ),
      bannedWords: createReducer(
        "bannedWords",
        bannedWordActionHandlers,
        bannedWordInitialState
      ),
      batches: createReducer("batches", batchActionHandlers, batchInitialState),
      batchDeadlines: createReducer(
        "batchDeadlines",
        batchDeadlineActionHandlers,
        batchDeadlineInitialState
      ),
      briefingFields: createReducer(
        "briefingFields",
        briefingFieldActionHandlers,
        briefingFieldInitialState
      ),
      clientBatchDetails: createReducer(
        "clientBatchDetails",
        clientBatchDetailsActionHandlers,
        clientBatchDetailsInitialState
      ),
      contentTypes: constantReducer("contentTypes"),
      commentGroups: createReducer(
        "commentGroups",
        commentGroupActionHandlers,
        commentGroupInitialState
      ),
      comments: createReducer(
        "comments",
        commentActionHandlers,
        commentInitialState
      ),
      currencies: constantReducer("currencies"),
      defaultAssignees: createReducer(
        "defaultAssignees",
        defaultAssigneeActionHandlers,
        defaultAssigneeInitialState
      ),
      deliverables: createReducer(
        "deliverables",
        deliverableActionHandlers,
        deliverableInitialState
      ),
      dropdowns: createReducer(
        "dropdowns",
        dropdownActionHandlers,
        dropdownInitialState
      ),
      downloads: createReducer(
        "downloads",
        downloadActionHandlers,
        downloadInitialState
      ),
      editor: createReducer("editor", editorActionHandlers, editorInitialState),
      emailLogs: createReducer(
        "emailLogs",
        emailLogsActionHandlers,
        emailLogsInitialState
      ),
      exportFormats: createReducer(
        "exportFormats",
        exportFormatActionHandlers,
        exportFormatInitialState
      ),
      errors: errorsReducer,
      form: formReducer,
      favourites: createReducer(
        "favourites",
        favouritesActionHandlers,
        favouritesInitialState
      ),
      featureToggles: featureToggleReducer,
      freelancerDashboard: createReducer(
        "freelancerDashboard",
        freelancerDashboardActionHandlers,
        freelancerDashboardInitialState
      ),
      gCloud: createReducer("gCloud", gCloudActionHandlers, gCloudInitialState),
      glossary: createReducer(
        "glossary",
        glossaryActionHandlers,
        glossaryInitialState
      ),
      hidden: createReducer("hidden", hiddenActionHandlers, hiddenInitialState),
      grades: createReducer("grades", gradesActionHandlers, gradesInitialState),
      keywordGroups: createReducer(
        "keywordGroups",
        keywordGroupActionHandlers,
        keywordGroupInitialState
      ),
      keywords: createReducer(
        "keywords",
        keywordActionHandlers,
        keywordInitialState
      ),
      languages: constantReducer("languages"),
      location: createReducer(
        "location",
        locationActionHandlers,
        locationInitialState
      ),
      me: createReducer("me", meActionHandlers, meInitialState),
      messages: createReducer(
        "messages",
        messageActionHandlers,
        messageInitialState
      ),
      messagesV2: createReducer(
        "messagesV2",
        messageActionHandlersV2,
        messageInitialStateV2
      ),
      modal: modalReducer,
      orderForms: createReducer(
        "orderForms",
        orderFormActionHandlers,
        orderFormInitialState
      ),
      parentDeliverables: createReducer(
        "parentDeliverables",
        parentDeliverableActionHandlers,
        parentDeliverableInitialState
      ),
      payments: createReducer(
        "payments",
        paymentActionHandlers,
        paymentInitialState
      ),
      people: createReducer("people", peopleActionHandlers, peopleInitialState),
      personAccounts: createReducer(
        "personAccounts",
        personAccountActionHandlers,
        personAccountInitialState
      ),
      personRoles: createReducer(
        "personRoles",
        personRolesActionHandlers,
        personRolesInitialState
      ),
      personNativeLanguages: createReducer(
        "personNativeLanguages",
        personNativeLanguageActionHandlers,
        personNativeLanguageInitialState
      ),
      parentAccounts: createReducer(
        "parentAccounts",
        parentAccountActionHandlers,
        parentAccountInitialState
      ),
      parentDeliverableBriefingFields: createReducer(
        "parentDeliverableBriefingFields",
        parentDeliverableBriefingFieldActionHandlers,
        parentDeliverableBriefingFieldInitialState
      ),
      parentDeliverableSourceFields: createReducer(
        "parentDeliverableSourceFields",
        parentDeliverableSourceFieldActionHandlers,
        parentDeliverableSourceFieldInitialState
      ),
      projectLanguages: createReducer(
        "projectLanguages",
        projectLanguageActionHandlers,
        projectLanguageInitialState
      ),
      projects: createReducer(
        "projects",
        projectActionHandlers,
        projectInitialState
      ),
      projectTeams: createReducer(
        "projectTeams",
        projectTeamActionHandlers,
        projectTeamInitialState
      ),
      projectTotals: createReducer(
        "projectTotals",
        projectTotalsActionHandlers,
        projectTotalsInitialState
      ),
      quotedRates: createReducer(
        "quotedRates",
        quotedRatesActionHandlers,
        quotedRatesInitialState
      ),
      rateBandItems: createReducer(
        "rateBandItems",
        rateBandItemActionHandlers,
        rateBandItemInitialState
      ),
      rateBandVolumes: createReducer(
        "rateBandVolumes",
        rateBandVolumeActionHandlers,
        rateBandVolumeInitialState
      ),
      rateBands: createReducer(
        "rateBands",
        rateBandActionHandlers,
        rateBandInitialState
      ),
      rates: createReducer("rates", rateActionHandlers, rateInitialState),
      requestTracker: requestTrackerReducer,
      stages: constantReducer("stages"),
      stageCommentGroups: stageCommentGroupReducer,
      batchSearch: createReducer(
        "batchSearch",
        searchActionHandlers,
        searchInitialState
      ),
      sourceFields: createReducer(
        "sourceFields",
        sourceFieldActionHandlers,
        sourceFieldInitialState
      ),
      tabs: createReducer("tabs", tabsActionHandlers, tabsInitialState),
      taskFieldContent: createReducer(
        "taskFieldContent",
        taskFieldContentActionHandlers,
        taskFieldContentInitialState
      ),
      taskFields: createReducer(
        "taskFields",
        taskFieldActionHandlers,
        taskFieldInitialState
      ),
      taskFieldRateBands: createReducer(
        "taskFieldRateBands",
        taskFieldRateBandActionHandlers,
        taskFieldRateBandInitialState
      ),
      transitionHistory: createReducer(
        "transitionHistory",
        transitionHistoryActionHandlers,
        transitionHistoryInitialState
      ),
      transitionLogs: createReducer(
        "transitionLogs",
        transitionLogsActionHandlers,
        transitionLogsInitialState
      ),
      transitions: constantReducer("transitions"),
      translations: createReducer(
        "translations",
        translationsActionHandlers,
        translationsInitialState
      ),
      versionHistory: createReducer(
        "versionHistory",
        versionHistoryActionHandlers,
        versionHistoryInitialState
      ),
      webSpellChecker: createReducer(
        "webSpellChecker",
        webSpellCheckerActionHandlers,
        webSpellCheckerInitialState
      ),
      grammarCheckIgnore: createReducer(
        "grammerCheckIgnore",
        grammarCheckIgnoreActionHandlers,
        grammarCheckIgnoreInitialState
      ),
      wordRates: createReducer(
        "wordRates",
        wordRatesActionHandlers,
        wordRatesInitialState
      ),
      workflows: constantReducer("workflows"),
      ...asyncReducers,
    })
  );
  /* eslint-enable max-len */
};
// in development we need to have a global handlers object that
// combines handlers from different pages. This is because
// redux-dev-tools recomputes the entire state history in development on a new page.
// If the new page doesn't have the handler from the previous page
// we get inconsistent states. In prod, there isn't this recomputation so
// it doesn't matter that the new page doesn't have the handler.
// https://github.com/davezuko/react-redux-starter-kit/issues/1215

// Note that initialState will not matter for us because this is a universal app.
// However if you have 2 different initialStates for the same key in development you could have problems.
const handlers = {};

export const createReducer = (key, localHandlers, initialState) => {
  handlers[key] = { ...handlers[key], ...localHandlers };

  const reducer = (state = initialState, action) => {
    const handleFn = handlers[key][action.type];
    return handleFn ? handleFn(state, action) : state;
  };

  return reducer;
};

export const injectReducer = ({ key, actionHandlers, initialState }) => {
  store.asyncReducers[key] = createReducer(key, actionHandlers, initialState);
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, sentryReduxLogger(), addSentryUserMiddleware];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (typeof window !== "undefined") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  return store;
};
