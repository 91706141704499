import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./parentAccountsList.module.scss";
import Helmet from "react-helmet";
import Modal from "../../../../components/Modal";
import debounce from "lodash/debounce";
import PageHeader from "../../../../components/PageHeader";
import NestedParentAccountRow from "./NestedParentAccountRow";
import ParentAccountForm from "./ParentAccountForm";
import AccountForm from "./AccountForm";
import OrderForm from "./OrderForm";
import ProjectCopy from "./ProjectCopy";
import ErrorMessage from "../../../../components/ErrorMessage";
import parseQuery, { handleQuery } from "../../../../utils/parseQuery";
import qs from "query-string";

class ParentAccountsList extends Component {
  constructor(props) {
    super(props);
    this.parentAccountRefs = new Map();
    this.state = {
      filterText: "",
      showCreateOrEditModal: false,
      showDeleteModal: false,
      modalType: null,
      modalId: null,
      modalTitle: null,
      filtered: this.props.parentAccounts,
      createOperation: false,
      expandedId: null,
    };

    this.props.parentAccounts.forEach(({ parentAccountId }) =>
      this.parentAccountRefs.set(parentAccountId, React.createRef())
    );

    this.debouncedSearch = debounce((input) => {
      this.props.searchParentAccounts(input);
    }, 500);
  }

  componentDidMount() {
    const query = parseQuery(this.props.location.search);
    const parentAccountId = query["latestAccount"];
    const hasRef = this.parentAccountRefs.get(Number(parentAccountId));

    if (hasRef) {
      hasRef.current.scrollIntoView();
    } else {
      this.searchInput.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextIds = nextProps.parentAccounts.map((pa) => pa.parentAccountId);
    const currentIds = this.props.parentAccounts.map(
      (pa) => pa.parentAccountId
    );
    const nextSet = new Set(nextIds);
    const currentSet = new Set(currentIds);
    if (
      nextSet.size !== currentSet.size ||
      nextIds.some((id) => !currentSet.has(id)) ||
      currentIds.some((id) => !nextSet.has(id)) ||
      nextProps.parentAccounts !== this.state.filtered
    ) {
      this.filterData(this.state.filterText, nextProps.parentAccounts);
    }
  }

  search = (e) => {
    const { parentAccounts } = this.props;
    const input = e.target.value;

    if (input.length >= 2) {
      this.debouncedSearch(input);
    }

    this.updateQuerySearch("searchTerm", input);
    this.filterData(input, parentAccounts);
  };

  filterData = (filterText, parentAccounts) => {
    const searchObject = parseQuery(this.props.location.search);
    const searchTerm = searchObject["searchTerm"];
    const filtered = searchTerm
      ? parentAccounts.filter(
          ({ parentAccountName }) =>
            parentAccountName.toLowerCase().indexOf(searchTerm?.toLowerCase()) >
            -1
        )
      : parentAccounts;
    this.setState({ filtered, filterText });
  };

  showCreateParentModal = () => {
    const data = {
      modalType: "parentAccount",
      modalTitle: "parent account",
    };
    this.handleCreateOrEdit(data, true);
  };

  handleCreateOrEdit = (data, createOperation) => {
    this.setState({
      showCreateOrEditModal: true,
      showDeleteModal: false,
      modalType: data.modalType,
      modalId: data.modalId,
      modalTitle: data.modalTitle,
      createOperation,
    });
    this.props.showModal();
  };

  handleDelete = (data) => {
    this.setState({
      showCreateOrEditModal: false,
      showDeleteModal: true,
      modalType: data.modalType,
      modalId: data.modalId,
      modalTitle: data.modalTitle,
      name: data.name,
      archiveError: "",
    });
    this.props.showModal();
  };

  // find initialValues for an account or orderForm
  findInitialValues = (entityType, idKey) => {
    let initialValues;
    const entities = entityType === "account" ? "accounts" : "orderForms";
    const parentIds = Object.keys(this.props[entities]);
    for (let i = 0; i < parentIds.length; i++) {
      const parentId = parentIds[i];
      const account = this.props[entities][parentId].find(
        (entity) => entity[idKey] === this.state.modalId
      );

      if (account) {
        if (account["verticalType"] === null) {
          delete account["verticalType"];
        }
        delete account["isFavourite"];
        initialValues = account;
      }
    }
    return initialValues;
  };

  createAccountAndToggle = async (data) => {
    await this.props.createAccount(data);
    await this.props.fetchParentAccountRelationships(this.state.modalId);
  };

  renderCreateOrEditModal = () => {
    if (this.state.modalType === "parentAccount") {
      const initialValues = this.state.createOperation
        ? {}
        : this.props.parentAccounts.reduce((acc, cur) => {
            // Omit isFavourite
            const { isFavourite, ...rest } = cur;
            if (cur.parentAccountId === this.state.modalId) {
              acc = rest;
            }
            return acc;
          }, {});
      const handleSubmit = this.state.createOperation
        ? this.props.createParentAccount
        : this.props.updateParentAccount;
      const header = this.state.createOperation
        ? "New parent account"
        : "Edit parent account";

      return (
        <ParentAccountForm
          header={header}
          hideModal={this.props.hideModal}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      );
    } else if (this.state.modalType === "account") {
      const initialValues = this.state.createOperation
        ? { parentAccountId: this.state.modalId }
        : this.findInitialValues("account", "accountId");

      const handleSubmit = this.state.createOperation
        ? this.createAccountAndToggle
        : this.props.updateAccount;
      const header = this.state.createOperation
        ? "New account"
        : "Edit account";

      return (
        <AccountForm
          header={header}
          hideModal={this.props.hideModal}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      );
    } else if (this.state.modalType === "orderForm") {
      const initialValues = this.state.createOperation
        ? { accountId: this.state.modalId }
        : this.findInitialValues("orderForms", "orderFormId");

      const handleSubmit = this.state.createOperation
        ? this.props.createOrderForm
        : this.props.updateOrderForm;
      const header = this.state.createOperation
        ? "New order form"
        : "Edit order form";

      return (
        <OrderForm
          header={header}
          hideModal={this.props.hideModal}
          accounts={this.props.dropDownAccounts}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          orderFormEntities={this.props.orderFormEntities}
        />
      );
    } else if (this.state.modalType === "projectCopy") {
      const initialValues = this.state.createOperation
        ? { accountId: this.state.modalId }
        : this.findInitialValues("orderForms", "orderFormId");

      const handleSubmit = this.props.copyProject;
      const header = "Copy project";

      return (
        <ProjectCopy
          header={header}
          hideModal={this.props.hideModal}
          accountId={this.state.modalId}
          orderForms={this.props.dropDownOrderFormsByAccount}
          projects={this.props.dropDownProjectsByOrderForm}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      );
    }
  };

  archiveObject = () => {
    let archiveFunc;
    if (this.state.modalType === "parentAccount") {
      archiveFunc = this.props.archiveParentAccount;
    } else if (this.state.modalType === "account") {
      archiveFunc = this.props.archiveAccount;
    } else if (this.state.modalType === "orderForm") {
      archiveFunc = this.props.archiveOrderForm;
    }
    this.setState({ archiveError: "" });
    return archiveFunc(this.state.modalId)
      .then(() => this.props.hideModal())
      .catch((err) => this.setState({ archiveError: err.stack }));
  };

  setExpandedId = (parentAccountName, parentAccountId) => {
    this.updateQuerySearch(parentAccountName, parentAccountId);
  };

  updateUrl = (searchObject) => {
    const search = qs.stringify(searchObject);

    // only push a new history state if the params are different
    if (this.props.location.search !== `?${search}`) {
      this.props.history.push({ search });
    }
  };

  updateQuerySearch = (term, value) => {
    const searchObject = parseQuery(this.props.location.search);
    const key = searchObject[term];

    if (term === "searchTerm") {
      this.updateUrl({ ...searchObject, searchTerm: value });
    } else {
      if (!key) {
        const newQuery = handleQuery(searchObject, term, value);
        this.updateUrl({ ...newQuery, latestAccount: value });
      } else {
        delete searchObject[term];
        delete searchObject["latestAccount"];
        this.updateUrl(searchObject);
      }
    }
  };

  render() {
    return (
      <div className={styles.content}>
        <Helmet>
          {" "}
          <title>Parent accounts</title>{" "}
        </Helmet>
        <PageHeader title={"Parent accounts"}>
          <div className={styles.headerRight}>
            <div className={styles.searchContainer}>
              <input
                ref={(node) => {
                  this.searchInput = node;
                }}
                className={styles.searchInput}
                onChange={this.search}
                placeholder={"Search"}
              />
              <svg
                className={styles.searchIcon}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
              >
                {/* eslint-disable max-len */}
                <path
                  fill={this.state.filterText === "" ? "#000" : "transparent"}
                  d="M15.504 13.616l-3.79-3.223c-0.392-0.353-0.811-0.514-1.149-0.499 0.895-1.048 1.435-2.407 1.435-3.893 0-3.314-2.686-6-6-6s-6 2.686-6 6 2.686 6 6 6c1.486 0 2.845-0.54 3.893-1.435-0.016 0.338 0.146 0.757 0.499 1.149l3.223 3.79c0.552 0.613 1.453 0.665 2.003 0.115s0.498-1.452-0.115-2.003zM6 10c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"
                />
                {/* eslint-enable max-len */}
              </svg>
            </div>
            <button
              className={styles.newParentAccount}
              onClick={this.showCreateParentModal}
            >
              Create new
            </button>
          </div>
        </PageHeader>
        {this.props.modal.display && this.state.showCreateOrEditModal && (
          <Modal
            large
            display={this.props.modal.display}
            body={this.renderCreateOrEditModal()}
          />
        )}
        {this.props.modal.display && this.state.showDeleteModal && (
          <Modal
            title={`Delete a ${this.state.modalTitle}`}
            display={this.props.modal.display}
            body={
              <div>
                {" "}
                Are you sure you want to delete the {this.state.modalTitle}{" "}
                {this.state.name}? This cannot be undone.
              </div>
            }
            footer={
              <div>
                {this.state.archiveError && (
                  <ErrorMessage
                    title={`Sorry there was an error archiving ${this.state.modalTitle}:`}
                    main={this.state.archiveError}
                    footer="Please try again at a later time."
                  />
                )}
                <button
                  className={styles.modalCancel}
                  onClick={this.props.hideModal}
                >
                  Cancel
                </button>
                <button
                  className={styles.modalConfirm}
                  onClick={this.archiveObject}
                >
                  Delete
                </button>
              </div>
            }
          />
        )}

        <div className={styles.container}>
          {this.state.filtered.map((parentAccount) => {
            return (
              <NestedParentAccountRow
                ref={this.parentAccountRefs.get(parentAccount.parentAccountId)}
                key={parentAccount.parentAccountId}
                accounts={this.props.accounts}
                orderForms={this.props.orderForms}
                projects={this.props.projects}
                handleCreateOrEdit={this.handleCreateOrEdit}
                handleDelete={this.handleDelete}
                defaultCollapsed
                setExpandedId={this.setExpandedId}
                hide={this.props.hide}
                isFavourite={parentAccount.isFavourite}
                favourite={this.props.favourite}
                unfavourite={this.props.unfavourite}
                searchObject={parseQuery(this.props.location.search)}
                {...parentAccount}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

ParentAccountsList.propTypes = {
  parentAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      parentAccountId: PropTypes.number.isRequired,
      parentAccountName: PropTypes.string.isRequired,
    })
  ).isRequired,
  accounts: PropTypes.object.isRequired,
  orderForms: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  dropDownAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  fetchParentAccountRelationships: PropTypes.func.isRequired,
  searchParentAccounts: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  createParentAccount: PropTypes.func.isRequired,
  updateParentAccount: PropTypes.func.isRequired,
  archiveParentAccount: PropTypes.func.isRequired,
  createAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  archiveAccount: PropTypes.func.isRequired,
  createOrderForm: PropTypes.func.isRequired,
  updateOrderForm: PropTypes.func.isRequired,
  archiveOrderForm: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool,
  }).isRequired,
};

export default ParentAccountsList;
