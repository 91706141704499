import { connect } from "react-redux";
import Glossary from "../components/Glossary.js";
import WithData from "../../../../decorators/WithData";
import { getInitialData } from "../modules/getInitialData";
import { languagesDropdownSelector } from "../../../../utils/entitySelector";
import {
  downloadGlossary,
  EXCLUDE_KEYS,
  removeTranslation,
  updateGlossary,
  uploadGlossary,
} from "../../../../modules/glossary.js";
import { createSelector } from "reselect";

export const translationSelector = createSelector(
  (state) => state.glossary.entities,
  (_, accountId) => accountId,
  (translations, accountId) => {
    return Object.values(translations).filter(
      (translation) =>
        translation.accountId === Number(accountId) && !translation.archived
    );
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInitialData: () => dispatch(getInitialData(ownProps)),
    uploadGlossary: (data) => {
      dispatch(uploadGlossary(data, ownProps));
    },
    downloadGlossary: (formName) => {
      dispatch(downloadGlossary(formName));
    },
    updateGlossary: () => dispatch(updateGlossary(ownProps)),
    removeTranslation: (sourceSegment) =>
      dispatch(removeTranslation(sourceSegment, ownProps)),
  };
};

const mapStateToProps = (state, { match: { params }, location }) => {
  const accountId = params.accountId;

  const { accountName, parentAccountId } =
    state.accounts.entities[accountId] || {};
  const { parentAccountName } =
    state.parentAccounts.entities[parentAccountId] || {};

  const glossaryLanguageSelector = createSelector(
    translationSelector,
    (glossaryValues) => {
      const languages = Object.values(glossaryValues)
        .flatMap(Object.keys) // Get all keys from all objects
        .filter((key) => !EXCLUDE_KEYS.includes(key)); // Filter out excluded keys

      return Array.from(new Set(languages)); // Remove duplicates
    }
  );

  return {
    parentAccountName,
    accountName,
    initialValues: { translations: translationSelector(state, accountId) },
    glossaryLanguages: glossaryLanguageSelector(state, accountId),
    languages: languagesDropdownSelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithData(Glossary));
