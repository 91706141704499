import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./FieldComments.module.scss";
import { getBounds } from "../../../../../utils/dom";
import CommentGroup from "../CommentGroup";

const FieldComments = ({
  commentLength,
  deliverableId,
  fieldCommentGroupId,
  stageId,
  taskFieldId,
}) => {
  const [selectedCommentGroupPosition, setCommentGroupPosition] = useState(
    null
  );

  const onClick = (e) => {
    e.preventDefault();

    setCommentGroupPosition(getBounds(e.target));
  };

  function closeCommentBox() {
    setCommentGroupPosition(null);
  }

  return (
    <div className={styles.fieldComments}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classNames({
          [styles.commentsLink]: true,
          [styles.hasComments]: commentLength > 0,
        })}
        href="#"
        onClick={onClick}
      >
        Comments ({commentLength})
      </a>

      {selectedCommentGroupPosition && (
        <CommentGroup
          commentGroupId={fieldCommentGroupId}
          commentType={"field"}
          deliverableId={deliverableId}
          isCommentable
          onClose={closeCommentBox}
          position={selectedCommentGroupPosition}
          stageId={stageId}
          taskFieldId={taskFieldId}
        />
      )}
    </div>
  );
};

FieldComments.propTypes = {
  commentLength: PropTypes.number.isRequired,
  deliverableId: PropTypes.number.isRequired,
  fieldCommentGroupId: PropTypes.number,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
};

export default memo(FieldComments);
