import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, change } from "redux-form";
import { QuillFormField } from "../../../components/QuillForm";
import { InputWithErrors } from "../../../decorators/WithErrors";
import { currentISODate } from "../../../utils/date";
import styles from "./StageSection.module.scss";
import StageHeader from "./StageHeader";
import FeatureToggle from "../../FeatureToggle";
import { useDispatch } from "react-redux";

// the "allocations" column will always show
export const DEFAULT_COLUMN_COUNT = 1;

const StageSection = (props) => {
  const {
    defaultAssignees,
    featureToggles,
    isLocalisation,
    languageCode: assignmentLanguageCode,
    numParentDeliverables,
    people,
    personGrades,
    reassignment,
    selectedAssignment,
    stage,
    stageData,
    trainingStage,
  } = props;

  const dispatch = useDispatch();

  const inTraining = stage.stageType === "Training";

  let { projectLanguages } = props;

  // Check if we need to show this stage
  if (inTraining && !trainingStage) {
    // If all of the languages have no training deliverables associated then we don't need to display this training stage
    const trainingCount = projectLanguages.reduce(
      (acc, pl) => acc + (stage.deliverableAmount?.[pl.languageCode] || 0),
      0
    );
    if (trainingCount === 0) {
      return null; // do not display anything for this stage
    }
  }

  // If reassigning from a trainee, we do not show reassignments
  if (selectedAssignment && selectedAssignment.inTraining && inTraining) {
    return null;
  }

  const stageLanguageCode =
    assignmentLanguageCode || projectLanguages[0].languageCode;
  const languageData = (stageData && stageData[stageLanguageCode]) || {};
  const totalAllocations = Object.keys(languageData).reduce(
    (acc, personId) => acc + (languageData[personId].allocation || 0),
    0
  );

  const numberToAssign = inTraining
    ? stage.deliverableAmount
      ? stage.deliverableAmount[stageLanguageCode]
      : numParentDeliverables
    : numParentDeliverables;

  const remainingAllocations =
    numberToAssign < totalAllocations
      ? `over assigned by ${totalAllocations - numberToAssign}`
      : numberToAssign - totalAllocations;

  projectLanguages =
    assignmentLanguageCode && isLocalisation
      ? projectLanguages.filter(
          (language) => language.languageCode === assignmentLanguageCode
        )
      : projectLanguages;

  /**
   * This function determines how many grid inputs we need to display (is different for stages with rates/training stages etc.)
   *
   * @return {Object.trainingCheckbox} the input element to display if in training
   * @return {Object.gridStyles} a styles element for the amount of inputs in the grid
   */
  const gridTemplateColumns = () => {
    const { hasTrainingStage } = stage;

    const qualityGradeColumn = featureToggles.QCC_1798_freelancerGrading && (
      <div className={styles.grade} title="Quality grade">
        Q*
      </div>
    );

    const trainingCheckbox = hasTrainingStage && (
      <div className={styles.training}>T</div>
    );

    const showAvailableColumn =
      !!featureToggles.allocationScaling && !reassignment;

    const inputCount =
      DEFAULT_COLUMN_COUNT +
      (showAvailableColumn ? 1 : 0) +
      !!qualityGradeColumn +
      !!trainingCheckbox;

    const gridStyles = classNames({
      [styles.gridInputs1]: inputCount === 1,
      [styles.gridInputs2]: inputCount === 2,
      [styles.gridInputs3]: inputCount === 3,
      [styles.gridInputs4]: inputCount === 4,
    });

    return { gridStyles, qualityGradeColumn, trainingCheckbox };
  };

  const onChangeAllocation = () => {
    dispatch(
      change(
        "assignmentsForm",
        `assistedDistributions[${stage.stageId}]`,
        false
      )
    );
  };

  /**
   *
   * @param {*} personId the personId of the default assignee
   * @param {*} languageCode the language for the assignments being assigned
   * @param {*} disabled whether the inputs are disabled
   */
  const renderDefaultAssigneeStageRow = (personId, languageCode, disabled) => {
    const { gridStyles, qualityGradeColumn, trainingCheckbox } =
      gridTemplateColumns();
    const { qualityGrade } = personGrades[personId] || {};
    return (
      <div
        key={`[${stage.stageId}][${languageCode}][${personId}]`}
        className={`${gridStyles} ${styles.gridRow}`}
      >
        <div>
          {people[personId].firstName + " " + people[personId].lastName}
        </div>

        <FeatureToggle toggle="allocationScaling">
          {!reassignment && (
            <QuillFormField
              component={InputWithErrors}
              customContainerStyle={styles.inputContainer}
              disabled={disabled}
              name={`assignees[${stage.stageId}][${languageCode}][${personId}][available]`}
              styles={styles.input}
              type="checkbox"
            />
          )}
        </FeatureToggle>

        {qualityGradeColumn && (
          <div
            className={styles.gradeCell}
            title="Quality grade for this order form"
          >
            {qualityGrade || "-"}
          </div>
        )}

        {!!trainingCheckbox && (
          <QuillFormField
            component={InputWithErrors}
            customContainerStyle={styles.inputContainer}
            disabled={disabled}
            name={`assignees[${stage.stageId}][${languageCode}][${personId}][training]`}
            styles={styles.input}
            type="checkbox"
          />
        )}
        <QuillFormField
          component={InputWithErrors}
          customContainerStyle={styles.inputContainer}
          disabled={disabled}
          min={0}
          name={`assignees[${stage.stageId}][${languageCode}][${personId}][allocation]`}
          parse={(val) => Math.min(Number(val || 0), numParentDeliverables)}
          styles={styles.input}
          onChange={onChangeAllocation}
          type="number"
        />
      </div>
    );
  };

  const allocationStyles = classNames({
    [styles.allocation]: true,
    [styles.warn]: remainingAllocations !== 0,
  });

  return (
    <div className={styles.stageContainer}>
      <div className={styles.stageHeader}>
        <div className={styles.stageLabel}>
          <span>{stage.stageName}</span>
          <span
            className={allocationStyles}
          >{`(${remainingAllocations})`}</span>
        </div>
        <Field
          component={InputWithErrors}
          disabled={Number(numParentDeliverables) === 0}
          max="9999-12-31T00:00"
          min={currentISODate()}
          name={`deadlines[${stage.stageId}]`}
          styles={styles.date}
          type="datetime-local"
        />
      </div>

      <div>
        {projectLanguages.map(({ languageCode }, idx) => {
          return (
            <div
              key={`${languageCode}[${idx}]`}
              className={styles.languageContainer}
            >
              <StageHeader
                languageData={languageData}
                featureToggles={featureToggles}
                defaultAssignees={
                  defaultAssignees?.[stage.stageId]?.[languageCode] || []
                }
                stage={stage}
                languageCode={languageCode}
                reassignment={reassignment}
              />

              {defaultAssignees[stage.stageId] &&
                defaultAssignees[stage.stageId][languageCode] &&
                defaultAssignees[stage.stageId][languageCode].map(
                  ({ personId }) => {
                    return (
                      people[personId] &&
                      renderDefaultAssigneeStageRow(personId, languageCode)
                    );
                  }
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

StageSection.propTypes = {
  defaultAssignees: PropTypes.object.isRequired,
  featureToggles: PropTypes.shape({
    QCC_1798_freelancerGrading: PropTypes.bool,
  }),
  isLocalisation: PropTypes.bool,
  numParentDeliverables: PropTypes.number.isRequired,
  people: PropTypes.object.isRequired,
  personGrades: PropTypes.object.isRequired,
  projectLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      languageCode: PropTypes.string.isRequired,
    })
  ).isRequired,
  languageCode: PropTypes.string,
  reassignment: PropTypes.bool,
  selectedAssignment: PropTypes.object,
  selectedBatchId: PropTypes.number,
  stage: PropTypes.shape({
    chargeable: PropTypes.bool,
    deliverableAmount: PropTypes.object,
    hasTrainingStage: PropTypes.bool,
    stageId: PropTypes.number.isRequired,
    stageName: PropTypes.string.isRequired,
    stageType: PropTypes.string.isRequired,
  }).isRequired,
  stageData: PropTypes.object,
  trainingStage: PropTypes.bool,
};

export default StageSection;
