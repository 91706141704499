import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { renderBriefingFieldValue } from "../../../../../utils/renderBriefingFieldValue";
import styles from "./BriefingFieldTab.module.scss";
import WordsChecker from "./WordsChecker";
import AttachmentThumbnails from "../../../../AttachmentThumbnails/index";
import FeatureToggle from "../../../../FeatureToggle/components/FeatureToggle";

const BriefingFieldTab = (props) => {
  const {
    briefingFieldInfo,
    deliverableId,
    keywords,
    keywordsContent,
    bannedWords,
    bannedWordsContent,
    personType,
    isWithClient,
    stageId,
    parentDeliverableId,
  } = props;

  return (
    <div className={styles.scrollingContainer}>
      {deliverableId && (
        <div className={styles.briefingInfo}>
          <div className={styles.label}>Deliverable Id</div>
          {deliverableId}
        </div>
      )}

      {briefingFieldInfo.map(
        ({
          briefingFieldId,
          fieldValue,
          briefingFieldName,
          briefingFieldFormat,
        }) => (
          <div key={briefingFieldId} className={styles.briefingInfo}>
            <div className={styles.label}>{briefingFieldName}</div>
            {renderBriefingFieldValue(fieldValue, briefingFieldFormat)}
          </div>
        )
      )}

      <WordsChecker
        content={keywordsContent}
        emptyMessage="No keywords"
        highlightClass="hasKeyword"
        noUsedMessage="No keywords have been used"
        title="Keywords"
        words={keywords}
      />

      <WordsChecker
        content={bannedWordsContent}
        emptyMessage="No banned words"
        highlightClass="hasBannedWord"
        noUsedMessage="No banned words have been used"
        title="Banned words"
        words={bannedWords}
      />

      <FeatureToggle toggle="attachmentUpload">
        {parentDeliverableId && (
          <AttachmentThumbnails parentDeliverableId={parentDeliverableId} />
        )}
      </FeatureToggle>

      {personType === "Client" && isWithClient && (
        <Link
          className={styles.linkButton}
          target="_blank"
          to={`/clients/deliverable/${deliverableId}/stage/${stageId}/version-history`}
        >
          Version history
        </Link>
      )}
    </div>
  );
};

BriefingFieldTab.propTypes = {
  bannedWords: PropTypes.array.isRequired,
  bannedWordsContent: PropTypes.array.isRequired,
  briefingFieldInfo: PropTypes.array.isRequired,
  deliverableId: PropTypes.number,
  isWithClient: PropTypes.bool.isRequired,
  keywords: PropTypes.array.isRequired,
  keywordsContent: PropTypes.array.isRequired,
  personType: PropTypes.string.isRequired,
  stageId: PropTypes.number,
};

export default BriefingFieldTab;
