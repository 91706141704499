import addTrainingDeliverablesToStages from "./utility";

const validate = (values, props, c, d) => {
  const errors = {};
  const { assignees, deadlines } = values;
  const {
    numParentDeliverables,
    stages,
    assignmentData,
    trainingStage,
    selectedAssignment,
    languageCode: assignmentLanguageCode,
  } = props;

  if (!assignees || Object.keys(assignees).length === 0) return {};

  // TEMP FIX -- languageCodes will be all the languages in the Writing stage
  const languageCodes = assignees[Object.keys(assignees)[0]];

  // add the training deliverable count to the 'training' stages
  const stagesWithTrainingDeliverables =
    assignmentData && !trainingStage
      ? addTrainingDeliverablesToStages(stages, assignmentData)
      : stages;

  // We don't want to check every stage as sometimes training stages will have data and other times they wont
  const stagesToValidate = stagesWithTrainingDeliverables.filter((s) => {
    if (s.stageType !== "Training" && !trainingStage) return true;
    if (trainingStage && s.stageType !== "Training") return false;
    if (trainingStage && s.stageType === "Training") return true;

    // Only validate training stages if they have any deliverables
    let count = 0;
    for (const lc in s.deliverableAmount) {
      count += s.deliverableAmount[lc];
    }
    return count > 0;
  });

  // Check the deadlines are set and are in chronological order
  if (deadlines) {
    stagesToValidate.forEach((s, idx) => {
      if (
        selectedAssignment &&
        selectedAssignment.inTraining &&
        s.stageId !== selectedAssignment.stageId
      )
        return;
      if (!deadlines[s.stageId]) {
        errors._error = `Please make sure you provide a deadline for each stage of production.`;
      } else {
        const previousStage = stagesToValidate[idx - 1];
        const previousDeadlineISO = previousStage
          ? deadlines[previousStage.stageId]
          : null;
        const currentDeadlineISO = deadlines[s.stageId];

        const previousDeadline = previousDeadlineISO
          ? new Date(previousDeadlineISO).getTime()
          : null;
        const currentDeadline = currentDeadlineISO
          ? new Date(currentDeadlineISO).getTime()
          : null;

        if (previousDeadline > currentDeadline) {
          errors._error = `Please make sure that your deadlines are in the correct chronological order.`;
        }
      }
    });
  }

  // Check that the correct amount of deliverables are set in each stage
  stagesToValidate.forEach((stage) => {
    if (
      selectedAssignment &&
      selectedAssignment.inTraining &&
      stage.stageId !== selectedAssignment.stageId
    )
      return;
    const stageValues = assignees[stage.stageId] || {};

    // Count total deliverables in each language
    for (const languageCode in languageCodes) {
      // TEMP
      if (assignmentLanguageCode === languageCode || !assignmentLanguageCode) {
        let count = 0;

        for (const personId in stageValues[languageCode]) {
          if (stageValues[languageCode][personId].allocation) {
            count += stageValues[languageCode][personId].allocation;
          }
        }

        if (props.reassignment) {
          if (!trainingStage && stage.stageType === "Training" && count !== 0) {
            const associatedStage = stages.find(
              (s) => s.stagePosition === stage.stagePosition - 1
            );
            const associatedAssignment =
              assignees[associatedStage.stageId][languageCode];
            const associatedAssignmentCount = Object.keys(
              associatedAssignment
            ).reduce((acc, key) => {
              const assignment = associatedAssignment[key];
              if (assignment.allocation && assignment.training)
                acc += Number(assignment.allocation);
              return acc;
            }, 0);
            if (count !== associatedAssignmentCount) {
              errors._error = `Please reassign ${associatedAssignmentCount} deliverables in the training stage`;
            }
          } else if (count === 0) {
            errors._error = `Please reassign at least 1 deliverable.`;
          } else if (count > Number(numParentDeliverables)) {
            errors._error = `Please reassign at most ${numParentDeliverables} deliverable${
              numParentDeliverables > 1 ? "s" : ""
            }.`;
          }
        } else {
          if (stage.stageType !== "Training") {
            if (count !== Number(numParentDeliverables)) {
              errors._error = `Please make sure you have assigned ${numParentDeliverables}
               deliverable${
                 numParentDeliverables > 1 ? "s" : ""
               } for each stage`;
            }
          } else {
            const trainingDeliverableCount =
              stage.deliverableAmount[languageCode];
            if (count !== trainingDeliverableCount) {
              errors._error = `Please make sure you have assigned the correct amount of deliverables for a training stage`;
            }
          }
        }
      }
    }
  });

  return errors;
};

export default validate;
