import {
  checkValidations,
  isNotBlank,
} from "../../../../../../../utils/validations";

const validate = ({ batchId, rateBandId, featureToggles } = {}) => {
  const errors = {};

  const batchIdErrors = checkValidations(batchId, [
    { validate: isNotBlank, msg: "Required" },
  ]);

  if (batchIdErrors) errors.batchId = batchIdErrors;

  if (featureToggles && featureToggles.QCC_1081_rateBands) {
    const rateBandIdErrors = checkValidations(rateBandId, [
      { validate: isNotBlank, msg: "Required" },
    ]);
    if (rateBandIdErrors) errors.rateBandId = rateBandIdErrors;
  }

  return errors;
};

export default validate;
