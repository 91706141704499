import React, { Component } from "react";
import PropTypes from "prop-types";
import { initialize, reduxForm } from "redux-form";
import {
  SelectWithErrors,
  CreatableWithErrors,
} from "../../../../../../../decorators/WithErrors";
import styles from "./ParentDeliverablesForm.module.scss";
import validate from "./validations";
import {
  QuillForm,
  QuillFormFieldArray,
  QuillFormField,
} from "../../../../../../../components/QuillForm";
import FieldArrayWithErrors from "./FieldArray";
import CreateBatchRow from "../../../../../../../components/Buttons/CreateBatchRow";
import { formatDate } from "../../../../../../../utils/date";

class ParentDeliverablesForm extends Component {
  componentDidMount() {
    this.initializeFormIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.edit &&
      prevProps.initialValues !== this.props.initialValues
    ) {
      this.initializeFormIfNeeded();
    }
  }

  initializeFormIfNeeded() {
    const { initialValues, dispatch } = this.props;
    if (initialValues) {
      dispatch(initialize("parentDeliverablesForm", initialValues));
    }
  }

  render() {
    const { featureToggles, projectId, updateDate } = this.props;
    const formattedUpdateDate = updateDate ? formatDate(updateDate) : null;
    return (
      <div className="parent-deliverables-form">
        <QuillForm
          disabled={this.props.submitting || !this.props.valid}
          error={this.props.error}
          handleSubmit={this.props.handleSubmit}
          header={this.props.header}
          updateDate={formattedUpdateDate}
          hideButtons
          rebrandingContainer
        >
          {this.props.edit ? (
            <p className={styles.batchLabel}>Batch</p>
          ) : (
            <CreateBatchRow projectId={projectId} />
          )}
          <QuillFormField
            component={SelectWithErrors}
            disabled={this.props.edit}
            name="batchId"
            options={this.props.batches}
            type="number"
          />

          <QuillFormFieldArray
            component={FieldArrayWithErrors}
            customContainerStyle={styles.margin}
            entities={this.props.briefingFields}
            entityId="briefingFieldId"
            fieldKey="fieldValue"
            labelKey="briefingFieldName"
            name="parentDeliverableBriefingFields"
            title="Briefing fields"
          />

          {this.props.initialValues.keywords.length > 0 && (
            <div className={styles.margin}>
              <div className={styles.marginBottom}>
                <label className={styles.label}>Keywords</label>
              </div>
              {this.props.keywordGroups.map((kwg, idx) => (
                <QuillFormField
                  key={kwg.keywordGroupId}
                  component={CreatableWithErrors}
                  customLabelStyles={styles.fieldLabel}
                  disabled={this.props.submitting}
                  labelText={kwg.keywordGroupName}
                  multi
                  name={`keywords[${idx}][word]`}
                  noResultsText={false}
                  options={[]}
                  placeholder="Type keyword and press Enter or Tab ..."
                  styles="creatable-only"
                  type="text"
                />
              ))}
            </div>
          )}
          {this.props.sourceFields &&
            Object.keys(this.props.sourceFields).length > 0 && (
              <QuillFormFieldArray
                key={this.props.parentDeliverableId}
                component={FieldArrayWithErrors}
                componentType="textarea"
                customContainerStyle={styles.margin}
                entities={this.props.sourceFields}
                entityId="sourceFieldId"
                fieldKey="fieldValue"
                labelKey="taskFieldName"
                name="parentDeliverableSourceFields"
                title={`Source fields (${this.props.projectSourceLanguage})`}
              />
            )}

          {featureToggles && featureToggles.QCC_1081_rateBands && (
            <div>
              <p className={styles.label}>Rate band</p>
              <QuillFormField
                component={SelectWithErrors}
                disabled={
                  this.props.submitting || this.props.rateBands.length === 1
                }
                name="rateBandId"
                options={this.props.rateBands}
                type="text"
              />
            </div>
          )}
        </QuillForm>
      </div>
    );
  }
}

ParentDeliverablesForm.propTypes = {
  batches: PropTypes.array.isRequired,
  briefingFields: PropTypes.object.isRequired,
  clientDeadlineStages: PropTypes.array,
  createBatch: PropTypes.func,
  edit: PropTypes.bool,
  error: PropTypes.string,
  featureToggles: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    keywords: PropTypes.array,
  }),
  updateDate: PropTypes.string,
  keywordGroups: PropTypes.array.isRequired,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }),
  projectId: PropTypes.number.isRequired,
  projectSourceLanguage: PropTypes.string,
  rateBands: PropTypes.array,
  showModal: PropTypes.func,
  sourceFields: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: "parentDeliverablesForm",
  validate,
  enableReinitialize: true,
})(ParentDeliverablesForm);
