import { getGraphQL } from "../../../../../../../utils/fetch";

import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../modules/projectLanguages";

export const initialQuery = `query ClientDashboardFilter ($projectId: String) {
  ${projectLanguagesByProjectIdQuery}
}`;

export function getInitialData(props, url, cookies) {
  const { projectId } = props;

  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, { projectId }, url, cookies);
    dispatch(fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages));
  };
}
