import { StageType } from "../../../../../../../../../modules/stages";

export const getStageMaximums = ({
  assignees,
  stages,
  defaultAssignees,
  languageCode,
  numParentDeliverables,
}) => {
  return stages.reduce((acc, { stageId, stageType }, idx) => {
    if (stageType === StageType.Training) {
      const prevStageId = stages[idx - 1].stageId;
      const prevStageDefaultAssignees =
        defaultAssignees[prevStageId]?.[languageCode] || [];

      // count the amount of allocations that are also
      // in training in the previous production stage
      const trainingTotal = prevStageDefaultAssignees.reduce(
        (acc, { personId }) => {
          const assignee = assignees[prevStageId][languageCode][personId];
          if (!assignee.training) {
            return acc;
          }

          return acc + assignee.allocation;
        },
        0
      );

      acc[stageId] = trainingTotal;
    } else {
      // non training stages always have the maximum to assign
      acc[stageId] = numParentDeliverables;
    }

    return acc;
  }, {});
};
