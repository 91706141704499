import React, { useEffect } from "react";
import DropdownV2 from "../../../../../../components/DropdownV2";
import styles from "./DashboardFilters.module.scss";
import PropTypes from "prop-types";

export const DashboardFilters = (props) => {
  const {
    activeLanguage,
    dataReady,
    projectLanguages,
    readyForReview,
    setActiveLanguage,
    setReadyForReview,
  } = props;
  const languageArray = projectLanguages.map(
    ({ languageCode }) => languageCode
  );
  const languageArrayLength = languageArray.length;
  const isProjectLanguage = languageArray.some((la) => la === activeLanguage);

  useEffect(() => {
    if (dataReady && !isProjectLanguage) setActiveLanguage("");
    if (languageArrayLength === 1) setActiveLanguage(languageArray[0]);
  });

  function selectLanguage(e) {
    setActiveLanguage(e.target.value);
  }

  function toggleWithClient() {
    setReadyForReview(!readyForReview);
  }

  function getTitle() {
    if (!languageArrayLength) return "No languages";
    if (languageArrayLength === 1) return languageArray[0];
    if (isProjectLanguage) return activeLanguage;

    return "Select language";
  }

  const languages = projectLanguages.map(({ languageCode }, i) => (
    <option key={i} onClick={selectLanguage}>
      {languageCode}
    </option>
  ));

  return (
    <div className={styles.dashboardFilters}>
      <select className={styles.languageFilter} onChange={selectLanguage}>
        <option value="">{!activeLanguage ? getTitle() : "All"}</option>
        {languages}
      </select>

      <div className={styles.toggleDiv}>
        <span className={styles.label}>Ready for review:</span>
        <label className={styles.switch}>
          <input
            checked={readyForReview}
            onChange={toggleWithClient}
            type="checkbox"
          />
          <span className={`${styles.slider} ${styles.round}`} />
        </label>
      </div>

      <div className={styles.languageFilterPosition}>
        <DropdownV2
          title={getTitle()}
          buttonStyles={styles.languageFilterButton}
        >
          <option onClick={selectLanguage} value="">
            All
          </option>
          {languages}
        </DropdownV2>
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {
  activeLanguage: PropTypes.string,
  dataReady: PropTypes.bool,
  projectLanguages: PropTypes.array.isRequired,
  readyForReview: PropTypes.bool,
  setActiveLanguage: PropTypes.func.isRequired,
  setReadyForReview: PropTypes.func.isRequired,
};

export default DashboardFilters;
