import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./WordsChecker.module.scss";
import WordList from "./WordList";
import classNames from "classnames";
import { wordMatchRegex } from "../../../../../../utils/string";

class WordsChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showKeywords: true,
    };
  }

  hasWord = (word, contentArray) => {
    const regex = wordMatchRegex([word], "i");
    return contentArray.some((text) => regex.test(text));
  };

  componentDidMount() {
    this.wordsMatch();
  }

  componentDidUpdate() {
    this.wordsMatch();
  }

  wordsMatch = () => {
    const { content, words } = this.props;
    return words.map((word) => ({
      word,
      hasWord: this.hasWord(word, content),
    }));
  };

  toggleWords = () => {
    this.setState({ showKeywords: !this.state.showKeywords });
  };

  render() {
    const wordsArray = this.wordsMatch();

    const style = classNames({
      [styles.visible]: this.state.showKeywords,
      [styles.hidden]: !this.state.showKeywords,
    });

    return (
      <div className={styles.wordsContainer}>
        <div className={styles.wordNav} onClick={this.toggleWords}>
          <span>{this.props.title}</span>
          <svg height="15px" width="15px">
            {" "}
            {this.state.showKeywords ? (
              <path d="M1 7h14v2h-14v-2z" fill="#da0034" />
            ) : (
              <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#da0034" />
            )}
          </svg>
        </div>
        <div className={style}>
          <WordList
            emptyMessage={this.props.emptyMessage}
            highlightClass={this.props.highlightClass}
            noUsedMessage={this.props.noUsedMessage}
            wordsArray={wordsArray}
          />
        </div>
      </div>
    );
  }
}

WordsChecker.propTypes = {
  content: PropTypes.array.isRequired,
  words: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  highlightClass: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  noUsedMessage: PropTypes.string.isRequired,
};

export default WordsChecker;
