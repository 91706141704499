import React, { useEffect } from "react";
import { QuillFormField } from "../../../../../../../../../components/QuillForm";
import { InputWithErrors } from "../../../../../../../../../decorators/WithErrors";
import styles from "../BatchForm.module.scss";
import { connect } from "react-redux";
import { change } from "redux-form";
import { getAllocationUpdates } from "./allocationUpdates";

const Messages = {
  NO_DELIVERABLES_IN_BATCH:
    "There are no deliverables available in this batch. Please select a different batch.",
  SELECT_A_BATCH: "Please select a batch",
};

const DeliverableCount = ({
  stages,
  assignmentsFormValues,
  deliverableIds,
  defaultAssignees,
  languageCode,
  selectedBatchId,
  featureToggles,
  numParentDeliverables,
  updateAllocationAmounts,
  maxNumParentDeliverables,
  change,
  isAiContentWorkflow,
}) => {
  // update all allocation amounts with distributed allocations when the number
  // of deliverables to assign is changed
  useEffect(() => {
    if (isAiContentWorkflow) {
      change("numParentDeliverables", maxNumParentDeliverables);
    }
    const hasFormValues = Object.keys(assignmentsFormValues).length > 0;
    if (
      featureToggles.allocationScaling &&
      numParentDeliverables > 0 &&
      hasFormValues
    ) {
      updateAllocationAmounts({
        stages,
        languageCode,
        defaultAssignees,
        numParentDeliverables,
        assignmentsFormValues,
      });
    }
  }, [
    assignmentsFormValues,
    defaultAssignees,
    featureToggles.allocationScaling,
    languageCode,
    numParentDeliverables,
    stages,
    updateAllocationAmounts,
    isAiContentWorkflow,
    change,
    maxNumParentDeliverables,
  ]);

  if (!selectedBatchId) {
    return <div className={styles.labelWarning}>{Messages.SELECT_A_BATCH}</div>;
  }

  const unallocatedCount = Object.keys(deliverableIds.unallocated).length;
  if (unallocatedCount === 0) {
    return (
      <div className={styles.labelWarning}>
        {Messages.NO_DELIVERABLES_IN_BATCH}
      </div>
    );
  }

  const checkMax = (val) => {
    if (!val || isNaN(val)) return 0;
    if (val > maxNumParentDeliverables) return maxNumParentDeliverables;
    return Number(val);
  };

  return (
    <>
      <div className={styles.labelLeft}>
        <div>How many deliverables would you like to assign?</div>
        <div className={styles.small}>
          Available: {maxNumParentDeliverables}
        </div>
      </div>

      <QuillFormField
        component={InputWithErrors}
        customContainerStyle={`${styles.inputRightSmaller} ${styles.deliverableNum}`}
        max={maxNumParentDeliverables}
        min={0}
        name="numParentDeliverables"
        parse={checkMax}
        type="number"
        disabled={isAiContentWorkflow}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAllocationAmounts: (options) => {
    const newValues = getAllocationUpdates(options);

    // for each update dispatch a change to the the redux-store
    newValues.forEach((update) => {
      dispatch(change(...update));
    });
  },
});

const mapStateToProps = (state) => {
  const assignmentsFormValues = state.form.assignmentsForm?.values || {};
  const batchFormValues = state.form.batchForm?.values || {};
  const { featureToggles } = state;
  const { numParentDeliverables } = batchFormValues;

  return {
    numParentDeliverables,
    assignmentsFormValues,
    featureToggles,
    batchFormValues,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableCount);
