import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { logoutFetch } from "./modules/me";
import * as Bundles from "./routes/asyncBundles";
import getRoutes from "./routes";
import loadGlobals from "./boot/loadGlobals";
import getInitialData from "./boot/getInitialData";
import initialiseStore from "./boot/initialiseStore";
import pageData from "./boot/pageData";
import render from "./boot/render";
import TagManager from "react-gtm-module";
import { handleAuth0Authentication } from "./boot/auth0Client";
import intercomBootWithStore from "./boot/intercom";
loadGlobals();

Sentry.init({
  release: `${process.env.PROJECT_ID || window.__PROJECT_ID__}@${
    process.env.REACT_APP_SHA
  }`,
  environment: window.__RUNTIME_NODE_ENV__ || process.env.NODE_ENV,
  dsn: window.__SENTRY_DSN__ || process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "t: Submit Validation Failed",
    "c is undefined",
    "undefined has no properties",
    "Text cannot be empty",
  ],
  tracesSampleRate: 0.1,
});

const TAG_INFO = {
  gtmId: window.__GA_TAG__ || process.env.GA_TAG,
};

TagManager.initialize(TAG_INFO);

async function main() {
  const initalData = await getInitialData();
  const store = initialiseStore(initalData);

  const { featureToggles } = store.getState();

  const routes = getRoutes(featureToggles);
  const getPageData = pageData(routes, store);

  // Only run this if there's no coookie
  if (featureToggles.auth0Login) {
    await handleAuth0Authentication(store);
  }

  if (featureToggles.intercom) {
    intercomBootWithStore(store);
  }

  const checkName = (name) => {
    if (
      name === "NotFound" ||
      name === "Unauthorized" ||
      name === "Forbidden" ||
      name === "TypeError"
    ) {
      return logoutFetch()(store.dispatch, store.getState).then(
        () => (window.location = "/")
      );
    }
  };

  try {
    getPageData();
    await Promise.all(
      ["AdminLayout", "ParentAccountsList"].map((chunk) =>
        Bundles[chunk].loadComponentAndReducers()
      )
    );

    await render(routes, store);
  } catch (err) {
    checkName(err.name);
    if (err.errors) {
      for (const { name } of err.errors) {
        checkName(name);
      }
    }

    console.log("ERR", err);
  }
}
main();
