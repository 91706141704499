import { getGraphQL } from "../../../../../../../utils/fetch";
import {
  accountsByProjectQuery,
  fetchAccountsSuccess,
} from "../../../../../../modules/accounts";
import {
  exportFormatByProjectIdQuery,
  fetchExportFormatSuccess,
} from "../../../../../../modules/exportFormats";
import {
  orderFormsByProjectQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../modules/orderForms";
import {
  parentAccountsByProjectQuery,
  fetchParentAccountsSuccess,
} from "../../../../../../modules/parentAccounts";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../modules/projects";
import {
  fetchTaskFieldsSuccess,
  taskFieldsByProjectQuery,
} from "../../../../../../modules/taskFields";

export const initialQuery = `query projectExportFormat_getInitialData ($projectId: String){
  ${accountsByProjectQuery}
  ${exportFormatByProjectIdQuery}
  ${orderFormsByProjectQuery}
  ${parentAccountsByProjectQuery}
  ${taskFieldsByProjectQuery}
  ${projectQuery}
}`;

export function getInitialData(params, url, cookies) {
  return async (dispatch) => {
    const json = await getGraphQL(initialQuery, params, url, cookies);

    dispatch(fetchParentAccountsSuccess(json.parentAccounts));
    dispatch(fetchAccountsSuccess(json.accounts));
    dispatch(fetchOrderFormsSuccess(json.orderForms));
    dispatch(fetchProjectSuccess(json.project));
    dispatch(fetchTaskFieldsSuccess(json.taskFields));
    dispatch(fetchExportFormatSuccess(json.exportFormat));
  };
}
