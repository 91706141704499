import React from "react";
import styles from "./AssignmentHeaders.module.scss";
import LanguageSelector from "../../../../../../../../../components/LanguageSelector";
import BatchSelector from "../../../../../../../../../components/BatchSelector";
import PropTypes from "prop-types";
import FeatureToggle from "../../../../../../../../../components/FeatureToggle";
import classNames from "classnames";

const IN_PRODUCTION_SELECTED_BATCH_KEY = "admin/in-production/batch-id";

const AssignmentHeaders = (props) => {
  const filterIcon = (
    <svg height="15" viewBox="0 0 15 15" width="15">
      <path
        d="M6 4v11h-2v-11h-2.5l3.5-3.5 3.5 3.5zM10 12v-11h2v11h2.5l-3.5 3.5-3.5-3.5z"
        fill="#4e575e"
      />
    </svg>
  );
  const arrowDown = (
    <svg height="16" version="1.1" viewBox="0 0 16 16" width="16">
      <path d="M3.5 6l4.5 4.5 4.5-4.5h-9z" fill="#4e575e" />
    </svg>
  );

  const arrowUp = (
    <svg height="16" version="1.1" viewBox="0 0 16 16" width="16">
      <path d="M12.5 10l-4.5-4.5-4.5 4.5h9z" fill="#4e575e" />
    </svg>
  );

  const { filter, overdueFilter, toggleSort } = props;

  const renderArrow = (type) => {
    if (type === "assignee") {
      if (filter === "normal") return filterIcon;
      if (filter === "alphabetical") return arrowDown;
      if (filter === "reverse") return arrowUp;
    }

    if (type === "deadline") {
      if (!overdueFilter) return filterIcon;
      else return arrowDown;
    }
  };

  const sort = (e) => {
    toggleSort(e.currentTarget.getAttribute("value"));
  };

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.automatedReminders]: props.automatedReminderActive,
  });

  return (
    <div className={containerStyles}>
      <div></div>
      <div className={`${styles.sort} ${styles.assignee}`} onClick={sort}>
        Assignee
        {renderArrow("assignee")}
      </div>
      <div className={styles.status}>Accepted?</div>
      <div className={styles.count}>#</div>
      <BatchSelector
        batches={props.batches}
        history={props.history}
        localStorageKey={IN_PRODUCTION_SELECTED_BATCH_KEY}
        location={props.location}
        selectBatch={props.selectBatch}
        showAllButton
      />
      <div className={styles.sort} onClick={sort} value="deadline">
        Deadline
        {renderArrow("deadline")}
      </div>
      <div>In training</div>
      <LanguageSelector
        languageNames={props.languageNames}
        projectLanguages={props.projectLanguages}
        selectLanguage={props.selectLanguage}
      />
      <div className={styles.amendsRequested}>Amends requested</div>
      <div className={styles.reassign}>Adjust assignment</div>
      <FeatureToggle toggle="automatedReminders">
        <div className={styles.automatedReminder}>Automated Reminder</div>
      </FeatureToggle>
    </div>
  );
};

AssignmentHeaders.propTypes = {
  allChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleAll: PropTypes.func.isRequired,
  batches: PropTypes.array,
  filter: PropTypes.string.isRequired,
  history: PropTypes.object,
  languageNames: PropTypes.object,
  location: PropTypes.object,
  overdueFilter: PropTypes.bool.isRequired,
  projectLanguages: PropTypes.array,
  selectBatch: PropTypes.func,
  selectLanguage: PropTypes.func,
  toggleSort: PropTypes.func,
};

export default AssignmentHeaders;
