import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import Component from "./AssignmentEdit";
import { getInitialData } from "./getInitialData";
import WithData from "../../decorators/WithData";
import { bannedWordsForProjectAndLanguage } from "../../modules/bannedWords";
import { showModal, hideModal, ModalType } from "../../modules/modal";
import { showErrors } from "../../modules/errors";
import {
  displayGradingFormIfApplicable,
  GradeNotSubmitted,
} from "../../modules/grades";
import { createTransitionLogsFromDeliverables } from "../../modules/transitionLogs";
import { keywordsForParentDeliverable } from "../../modules/keywords";
import { showAppComponent } from "../../modules/appComponents";
import { PersonType } from "../../modules/people";
import { shouldShowTM } from "../../modules/translations";
import { BriefingFieldFormat } from "../../modules/briefingFields";
import { showWarningMessage } from "../../modules/messages";
import {
  discardClientChanges,
  checkForUnconfirmedTMSegments,
  createTaskFieldContentAtServer,
  wasModifiedByClient,
  getLatestTaskFieldContentV2,
} from "../../modules/taskFieldContent";
import { ResponseTypes } from "../../modules/transitionLogs";
import { showSuccessMessage } from "../../modules/messagesV2";
import {
  deliverableById,
  getLoggedInUser,
  isProjectLocalisation,
  parentDeliverableBriefingFieldsByParentDeliverableSelector,
  parentDeliverableSourceFieldsByParentDeliverableSelector,
  projectById,
  projectTaskFieldsSelector,
  workflowStagesSelectorByTypes,
} from "../../utils/entitySelector";
import { getDeliverableTransitions } from "../../utils/entitySelectors/transitions";
import { nullArray } from "../../utils/nullObjects";
import { createTransitionSuccessMessage } from "../../modules/transitions/notifications";
import { plagiarismStatus } from "./Tasks/Task/PlagiarismMessage";
import {
  removeSuggestion,
  processTransitionGrammarCheck,
} from "../../modules/webSpellChecker";

import { addToDictionary } from "../../modules/userDictionary";
import { AI_STAGES, stageById } from "../../modules/stages";

/**
 * Collates data for source fields, task fields and task field content
 *
 * @param {Object} state
 * @param {number} deliverableId
 * @param {number} stageId
 * @param {number} parentDeliverableId
 * @param {number} projectId
 *
 * @returns {Task[]} array of tasks
 */
const taskSelector = createSelector(
  getLatestTaskFieldContentV2,
  (state, _d, _s, parentDeliverableId) =>
    parentDeliverableSourceFieldsByParentDeliverableSelector(
      state,
      parentDeliverableId
    ),
  (state, _d, _s, _pd, projectId) =>
    projectTaskFieldsSelector(state, projectId),
  (_, _d, _s, _pd, _pid, plagiarismTasks) => plagiarismTasks,
  (
    taskFieldContentByTaskFieldId,
    pdSourceFields,
    taskFields,
    plagiarismTasks
  ) => {
    return taskFields.map(
      ({
        taskFieldId,
        taskFieldName,
        taskFieldFormat,
        qualityCheck,
        minWords,
        maxWords,
        minCharacters,
        maxCharacters,
      }) => {
        const { content, rawContent } =
          taskFieldContentByTaskFieldId[taskFieldId] || {};
        const { fieldValue, rawContent: sfRawContent } =
          pdSourceFields.find((field) => field.taskFieldId === taskFieldId) ||
          {};

        const plagiarismTask = plagiarismTasks.find(
          (p) => p.taskFieldId === taskFieldId
        );

        return {
          content,
          fieldName: taskFieldName,
          format: taskFieldFormat,
          qualityCheck,
          rawContent: rawContent && JSON.parse(rawContent),
          sourceField: {
            fieldValue,
            rawContent: sfRawContent && JSON.parse(sfRawContent),
          },
          taskFieldId,
          minWords,
          maxWords,
          minCharacters,
          maxCharacters,
          plagiarismTask,
        };
      }
    );
  }
);

const briefingFieldInfoSelector = createSelector(
  parentDeliverableBriefingFieldsByParentDeliverableSelector,
  (state) => state.briefingFields.entities,
  (_pds, _bfs, personType) => personType,
  (parentDeliverableBriefingFields, briefingFieldEntities, personType) =>
    parentDeliverableBriefingFields
      .map(({ briefingFieldId, fieldValue }) => {
        const {
          briefingFieldFormat,
          briefingFieldName,
          briefingFieldPosition,
        } = briefingFieldEntities[briefingFieldId] || {}; // gets past the "&& check"
        return {
          briefingFieldId,
          briefingFieldFormat,
          briefingFieldName,
          fieldValue,
          briefingFieldPosition,
        };
      })
      .filter(({ briefingFieldFormat, fieldValue }) => {
        // Hide AI attributes if not admin
        if (
          personType !== PersonType.Admin &&
          briefingFieldFormat === BriefingFieldFormat["AI attribute"]
        ) {
          return false;
        }
        // hide briefing fields which do not have any values
        if (
          briefingFieldFormat === BriefingFieldFormat.Text ||
          briefingFieldFormat === BriefingFieldFormat.Hyperlink ||
          briefingFieldFormat === BriefingFieldFormat.Image ||
          briefingFieldFormat === BriefingFieldFormat["AI content model"] ||
          briefingFieldFormat === BriefingFieldFormat["AI attribute"]
        ) {
          return fieldValue && fieldValue.trim().length > 0;
        } else if (briefingFieldFormat === BriefingFieldFormat.HTML) {
          // DraftJS empty HTML line
          return fieldValue && fieldValue !== "<p><br></p>";
        }
        return null;
      })
      .sort((a, b) => a.briefingFieldPosition - b.briefingFieldPosition)
);

function mapDispatchToProps(dispatch, ownProps) {
  const {
    deliverableId,
    gradeAssignmentGroupId,
    history,
    listUrl,
    nextUrl,
    isBulkQA,
  } = ownProps;

  return {
    checkForUnconfirmedTMSegments: (assignmentIds) =>
      dispatch(checkForUnconfirmedTMSegments(assignmentIds)),
    getInitialData: () => dispatch(getInitialData(ownProps)),
    processTransition: async (transition) => {
      const { transitionName, forwardTransition } = transition;
      if (transitionName.includes("QA") && forwardTransition) {
        dispatch(
          processTransitionGrammarCheck(
            { deliverableIds: [deliverableId] },
            ownProps
          )
        );
      }

      try {
        if (gradeAssignmentGroupId) {
          await dispatch(
            displayGradingFormIfApplicable(transition, gradeAssignmentGroupId, [
              deliverableId,
            ])
          );
        }

        const data = await dispatch(
          createTransitionLogsFromDeliverables(transition, [deliverableId])
        );
        const {
          meta: { eventType },
          deliverableIds = [],
        } = data;

        if (eventType === ResponseTypes.TRANSITION_LOGS_CREATED) {
          const message = createTransitionSuccessMessage(
            deliverableIds,
            transitionName
          );
          dispatch(showSuccessMessage(message));
        }

        if (!isBulkQA) {
          history.push(nextUrl || listUrl);
        }
      } catch (err) {
        if (err instanceof GradeNotSubmitted) return; // ignore
        dispatch(showErrors(err));
      }
    },
    saveContent: async (data, isTransitioning) => {
      if (!data.length) return;

      try {
        await dispatch(createTaskFieldContentAtServer(data, isTransitioning));
      } catch (err) {
        throw err;
      }
    },
    showBatchFeedback: () => dispatch(showAppComponent("batchFeedbackPanel")),
    showWarningMessage: (message) => dispatch(showWarningMessage(message)),
    showModal: () => dispatch(showModal({ type: ModalType.DiscardChanges })),
    hideModal: () => dispatch(hideModal()),
    discardClientContent: () => dispatch(discardClientChanges(ownProps)),
    processTransitionGrammarCheck: (params) =>
      dispatch(processTransitionGrammarCheck(params, ownProps)),
    addToDictionary: (textString) =>
      dispatch(addToDictionary(textString, ownProps)),
    removeSuggestion: (suggestion) => dispatch(removeSuggestion(suggestion)),
  };
}

function removeIgnores(corrections, rawContentStrMap, ignores = []) {
  if (ignores.length === 0) {
    return corrections;
  }

  const correctionKeys = Object.keys(corrections);

  const newCorrections = correctionKeys.reduce((acc, key) => {
    const newItems = corrections[key].map((c) => ({
      ...c,
      text: rawContentStrMap[key].substr(c.offset, c.length),
    }));

    const filteredItems = newItems.filter(({ text, type, rule }) => {
      const foundRule = ignores.find((ignore) => {
        const matchType = ignore.ignoreType === type;
        const matchWord = ignore.ignoreWord === text;
        const matchRule = rule ? ignore.ignoreRule === rule : true;

        return matchType && matchWord && matchRule;
      });
      return !foundRule;
    });

    acc[key] = filteredItems;

    return acc;
  }, {});

  return newCorrections;
}

function getIgnoresByTaskFieldId(grammarCheckEntities, grammarCheckResult) {
  return grammarCheckResult.reduce((acc, id) => {
    const gci = grammarCheckEntities[id];
    const { taskFieldId } = gci;

    if (taskFieldId) {
      if (!acc[taskFieldId]) {
        acc[taskFieldId] = [];
      }

      acc[taskFieldId].push(gci);
    }

    return acc;
  }, {});
}

function getIgnoresByProjectId(grammarCheckEntities, grammarCheckResult) {
  return grammarCheckResult.reduce((acc, id) => {
    const gci = grammarCheckEntities[id];
    const { taskFieldId, projectId } = gci;

    if (!taskFieldId) {
      if (!acc[projectId]) {
        acc[projectId] = [];
      }

      acc[projectId].push(gci);
    }

    return acc;
  }, {});
}

function getRawContentStrMap(rawContent) {
  try {
    return JSON.parse(rawContent).blocks.reduce((acc, { key, text }) => {
      acc[key] = text;
      return acc;
    }, {});
  } catch (_err) {
    return {};
  }
}

const correctionSelector = createSelector(
  (state) => state.webSpellChecker.entities,
  (state) => state.grammarCheckIgnore.entities,
  (state) => state.grammarCheckIgnore.result,
  (_state, deliverableId) => deliverableId,
  (
    corrections,
    grammarCheckIgnoreEntities,
    grammarCheckIgnoreResult,
    deliverableId
  ) => {
    const ignoresByTFId = getIgnoresByTaskFieldId(
      grammarCheckIgnoreEntities,
      grammarCheckIgnoreResult
    );
    const ignoresByProjectId = getIgnoresByProjectId(
      grammarCheckIgnoreEntities,
      grammarCheckIgnoreResult
    );

    const localCorrections = Object.values(corrections).filter(
      (cor) => Number(cor.deliverableId) === deliverableId
    );

    const correctionsRemovedIgnores = localCorrections.map((cor) => {
      const ignoresTF = ignoresByTFId[cor.taskFieldId] || [];
      const ignoresProject = ignoresByProjectId[cor.projectId] || [];
      const rawContentStrMap = getRawContentStrMap(cor.rawContent);

      const correctionsFiltered = removeIgnores(
        cor.corrections,
        rawContentStrMap,
        [...ignoresTF, ...ignoresProject]
      );

      return {
        ...cor,
        corrections: correctionsFiltered,
      };
    });

    return correctionsRemovedIgnores;
  }
);
/**
 * @param   {Object}  state
 * @param   {number}  deliverableId
 * @param   {number}  stageId
 * @returns {Boolean} true if the content has been modified after approval, otherwise false
 */
const wasModifiedAfterApproval = createSelector(
  getLatestTaskFieldContentV2,
  (state) => state.stages.entities,
  (tfc, stageEntities) =>
    Object.values(tfc).some(({ stageId }) => {
      const modifiedStage = stageEntities[stageId];
      return modifiedStage && modifiedStage.isFinal;
    })
);

const tasksByTaskFieldId = createSelector(
  (tasks) => tasks,
  (_tasks, taskFieldId) => taskFieldId,
  (tasks, taskFieldId) => {
    return tasks.filter((t) => t.taskFieldId === taskFieldId);
  }
);

function mapStateToProps(
  state,
  { deliverableId, isActionable, projectId, stageId, taskFieldId, isBulkQA }
) {
  const {
    accounts,
    featureToggles: { adminGrammarCheck, freelancerGrammarCheck, glossary_v2 },
  } = state;

  const { personId, personType } = getLoggedInUser(state);
  const project = projectById(state, projectId);
  const { editableClientStages, sourceLanguage } = project || {};
  const deliverable = deliverableById(state, deliverableId);
  const {
    languageCode,
    parentDeliverableId,
    currentStageId,
    plagiarismTasks = [],
  } = deliverable;

  const [accountId] = accounts.result || [];

  const { rateBandId } =
    state.parentDeliverables.entities[parentDeliverableId] || {};

  const briefingFieldInfo = briefingFieldInfoSelector(
    state,
    parentDeliverableId,
    personType
  );
  const bannedWords = bannedWordsForProjectAndLanguage(
    state,
    projectId,
    languageCode
  );
  const keywords = keywordsForParentDeliverable(state, parentDeliverableId);

  const transitions = isActionable
    ? getDeliverableTransitions(state, deliverableId, stageId)
    : nullArray;

  const { isFinal: isFinalStage } = stageById(state, stageId);

  const stages = workflowStagesSelectorByTypes(state, project.workflowId, [
    "Client",
  ]);

  const isWithClient = stages.reduce((acc, cur) => {
    if (
      cur.stageId === currentStageId &&
      !cur.isFinal &&
      cur.stageType === "Client"
    )
      return true;
    return acc;
  }, false);

  const { isFinal: isDeliverableApproved, stageName } = stageById(
    state,
    currentStageId
  );

  const tasks = taskSelector(
    state,
    deliverableId,
    stageId,
    parentDeliverableId,
    projectId,
    plagiarismTasks
  );

  // if any plagiarism checks are ongoing, we disallow users from editing
  const plagiarismChecksInProgress = plagiarismTasks.some((t) => {
    return t.status !== plagiarismStatus.COMPLETE;
  });

  const isLocalisationProject = !!isProjectLocalisation(state, projectId);

  const allowEditOverride =
    editableClientStages === "POST_APPROVAL" &&
    isFinalStage &&
    personType === PersonType.Client;

  const allowClientChanges = editableClientStages === "CLIENT_REVIEW";

  const allowEditInReview =
    editableClientStages === "CLIENT_REVIEW" &&
    isWithClient &&
    personType === PersonType.Client;

  const grammarChecks = correctionSelector(state, deliverableId);
  const allowGrammarCheck =
    stageName?.includes("QA") &&
    ((personType === PersonType.Admin && adminGrammarCheck) ||
      (personType === PersonType.Freelancer && freelancerGrammarCheck));

  const { taskFieldRateBands } = state;

  const isAiStage = AI_STAGES.includes(stageName);

  const selectGlossaryWords = createSelector(
    (state) => state.glossary.entities,
    (_state, targetLanguage) => targetLanguage,
    (_state, _targetLanguage, sourceLanguage) => sourceLanguage,
    (glossary, targetLanguage, sourceLanguage) => {
      return Object.values(glossary).reduce((acc, segment) => {
        const targetWord = segment[targetLanguage];
        const sourceWord = segment[sourceLanguage];
        if (targetWord && (!sourceLanguage || (sourceLanguage && sourceWord))) {
          acc.push(sourceLanguage ? sourceWord : targetWord);
        }
        return acc;
      }, []);
    }
  );

  return {
    accountId,
    allowEditOverride,
    allowEditInReview,
    allowClientChanges,
    allowGrammarCheck,
    bannedWords,
    briefingFieldInfo,
    errors: state.errors,
    isDeliverableApproved,
    isFinalStage,
    isLocalisationProject,
    isWithClient,
    key: deliverableId,
    keywords,
    glossaryWords: selectGlossaryWords(state, languageCode),
    glossarySourceWords: selectGlossaryWords(
      state,
      languageCode,
      sourceLanguage
    ),
    languageCode,
    parentDeliverableId,
    personId,
    taskFieldRateBands,
    rateBandId,
    personType,
    plagiarismChecksInProgress,
    shouldShowTM: shouldShowTM(state, projectId, stageId),
    sourceLanguage,
    tasks: isBulkQA ? tasksByTaskFieldId(tasks, taskFieldId) : tasks,
    transitions,
    wasModifiedAfterApproval: wasModifiedAfterApproval(
      state,
      deliverableId,
      stageId
    ),
    wasModifiedByClient: wasModifiedByClient(state, deliverableId, stageId),
    grammarChecks,
    glossary_v2,
    isAiStage,
  };
}

const AssignmentEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(Component));
AssignmentEdit.getInitialData = getInitialData;

AssignmentEdit.propTypes = {
  deliverableId: PropTypes.number.isRequired,
  gradeAssignmentGroupId: PropTypes.number,
  history: PropTypes.object.isRequired,
  isActionable: PropTypes.bool,
  isCommentable: PropTypes.bool.isRequired,
  isCurrentStage: PropTypes.bool.isRequired,
  listUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  stageId: PropTypes.number.isRequired,
};

export default AssignmentEdit;
