import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getGraphQL } from "../../../utils/fetch";
import WithData from "../../decorators/WithData";
import {
  fetchOrderFormGradesSuccess,
  orderFormGradesQuery,
  orderFormPersonGradesSelector,
} from "../../modules/grades";
import AssignmentsForm from "./AssignmentsForm";
import { createSelector } from "reselect";
import { convertDateToLocal, toInputString } from "../../utils/date";
import { batchById } from "../../utils/entitySelector";

const initialQuery = `query AssignmentForm_fetchGrades ($orderFormId: Int) {
  ${orderFormGradesQuery}
}`;

const getInitialData = (params, url, cookies) => {
  return async (dispatch, getState) => {
    const { featureToggles } = getState();
    if (!featureToggles.QCC_1798_freelancerGrading) return;

    try {
      const { orderFormId } = params;
      const { orderFormGrades } = await getGraphQL(
        initialQuery,
        { orderFormId },
        url,
        cookies
      );

      dispatch(fetchOrderFormGradesSuccess({ orderFormId, orderFormGrades }));
    } catch (_err) {
      // TOOD: Handle grading requests not being caught
    }
  };
};

export const deadlinesSelector = createSelector(
  ({ batch }) => batch,
  ({ stages }) => stages,
  ({ selectedAssignment }) => selectedAssignment || {},
  (batch, stages, selectedAssignment) => {
    const defaultDeadlines = batch.defaultDeadlines
      ? batch.defaultDeadlines.reduce((acc, dd) => {
          const localDate = convertDateToLocal(new Date(dd.deadline));
          acc[dd.stageId] = toInputString(localDate);
          return acc;
        }, {})
      : {};

    const deadlines = stages.reduce((acc, { stageId }) => {
      acc[stageId] = defaultDeadlines[stageId] || "";
      return acc;
    }, {});

    // reassignments use the already existing assignment deadline
    const { stageId, deadline } = selectedAssignment;
    if (deadline) {
      deadlines[stageId] = toInputString(
        convertDateToLocal(new Date(deadline))
      );
    }

    return deadlines;
  }
);

export const initialValuesSelector = createSelector(
  ({ stages }) => stages,
  ({ languageCode }) => languageCode,
  ({ defaultAssignees }) => defaultAssignees,
  ({ batch }) => batch,
  ({ featureToggles }) => featureToggles,
  ({ selectedAssignment }) => selectedAssignment || {},
  (
    stages,
    languageCode,
    defaultAssignees,
    batch,
    featureToggles,
    selectedAssignment
  ) => {
    const assignees = stages.reduce((acc, { stageId }) => {
      const stageDefaultAssignees = defaultAssignees[stageId];

      if (stageDefaultAssignees?.[languageCode]) {
        acc[stageId] = {};
        acc[stageId][languageCode] = {};

        stageDefaultAssignees[languageCode].forEach(({ personId }) => {
          acc[stageId][languageCode][personId] = {
            allocation: 0,
            training: false,
            ...(featureToggles.allocationScaling ? { available: true } : {}),
          };
        });
      }

      return acc;
    }, {});

    const deadlines = deadlinesSelector({ batch, stages, selectedAssignment });

    const assistedDistributions = stages.reduce((acc, { stageId }) => {
      acc[stageId] = true;
      return acc;
    }, {});

    const initialValues = { assignees, deadlines, assistedDistributions };

    // reassignments need the assignmentGroupId passed through
    if (selectedAssignment.assignmentGroupId) {
      initialValues.assignmentGroupId = selectedAssignment.assignmentGroupId;
    }

    return initialValues;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
});

const mapStateToProps = (state, props) => {
  const {
    featureToggles,
    defaultAssignees,
    stages,
    selectedBatchId,
    people,
    orderFormId,
    languageCode,
    selectedAssignment,
  } = props;

  const batch = batchById(state, selectedBatchId);
  const personGrades = orderFormPersonGradesSelector(state, orderFormId);
  const initialValues = initialValuesSelector({
    defaultAssignees,
    stages,
    people,
    languageCode,
    featureToggles,
    batch,
    selectedAssignment,
  });

  const assignmentsFormValues = state.form.assignmentsForm?.values;

  return {
    assignmentsFormValues,
    personGrades,
    initialValues,
  };
};

const WithContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AssignmentsForm));

WithContainer.propTypes = {
  orderFormId: PropTypes.number,
};

export default WithContainer;
