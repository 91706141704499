import React, { Component } from "react";
import { PersonType } from "../../../modules/people";
import { TaskFormat } from "../../../modules/taskFields";
import DeliverableTab from "./components/DeliverableTab";
import BriefingFieldTab from "./components/BriefingFieldTab";
import styles from "./SidePanel.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { setLSItem, getLSItem } from "../../../utils/localStorage";
import removeHTML from "../../../utils/removeHTML";
import GlossaryTab from "./components/GlossaryTab";

const ACTIVE_TAB_KEY = "assignment-sidePanel-active-tab";

const Tab = Object.freeze({
  Briefing: "Briefing",
  Deliverable: "Deliverable",
  TranslationMemory: "Translation Memory",
  GlossaryTab: "Glossary",
});

class SidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
    };
  }

  updateActiveTab(activeTab) {
    this.setState({ activeTab });
    setLSItem(ACTIVE_TAB_KEY, activeTab);
  }

  componentDidMount() {
    const { personType, shouldShowTM } = this.props;
    const activeTab = getLSItem(ACTIVE_TAB_KEY);
    const isClient = personType === PersonType.Client;

    if (activeTab === Tab.TranslationMemory) {
      this.updateActiveTab(shouldShowTM ? Tab.TranslationMemory : Tab.Briefing);
    } else if (activeTab === Tab.Deliverable) {
      this.updateActiveTab(isClient ? Tab.Briefing : Tab.Deliverable);
    } else {
      this.updateActiveTab(Tab.Briefing);
    }
  }

  handleClick = ({ target: { textContent: activeTab } }) => {
    this.updateActiveTab(activeTab);
  };

  renderTabItem(name) {
    const active = name === this.state.activeTab;

    const navItemStyles = classNames({
      [styles.navItem]: true,
      [styles.active]: active,
      [styles.inactive]: !active,
      [styles.breakWord]: name === Tab.Deliverable,
    });

    return (
      <div className={navItemStyles} onClick={this.handleClick}>
        {name}
      </div>
    );
  }

  render() {
    const { activeTab } = this.state;

    const {
      bannedWords,
      briefingFieldInfo,
      deliverableId,
      keywords,
      personType,
      projectId,
      shouldShowTM,
      stageId,
      tasks,
      transitions,
      isDeliverableApproved,
      isWithClient,
      parentDeliverableId,
      glossary_v2,
    } = this.props;

    const content = tasks.map(({ content, format, qualityCheck }) => ({
      content: format === TaskFormat.HTML ? removeHTML(content) : content,
      qualityCheck,
    }));

    const keywordsContent = content
      .filter((i) => i.qualityCheck)
      .map((i) => i.content);

    const bannedWordsContent = content.map((i) => i.content);

    return (
      <div className={styles.container}>
        <div className={styles.navContainer}>
          <div className={styles.navItems}>
            {this.renderTabItem(Tab.Briefing)}
            {shouldShowTM && this.renderTabItem(Tab.TranslationMemory)}
            {glossary_v2 && this.renderTabItem(Tab.GlossaryTab)}
            {personType !== PersonType.Client &&
              this.renderTabItem(Tab.Deliverable)}
          </div>

          <div className={styles.activeTab}>
            {activeTab === Tab.Briefing && (
              <BriefingFieldTab
                bannedWords={bannedWords}
                bannedWordsContent={bannedWordsContent}
                briefingFieldInfo={briefingFieldInfo}
                deliverableId={
                  personType === PersonType.Client ? deliverableId : null
                }
                isWithClient={isWithClient}
                keywords={keywords}
                keywordsContent={keywordsContent}
                personType={personType}
                stageId={stageId}
                parentDeliverableId={parentDeliverableId}
              />
            )}

            {activeTab === Tab.TranslationMemory && (
              <GlossaryTab
                deliverableId={deliverableId}
                projectId={projectId}
              />
            )}

            {activeTab === Tab.GlossaryTab && (
              <GlossaryTab
                isGlossary
                deliverableId={deliverableId}
                projectId={projectId}
              />
            )}

            {activeTab === Tab.Deliverable && (
              <DeliverableTab
                key={deliverableId}
                deliverableId={deliverableId}
                isDeliverableApproved={isDeliverableApproved}
                personType={personType}
                projectId={projectId}
                stageId={stageId}
                toggleTransitionHistory={this.toggleTransitionHistory}
                transitions={transitions}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

SidePanel.propTypes = {
  bannedWords: PropTypes.array.isRequired,
  briefingFieldInfo: PropTypes.array.isRequired,
  deliverableId: PropTypes.number.isRequired,
  isDeliverableApproved: PropTypes.bool,
  isWithClient: PropTypes.bool.isRequired,
  keywords: PropTypes.array.isRequired,
  personType: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  shouldShowTM: PropTypes.bool.isRequired,
  stageId: PropTypes.number.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      qualityCheck: PropTypes.bool,
    })
  ),
  transitions: PropTypes.array.isRequired,
};

export default SidePanel;
