import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./BriefingFieldContainer.module.scss";
import { formatDateObject } from "../../../utils/date";
import { extractErrorFromObject } from "../../../utils/errors";
import Modal from "../../Modal";
import Messages from "../../Messages/v1";
import Transitions from "../../Transitions";
import BriefingFieldHeader from "./BriefingFieldHeader";
import BriefingFieldRow from "./BriefingFieldRow";
import UpdateAssignmentsForm from "./UpdateAssignmentsForm";
import { ModalType } from "../../../modules/modal";
import { ResponseTypes } from "../../../modules/transitionLogs";
import {
  createTransitionSuccessMessage,
  createTransitionWarningMessage,
} from "../../../modules/transitions/notifications";
import CurrencyDisplay from "../../CurrencyDisplay";
import FeatureToggle from "../../../components/FeatureToggle";

const BatchViewBtn = ({ url }) => (
  <Link className={styles.bulkQA} to={url}>
    <button className={styles.button}>Batch view</button>
  </Link>
);

class BriefingFieldContainer extends Component {
  constructor(props) {
    super(props);

    // we don't need this reactive, so no need to put in state
    this.selectableIds = [];

    this.state = {
      checkboxStates: props.data
        ? this.convertDataArrayToCheckboxStates(props.data)
        : {},
    };
  }

  /**
   * We need to convert the data array prop into a usuable format which can keep track of
   * the state of which checkbox is checked / actionable.
   */
  convertDataArrayToCheckboxStates = (dataObject, initialState = {}) =>
    dataObject
      // we only care about selectable rows
      .filter(({ selectable }) => selectable)
      // try to add each row to our state
      .reduce(
        (acc, { assignmentId, deliverableId, actionable, id, wasModified }) => {
          if (!acc[id]) {
            acc[id] = {
              actionable,
              checked: false,
              assignmentId,
              deliverableId,
              wasModified,
            };

            // if we've added an item to our checkbox states we need to add the id
            this.selectableIds.push(id);
          }
          return acc;
        },
        initialState
      );

  /**
   * When we receive data (from async) we need to add the checkbox states to our local state
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.data.length !== nextProps.data.length) {
      this.setState({
        checkboxStates: this.convertDataArrayToCheckboxStates(nextProps.data, {
          ...this.state.checkboxStates,
        }),
      });
    }
  }

  toggleAll = () => {
    const newCheckedState = !this.allChecked();

    const { checkboxStates } = this.state;
    const newCheckboxStates = this.selectableIds.reduce((acc, id) => {
      acc[id] = {
        ...checkboxStates[id],
        checked: newCheckedState,
      };
      return acc;
    }, {});

    this.setState({ checkboxStates: newCheckboxStates });
  };

  /**
   * Toggles a single items checked property
   *
   * @param id the deliverable id of the item we're toggling
   */
  toggleItem = (id) => {
    const checkboxState = { ...this.state.checkboxStates[id] };
    checkboxState.checked = !checkboxState.checked;

    // update with the new checked status
    const checkboxStates = Object.assign(this.state.checkboxStates, {
      [id]: checkboxState,
    });

    this.setState({ checkboxStates });
  };

  /**
   * @return {Array} all selectable rows as an array (also with the id added in)
   */
  getAllSelectableRows = () =>
    this.selectableIds.map((id) => ({ id, ...this.state.checkboxStates[id] }));

  getCheckedRows = () =>
    this.getAllSelectableRows().filter(({ checked }) => checked);

  /**
   * @return {number[]} An array of all the checked rows assignments ids
   */
  getCheckedAssignmentIds = () => this.getCheckedRows().map(({ id }) => id);

  /**
   * @return {number[]} An array of all the checked row deliverable ids
   */
  getCheckedDeliverableIds = () =>
    this.getCheckedRows().map((r) => r.deliverableId);

  /**
   * @return {bool} whether all selectable assignments are checked
   */
  allChecked = () => {
    const selectableRows = this.getAllSelectableRows();
    const checkedRows = this.getCheckedRows();

    return selectableRows.length === checkedRows.length;
  };

  hasSelectableTask = () => this.getAllSelectableRows().length > 0;

  /**
   * Uncheck some rows
   *
   * @param {number[]} rowIds deliverableId/assignmentId depending on admin/client pages...
   */
  uncheckRows = (rowIds) => {
    const checkboxStates = rowIds.reduce(
      (acc, id) => {
        acc[id] = {
          ...acc[id],
          checked: false,
        };

        return acc;
      },
      { ...this.state.checkboxStates }
    );

    this.setState({ checkboxStates });
  };

  /**
   * This function will process a transition on all the selected rows
   *
   * @param transition this will be passed in based on which button was clicked
   */
  processTransition = async (transition) => {
    const { checkForUnconfirmedTMSegments, shouldShowTM, showWarningMessage } =
      this.props;

    // only grab the actionable items
    const checkedRows = this.getCheckedRows();

    let actionableRows;
    actionableRows = checkedRows.filter(({ actionable }) => actionable);

    if (transition.transitionName.includes("amends")) {
      actionableRows = actionableRows.filter(({ wasModified }) => !wasModified);
    }

    const nonActionableRows = checkedRows.filter(
      ({ actionable }) => !actionable
    );

    // If none of the rows selected can be transitioned we should just show an "error message"
    if (actionableRows.length === 0) {
      return this.props.showErrorMessage(
        "No rows selected can be transitioned (they might not be at the current stage)"
      );
    }

    const assignmentIds = actionableRows.map(({ id }) => id);

    if (shouldShowTM) {
      const data = await checkForUnconfirmedTMSegments(assignmentIds);

      if (data.status === "error" && data.type === "UNCONFIRMED_SEGMENTS") {
        return showWarningMessage(data.message);
      }
    }

    // to "temporarily" help with assignment list page submission by adding an
    // action to the error message to remove unsaved delvierables
    const callbackObj = {
      message: "Click here to unselect all unsaved deliverables",
      fn: this.uncheckRows,
    };

    try {
      // Attempt transitions. Display QualityGradingForm if applicable and await submission
      const result = await this.props.processMultipleTransitions(
        transition,
        assignmentIds,
        callbackObj
      );

      const {
        deliverableIds,
        meta: { eventType },
      } = result;

      if (
        transition.transitionName.includes("QA") &&
        transition.forwardTransition
      ) {
        this.props.processTransitionGrammarCheck({ deliverableIds });
      }

      if (eventType === ResponseTypes.PLAGIARISM_CHECK_INITIATED) {
        return this.uncheckRows(assignmentIds);
      }

      // if we don't remove actioned items they still get checked with the 'all' checkbox
      this.removeProcessedAssignmentIds(assignmentIds);

      // Now everything has been processed we should update the user
      const actionableCount = actionableRows.length;
      const nonActionableCount = nonActionableRows.length;

      if (nonActionableCount === 0) {
        const transitionMessage = createTransitionSuccessMessage(
          deliverableIds,
          transition.transitionName
        );

        return this.props.showSuccessMessage(transitionMessage);
      }

      const transitionMessage = createTransitionWarningMessage(
        actionableCount,
        actionableCount + nonActionableCount,
        transition.transitionName
      );

      this.props.showWarningMessage(transitionMessage);
    } catch (err) {} // unsaved deliverables handled in the modules
  };

  /**
   * Download all the deliverables in excel format
   */
  downloadExcel = () =>
    this.props.download(
      {
        deliverableIds: this.props.deliverableIds,
        baseUrl: this.props.baseUrl,
      },
      "excel"
    );

  removeProcessedAssignmentIds = (assignmentIds) => {
    this.selectableIds = this.selectableIds.filter(
      (id) => !assignmentIds.includes(id)
    );

    const checkboxStates = assignmentIds.reduce(
      (acc, id) => {
        if (acc[id]) delete acc[id];
        return acc;
      },
      { ...this.state.checkboxStates }
    );

    this.setState({ checkboxStates });
  };

  convertDeliverableIdsToAssignmentIds = (deliverableIds) => {
    const idMap = this.props.data.reduce((acc, d) => {
      const { assignmentId, deliverableId } = d;
      acc[deliverableId] = assignmentId;
      return acc;
    }, {});

    // only add assignmentIds if they actually exist - useful for determining
    // whether we're using assignmentIds/deliverableIds
    const assignmentIds = deliverableIds.reduce((acc, id) => {
      const assignmentId = idMap[id];

      if (assignmentId) {
        acc.push(assignmentId);
      }

      return acc;
    }, []);

    return assignmentIds;
  };

  /**
   * This will be called once the rate form submits a value.
   * It will attempt to create a new assignment group with the updated
   * values and then inform the user with success/error messages.
   */
  updateAssignments = async () => {
    const {
      form: values,
      hideModal,
      showSuccessMessage,
      showErrorMessage,
      updateAssignments,
    } = this.props;

    const assignmentIds = this.getCheckedAssignmentIds();

    try {
      // ids are returned if they are successfully updated
      const updatedAssignmentIds = await updateAssignments(
        assignmentIds,
        values
      );

      // build a list of messages to show based on which fields were changed
      const messages = [];
      const valueStrings =
        updatedAssignmentIds.length > 1
          ? {
              rate: "rates of the assignments were",
              deadline: "deadlines of the assignments were",
            }
          : {
              rate: "rate of the assignment was",
              deadline: "deadline of the assignment was",
            };

      if (values.rate)
        messages.push(
          <CurrencyDisplay
            rate={values.rate}
            text={`The ${valueStrings.rate} changed to:`}
            currencyCode={this.props.currencyCode}
          />
        );
      if (values.deadline)
        messages.push(
          `The ${valueStrings.deadline} changed to: ${formatDateObject(
            new Date(values.deadline)
          )}`
        );

      showSuccessMessage(messages);
      hideModal();
      this.removeProcessedAssignmentIds(updatedAssignmentIds);
    } catch (err) {
      showErrorMessage([
        "We could not currently update the rates",
        extractErrorFromObject(err),
      ]);
    }
  };

  manualOverrideFn = async (stageId) => {
    const {
      runWorkflowRevert,
      showSuccessMessage,
      showWarningMessage,
      showErrorMessage,
    } = this.props;

    const deliverableIds = this.getCheckedDeliverableIds();
    const updatedDeliverableIds = await runWorkflowRevert({
      stageId,
      deliverableIds,
    });

    if (updatedDeliverableIds.length === 0) {
      showErrorMessage(
        "No deliverables could be recalled to the selected stage"
      );
    }

    const assignmentIds = this.convertDeliverableIdsToAssignmentIds(
      updatedDeliverableIds
    );

    // the container uses a mix of assignmentIds and deliverableIds so we need
    // to check find which IDs we are using - quite janky tbh
    if (assignmentIds.length > 0) {
      this.uncheckRows(assignmentIds);
    } else {
      this.uncheckRows(updatedDeliverableIds);
    }

    if (updatedDeliverableIds.length === deliverableIds.length) {
      const successMessage =
        deliverableIds.length === 1
          ? "1 deliverable was recalled"
          : `${deliverableIds.length} deliverables were recalled`;

      showSuccessMessage(successMessage);
    } else {
      showWarningMessage(
        `${updatedDeliverableIds.length}/${deliverableIds.length} deliverables were recalled`
      );
    }
  };

  render() {
    const {
      assignmentGroupAdjustment,
      baseUrl,
      briefingFields,
      canUpdateRates,
      canUpdateAssignments,
      currencyCode,
      data,
      exportContent,
      isAtQA,
      includeRates,
      isFreelancer,
      isInHouse,
      messages,
      transitions,
    } = this.props;

    // if there is no data, the assignment group may have all been reassigned
    if (!data || data.length === 0) {
      return (
        <div className={styles.infoContainer}>
          {messages.display && <Messages />}
          <div>
            There are no assignments actionable left in this assignment group
            (they may have all been reassigned)
          </div>
        </div>
      );
    }

    const isSelectable = this.hasSelectableTask();
    // everything is disabled if no rows are selectable
    const disabled = !isSelectable;

    const includePreviousAssignee = data.some(
      ({ previousAssignee }) => previousAssignee
    );

    // if any assignment included has a status (set by the container) then we show status'
    const includeStatus = data.some(({ status }) => status);

    const includeDeliverableId = data.some(
      ({ deliverableId }) => deliverableId
    );

    const batchViewUrl = baseUrl.replace("/tasks", "/all-tasks");

    // once a checkbox is selected we can activate the buttons
    const anyCheckboxesSelected = data.some(
      ({ id }) =>
        this.state.checkboxStates[id] && this.state.checkboxStates[id].checked
    );

    const hasActiveAdjustment = assignmentGroupAdjustment?.actioned === false;
    const isUpdateAssignmentsEnabled =
      anyCheckboxesSelected && !hasActiveAdjustment;

    return (
      <div className={styles.mainContainer}>
        {messages.display && <Messages />}

        <Modal
          body={
            <UpdateAssignmentsForm
              canUpdateRates={canUpdateRates}
              hideModal={this.props.hideModal}
              initialValues={this.props.assignmentDefaultValues}
              onSubmit={this.updateAssignments}
              currencyCode={currencyCode}
            />
          }
          type={ModalType.UpdateAssignments}
        />

        <div className={styles.container}>
          <BriefingFieldHeader
            allChecked={this.allChecked()}
            briefingFields={briefingFields}
            disabled={disabled}
            includeDeliverableId={includeDeliverableId}
            includePreviousAssignee={includePreviousAssignee}
            includeRates={includeRates}
            includeStatus={includeStatus}
            toggleAll={this.toggleAll}
          />
          {data.map(
            ({
              actionable,
              archived,
              briefingFieldValues,
              deliverableId,
              id,
              previousAssignee,
              rate,
              selectable,
              status,
              wasModified,
              wasAmended,
            }) => (
              <BriefingFieldRow
                key={id}
                actionable={actionable}
                archived={archived}
                baseUrl={baseUrl}
                briefingFieldValues={briefingFieldValues}
                checked={
                  this.state.checkboxStates[id] &&
                  this.state.checkboxStates[id].checked
                }
                deliverableId={deliverableId}
                id={id}
                previousAssignee={previousAssignee}
                rate={
                  includeRates ? (
                    <CurrencyDisplay
                      rate={rate}
                      currencyCode={this.props.currencyCode}
                    />
                  ) : null
                }
                selectable={selectable}
                status={status}
                toggleItem={this.toggleItem}
                wasModified={wasModified && this.props.allowClientChanges}
                wasAmended={wasAmended}
              />
            )
          )}
        </div>

        <div className={styles.buttonContainer}>
          <div className={styles.alignLeft}>
            {exportContent && (
              <button className={styles.button} onClick={this.downloadExcel}>
                Download
              </button>
            )}

            {isAtQA && isInHouse && (
              <FeatureToggle toggle="bulkQA">
                <BatchViewBtn url={batchViewUrl} />
              </FeatureToggle>
            )}
            {isAtQA && isFreelancer && (
              <FeatureToggle toggle="freelancerBulkQA">
                <BatchViewBtn url={batchViewUrl} />
              </FeatureToggle>
            )}

            {canUpdateAssignments && (
              <button
                disabled={!isUpdateAssignmentsEnabled}
                onClick={this.props.showModal}
                className={styles.buttonAsLink}
              >
                <div className={styles.adjustmentTooltip}>
                  Unavailable while an assignment adjustment request is pending
                </div>
                Update assignments
              </button>
            )}
          </div>
          {isSelectable && (
            <div className={styles.alignRight}>
              <Transitions
                manualOverrideFn={this.manualOverrideFn}
                disable={!anyCheckboxesSelected}
                processTransition={this.processTransition}
                transitions={transitions}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

BriefingFieldContainer.propTypes = {
  assignmentDefaultValues: PropTypes.shape({
    deadline: PropTypes.string,
    originalDeadline: PropTypes.string,
  }),
  baseUrl: PropTypes.string.isRequired,
  briefingFields: PropTypes.arrayOf(
    PropTypes.shape({
      briefingFieldId: PropTypes.number.isRequired,
      briefingFieldName: PropTypes.string.isRequired,
    })
  ).isRequired,
  canUpdateAssignments: PropTypes.bool,
  canUpdateRates: PropTypes.bool.isRequired,
  checkForUnconfirmedTMSegments: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actionable: PropTypes.bool,
      briefingFieldValues: PropTypes.arrayOf(PropTypes.string).isRequired,
      id: PropTypes.number.isRequired, // either a deliverableId or an assignmentId
      viewable: PropTypes.bool,
      wasAmended: PropTypes.bool,
    })
  ).isRequired,
  deliverableIds: PropTypes.array,
  download: PropTypes.func.isRequired,
  exportContent: PropTypes.bool,
  form: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  includeRates: PropTypes.bool,
  messages: PropTypes.shape({
    display: PropTypes.bool,
  }),
  modal: PropTypes.shape({
    display: PropTypes.bool,
  }),
  processMultipleTransitions: PropTypes.func.isRequired,
  shouldShowTM: PropTypes.bool,
  showErrorMessage: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showWarningMessage: PropTypes.func.isRequired,
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      fromStageId: PropTypes.number.isRequired,
      payStageId: PropTypes.number,
      toStageId: PropTypes.number.isRequired,
      transitionId: PropTypes.number.isRequired,
      transitionName: PropTypes.string.isRequired,
      workflowId: PropTypes.number.isRequired,
    })
  ).isRequired,
  updateAssignments: PropTypes.func.isRequired,
};

export default BriefingFieldContainer;
