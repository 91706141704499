import * as Sentry from "@sentry/react";
import { LOGIN_SUCCESS, FETCH_ME_SUCCESS } from "../../modules/me";

const addSentryUser = () => (next) => (action) => {
  const { type, payload } = action;
  if (type === LOGIN_SUCCESS || type === FETCH_ME_SUCCESS) {
    Sentry.setUser(payload);
  }
  return next(action);
};

export default addSentryUser;
