import { getGraphQL } from "../../../../../../../../utils/fetch";

import {
  accountsQuery,
  fetchAccountsSuccess,
} from "../../../../../../../modules/accounts";
import {
  exportFormatByProjectIdQuery,
  fetchExportFormatSuccess,
} from "../../../../../../../modules/exportFormats";
import {
  orderFormsQuery,
  fetchOrderFormsSuccess,
} from "../../../../../../../modules/orderForms";
import {
  projectQuery,
  fetchProjectSuccess,
} from "../../../../../../../modules/projects";
import {
  clientBatchDetailsQuery,
  fetchClientBatchDetailsSuccess,
} from "../../../../../../../modules/clientBatchDetails";
import {
  batchesByProjectIdQuery,
  fetchBatchesSuccess,
} from "../../../../../../../modules/batches";
import {
  projectLanguagesByProjectIdQuery,
  fetchProjectLanguagesByProjectIdSuccess,
} from "../../../../../../../modules/projectLanguages";

export const initialQuery = `query ClientProjectCompleted ($projectId: String){
  ${accountsQuery}
  ${orderFormsQuery}
  ${clientBatchDetailsQuery}
  ${exportFormatByProjectIdQuery}
  ${projectQuery}
  ${batchesByProjectIdQuery}
  ${projectLanguagesByProjectIdQuery}
}`;

export function getInitialData(params, url, cookies) {
  const { projectId } = params;
  return (dispatch, getState) => {
    return getGraphQL(initialQuery, { projectId }, url, cookies).then(
      (json) => {
        dispatch(fetchAccountsSuccess(json.accounts));
        dispatch(fetchExportFormatSuccess(json.exportFormat));
        dispatch(fetchOrderFormsSuccess(json.orderForms));
        dispatch(fetchProjectSuccess(json.project));
        dispatch(
          fetchProjectLanguagesByProjectIdSuccess(json.projectLanguages)
        );
        dispatch(
          fetchClientBatchDetailsSuccess({
            data: json.clientBatchDetails,
            projectId,
          })
        );
        dispatch(fetchBatchesSuccess(json.batches));
      }
    );
  };
}
