import gql from "graphql-tag";

const fields = [
  "assignmentGroupAdjustmentId",
  "assignmentGroupId",
  "adjustmentType",
  "deadline",
  "reason",
  "actioned",
];

// GraphQL tagged for useQuery/useMutation
export const GET_ADJUSTMENT_QUERY_GQL = gql`
  query getAssignmentGroupAdjustment($assignmentGroupId: Int) {
    assignmentGroupAdjustment(assignmentGroupId: $assignmentGroupId) {
      ${fields}
    }
  }
`;

export const UPDATE_ADJUSTMENT_GQL = gql`
  mutation updateAssignmentGroupAdjustment(
    $input: AssignmentGroupAdjustmentInput
  ) {
    updateAssignmentGroupAdjustment(input: $input) {
      ${fields}
    }
  }
`;

export const CREATE_ADJUSTMENT_QUERY_GQL = gql`
  mutation createAssignmentGroupAdjustment(
    $input: AssignmentGroupAdjustmentInput
  ) {
    createAssignmentGroupAdjustment(input: $input) {
      ${fields}
    }
  }
`;
