import React, { useState, useEffect } from "react";
import Modal from "../../../../../../../../../../components/Modal/components/Modal";
import Input from "../../../../../../../../../../components/Input";
import styles from "./AdminRequestModal.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import OverlayLoading from "../../../../../../../../../../components/OverlayLoading/v2";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { updateAssignmentGroup } from "../../../../../../../../../../modules/assignmentGroups";
import ReassignmentForm from "../../../../_components/ReassignmentForm";
import { createReassignments } from "../../../../../../../../../../modules/assignments";
import { projectById } from "../../../../../../../../../../modules/projects";
import {
  GET_ADJUSTMENT_QUERY_GQL,
  UPDATE_ADJUSTMENT_GQL,
} from "../../../../../../../../../../modules/assignmentGroupAdjustment";

const DeadlineTabs = {
  EXTENSION: "EXTENSION",
  REASSIGNMENT: "REASSIGNMENT",
  REJECTION: "REJECTED_REQUEST",
};

type DateTypes = {
  extensionDate?: string;
  iso8601date?: string;
};

export function formatAdjustmentDate(date: string | null): DateTypes {
  if (!date) return {};

  const [y, m, d, hour, min] = date.split(/-| |:/g);

  const extensionDate = `${d}/${m}/${y} ${hour}:${min}`;
  const iso8601date = `${y}-${m}-${d}T${hour}:${min}`;

  return { extensionDate, iso8601date };
}

function Tab({ children, onClick, isActive }) {
  const tabStyles = classNames({
    [styles.tab]: true,
    [styles.active]: isActive,
  });
  return (
    <div onClick={onClick} className={tabStyles}>
      {children}
    </div>
  );
}

function AdminRequestModal(props) {
  const { setIsOpen, assignmentGroupId, projectId, assigneeName } = props;

  const dispatch = useDispatch();
  const [deadlineTab, setDeadlineTab] = useState(DeadlineTabs.EXTENSION);
  const [deadline, setDeadline] = useState("");
  const [reason, setReason] = useState("");

  const project = useSelector((state) => projectById(state, projectId));

  const assignmentGroup = useSelector(
    // @ts-ignore
    (state) => state.assignmentGroups.entities[assignmentGroupId]
  );

  const reassignmentData = useSelector((state) => {
    // @ts-ignore
    return state?.form?.assignmentsForm?.values;
  });

  const isExtensionTab = deadlineTab === DeadlineTabs.EXTENSION;
  const isReassignmentTab = deadlineTab === DeadlineTabs.REASSIGNMENT;
  const isRejectionTab = deadlineTab === DeadlineTabs.REJECTION;

  const { data, loading } = useQuery(GET_ADJUSTMENT_QUERY_GQL, {
    variables: { assignmentGroupId },
  });

  useEffect(() => {
    // Check if data is loaded and available
    if (loading || !data) {
      return;
    }

    const { deadline, adjustmentType } = data.assignmentGroupAdjustment;
    const { iso8601date } = formatAdjustmentDate(deadline);

    // Update / Set the deadline state based on the `isExtensionTab` flag
    if (isExtensionTab) {
      updateDeadline({ target: { value: iso8601date } });
    } else {
      setDeadline("");
    }
    if (adjustmentType === "CANCELLATION") {
      setDeadlineTab(DeadlineTabs.REASSIGNMENT);
    }
  }, [loading, data, isExtensionTab]);

  const [updateAdjustment] = useMutation(UPDATE_ADJUSTMENT_GQL);

  const setExtension = () => {
    setDeadlineTab(DeadlineTabs.EXTENSION);
  };

  const setReassignment = () => {
    setDeadlineTab(DeadlineTabs.REASSIGNMENT);
  };

  const setRejection = () => {
    setDeadlineTab(DeadlineTabs.REJECTION);
  };

  const updateReason = (e) => {
    setReason(e.target.value);
  };

  const updateDeadline = (e) => {
    const newValue = e.target.value;
    const formattedValue = newValue ? `${newValue.replace(/T/, " ")}:00` : "";
    setDeadline(formattedValue);
  };

  const submitReassigned = async () => {
    const { assignees, deadlines } = reassignmentData;

    const input = {
      projectId,
      assignmentGroupId,
      assignees,
      deadlines,
      responseNote: reason,
    };
    dispatch(createReassignments(input));
  };

  const submit = async () => {
    if (isReassignmentTab) {
      return submitReassigned();
    }

    const { assignmentGroupAdjustmentId } = assignmentGroupAdjustment;

    const input = {
      assignmentGroupAdjustmentId,
      assignmentGroupId,
      responseType: deadlineTab,
      responseNote: reason,
      ...(isExtensionTab ? { responseDeadline: deadline } : {}),
    };

    const response = await updateAdjustment({
      variables: { input },
    });

    if (
      !response.errors &&
      response.data?.updateAssignmentGroupAdjustment?.actioned
    ) {
      setIsOpen(false);
      dispatch(
        updateAssignmentGroup({
          assignmentGroupId,
          hasRequestPending: false,
        })
      );
    }
  };

  // deadline is only required when it's an extension
  const isSubmitDisabled = isExtensionTab && deadline.length === 0;

  if (loading) {
    return <OverlayLoading />;
  }

  const { assignmentGroupAdjustment } = data;

  const { extensionDate, iso8601date } = formatAdjustmentDate(
    assignmentGroupAdjustment.deadline
  );

  const isAdjustmentExtension =
    assignmentGroupAdjustment.adjustmentType === "EXTENSION";

  const isAdjustmentCancellation =
    assignmentGroupAdjustment.adjustmentType === "CANCELLATION";

  return (
    <Modal
      medium
      display
      hideModal={(e) => {
        e.stopPropagation();
        setIsOpen(false);
      }}
      padding30
      title={"Adjust assignment"}
      body={
        <div>
          <div>
            <span>{assigneeName} has requested to </span>
            {isAdjustmentExtension ? (
              <span>extend the deadline to {extensionDate} </span>
            ) : (
              <span>cancel their assignment </span>
            )}
            <span>for the following reason:</span>

            <div>&nbsp;</div>
            <div>"{assignmentGroupAdjustment.reason}"</div>
          </div>

          <div className={styles.deadlineTabs}>
            {isAdjustmentExtension && (
              <>
                <Tab onClick={setExtension} isActive={isExtensionTab}>
                  Extend deadline
                </Tab>
                <Tab onClick={setReassignment} isActive={isReassignmentTab}>
                  Reassign work
                </Tab>
                <Tab onClick={setRejection} isActive={isRejectionTab}>
                  Reject request
                </Tab>
              </>
            )}
            {isAdjustmentCancellation && (
              <>
                <Tab onClick={setReassignment} isActive={isReassignmentTab}>
                  Reassign work
                </Tab>
                <Tab onClick={setRejection} isActive={isRejectionTab}>
                  Reject request
                </Tab>
              </>
            )}
          </div>

          {isExtensionTab && (
            <div className={styles.deadlineTime}>
              <span>New deadline</span>
              <Input
                type="datetime-local"
                input={{ name: "ab" }}
                defaultValue={iso8601date}
                customStyles={styles.customInputStyles}
                max="9999-12-31T00:00"
                onChange={updateDeadline}
              />
            </div>
          )}

          {isReassignmentTab && (
            <ReassignmentForm
              // @ts-ignore
              useModal={false}
              project={project}
              assignmentGroup={assignmentGroup}
              match={{ params: { projectId } }}
              isInlineForm
            />
          )}

          <div className={styles.footerSection}>
            <div className={styles.requestContainer}>
              <div className={styles.requestText}>Note to {assigneeName}</div>
              <textarea
                onChange={updateReason}
                className={styles.requestReason}
                value={reason}
              />
            </div>

            <div className={styles.btnContainer}>
              <button
                disabled={isSubmitDisabled}
                className={styles.submit}
                type="submit"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default AdminRequestModal;
