import { batchActions } from "redux-batched-actions";
import { getGraphQL, getJson } from "../../../utils/fetch";
import {
  bannedWordsByProjectIdQuery,
  fetchBannedWordsSuccess,
} from "../../modules/bannedWords";
import {
  commentsByDeliverableIdQuery,
  fetchCommentsSuccess,
} from "../../modules/comments";
import {
  commentGroupsByDeliverableIdQuery,
  fetchCommentGroupsSuccess,
} from "../../modules/commentGroups";
import { gCloudQuery, createJWTSuccess } from "../../modules/gCloud";
import {
  briefingFieldsByProjectIdQuery,
  fetchBriefingFieldsSuccess,
} from "../../modules/briefingFields";
import {
  keywordsByDeliverableIdsQuery,
  fetchKeywordsSuccess,
} from "../../modules/keywords";
import {
  parentDeliverableBriefingFieldsByDeliverableIdsQuery,
  fetchParentDeliverableBriefingFieldsSuccess,
} from "../../modules/parentDeliverableBriefingFields";
import {
  parentDeliverableSourceFieldsByDeliverableIdsQuery,
  fetchParentDeliverableSourceFieldsSuccess,
} from "../../modules/parentDeliverableSourceFields";
import {
  parentDeliverablesByDeliverableQuery,
  fetchParentDeliverablesSuccess,
} from "../../modules/parentDeliverables";
import {
  sourceFieldsByDeliverableIdsQuery,
  fetchSourceFieldsSuccess,
} from "../../modules/sourceFields";
import {
  taskFieldsByProjectQuery,
  fetchTaskFieldsSuccess,
} from "../../modules/taskFields";
import {
  latestNonClientContentQuery,
  fetchTaskFieldContentSuccess,
  taskFieldContentByDeliverableIdsQueryV2,
} from "../../modules/taskFieldContent";
import {
  deliverableWithAdjacentIds,
  fetchDeliverablesSuccess,
} from "../../modules/deliverables";

import {
  taskFieldRateBandsQuery,
  fetchTaskFieldRateBandsSuccess,
} from "../../modules/taskFieldRateBands";
import {
  fetchGrammarCheckIgnoresSuccess,
  GET_GRAMMAR_CHECK_IGNORES,
} from "../../modules/grammarCheckIgnores";
import { updateGlossarySuccess } from "../../modules/glossary";
import { mergeBySourceSegmentId } from "../../routes/Admin/Glossary/modules/getInitialData";
import {
  accountsByAssignmentGroupIdQuery,
  fetchAccountSuccess,
} from "../../modules/accounts";

export const QUERY_ASSIGNMENT_EDIT_INITIAL_DATA = `
  query AssignmentEdit_getInitialData ($deliverableId: String,$projectId: String, $assignmentGroupId: String) {
    ${bannedWordsByProjectIdQuery}
    ${briefingFieldsByProjectIdQuery}
    ${commentsByDeliverableIdQuery}
    ${commentGroupsByDeliverableIdQuery}
    ${deliverableWithAdjacentIds}
    ${gCloudQuery}
    ${keywordsByDeliverableIdsQuery}
    ${parentDeliverablesByDeliverableQuery}
    ${parentDeliverableBriefingFieldsByDeliverableIdsQuery}
    ${parentDeliverableSourceFieldsByDeliverableIdsQuery}
    ${sourceFieldsByDeliverableIdsQuery}
    ${taskFieldContentByDeliverableIdsQueryV2}
    ${taskFieldsByProjectQuery}
    ${latestNonClientContentQuery}
    ${taskFieldRateBandsQuery}
    ${GET_GRAMMAR_CHECK_IGNORES}
    ${accountsByAssignmentGroupIdQuery}
  }
`;

const translationUrl = () =>
  `${
    window.__REACT_APP_API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY
  }/translation-memory`;

export function getInitialData(props, url, cookies) {
  const { projectId, deliverableId, languageCode, assignmentGroupId } = props;

  const params = {
    projectId,
    deliverableId,
    assignmentGroupId,
  };

  return async (dispatch) => {
    const json = await getGraphQL(
      QUERY_ASSIGNMENT_EDIT_INITIAL_DATA,
      params,
      url,
      cookies
    );

    const [{ accountId }] = json.accounts || {};

    const glossaryTranslations = await getJson(`${translationUrl()}/glossary`, {
      accountId,
      targetLanguage: languageCode,
    });

    const mergedGlossary = mergeBySourceSegmentId(glossaryTranslations);

    dispatch(
      batchActions([
        createJWTSuccess(json.gCloud.JWT),
        fetchBannedWordsSuccess(json.bannedWords),
        fetchBriefingFieldsSuccess(json.briefingFields),
        fetchCommentsSuccess(json.comments),
        fetchCommentGroupsSuccess(json.commentGroups),
        fetchDeliverablesSuccess(json.deliverable),
        fetchKeywordsSuccess(json.keywords),
        fetchParentDeliverableBriefingFieldsSuccess(
          json.parentDeliverableBriefingFields
        ),
        fetchParentDeliverableSourceFieldsSuccess(
          json.parentDeliverableSourceFields
        ),
        fetchSourceFieldsSuccess(json.sourceFields),
        fetchTaskFieldContentSuccess(json.taskFieldContentV2),
        fetchTaskFieldContentSuccess(json.latestNonClientContent),
        fetchTaskFieldsSuccess(json.taskFields),
        fetchTaskFieldRateBandsSuccess(json.taskFieldRateBands),
        fetchParentDeliverablesSuccess(json.parentDeliverables),
        fetchGrammarCheckIgnoresSuccess(json.grammarCheckIgnores),
        fetchAccountSuccess(json.accounts),
        updateGlossarySuccess(mergedGlossary),
      ])
    );
  };
}
