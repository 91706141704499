import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";

export const clientBatchDetailsQuery = `clientBatchDetails(projectId: $projectId) { 
  batchId, batchName, languageCode, stageType, stageName, count 
}`;
export const FETCH_CLIENT_BATCH_DETAILS_SUCCESS =
  "FETCH_CLIENT_BATCH_DETAILS_SUCCESS";

export const fetchClientBatchDetailsSuccess = createAction(
  FETCH_CLIENT_BATCH_DETAILS_SUCCESS
);

export const clientBatchDetailsActionHandlers = {
  [RESET_INITIAL_STATE]: () => clientBatchDetailsInitialState,
  [FETCH_CLIENT_BATCH_DETAILS_SUCCESS]: (
    state,
    { payload: { data, projectId } }
  ) =>
    // add the projectId into the batch details so we can KNOW that we are selecting the right batch/stage details
    data.map((batchDetails) => ({
      ...batchDetails,
      projectId: Number(projectId),
    })),
};

export const clientBatchDetailsInitialState = [];
