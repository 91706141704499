import React from "react";
import PropTypes from "prop-types";

import { QuillForm, QuillFormField } from "../../../../../components/QuillForm";
import {
  InputWithErrors,
  SelectWithErrors,
} from "../../../../../decorators/WithErrors";
import { reduxForm } from "redux-form";

import validate from "./validations";

const normalizeOrderFormId = (value, previousValue) => {
  // Allow people to delete the initial digit if re-entering the input
  if (previousValue) {
    const length = previousValue.toString().length;
    if (!value && length === 1) return null;
  }
  if (value > 9999) return Number(previousValue);
  if (value < 0) return Number(previousValue);
  return Number(value);
};

const returnNumber = (val) => Number(val) || null;

let OrderForm = (props) => {
  return (
    <QuillForm
      header={props.header}
      cancelText="Cancel"
      cancelHandler={props.hideModal}
      submitText="Save"
      disabled={props.submitting || !props.valid}
      handleSubmit={props.handleSubmit}
      error={props.error}
    >
      <QuillFormField
        name="accountId"
        component={SelectWithErrors}
        type="number"
        options={props.accounts}
        disabled
        hidden
      />
      <QuillFormField
        labelText="Entity"
        name="entity"
        required
        clearable={false}
        component={SelectWithErrors}
        type="text"
        options={props.orderFormEntities}
      />
      <QuillFormField
        labelText="Order form #"
        name="orderFormNumber"
        required
        component={InputWithErrors}
        type="number"
        parse={returnNumber}
        max={9999}
        normalize={normalizeOrderFormId}
      />
      <QuillFormField
        labelText="Order form name"
        required
        name="orderFormName"
        component={InputWithErrors}
        type="text"
      />
    </QuillForm>
  );
};

OrderForm.propTypes = {
  header: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  error: PropTypes.string,
  entity: PropTypes.array,
};

/*  Decorate the component with reduxForm(). */
OrderForm = reduxForm({
  form: "orderForm",
  validate,
})(OrderForm);

export default OrderForm;
