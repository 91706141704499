import { connect } from "react-redux";
import DashboardFilters from "../components/DashboardFilters";
import { getInitialData } from "../modules/getInitialData";
import WithData from "../../../../../../decorators/WithData";
import { projectLanguagesSelector } from "../../../../../../utils/entitySelector";
import {
  appComponentSelector,
  setAppComponentData,
} from "../../../../../../modules/appComponents";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps)),
  setActiveLanguage: (activeLanguage) =>
    dispatch(
      setAppComponentData({
        component: "overviewList",
        data: { activeLanguage },
      })
    ),
  setReadyForReview: (readyForReview) =>
    dispatch(
      setAppComponentData({
        component: "overviewList",
        data: { readyForReview },
      })
    ),
});

const mapStateToProps = (state, ownProps) => {
  const projectLanguages = projectLanguagesSelector(state, ownProps.projectId);
  const { activeLanguage, readyForReview } = appComponentSelector(
    state,
    "overviewList"
  );

  return {
    activeLanguage,
    projectLanguages,
    readyForReview,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(DashboardFilters));
