import React from "react";
import { createSelector } from "reselect";
import PropTypes from "prop-types";
import styles from "./WordHighlight.module.scss";
import { wordMatchRegex } from "../../../../../../../utils/string";

const createWordHighlightStrategy = (words = []) => {
  const regex = wordMatchRegex(words);

  return function wordStrategy(contentBlock, callback) {
    const text = contentBlock.getText();

    if (words.length === 0 || text.trim().length === 0) return;

    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  };
};

const WordHighlightComponent = ({ children, highlightStyle }) => (
  <span className={highlightStyle}>{children}</span>
);

WordHighlightComponent.propTypes = {
  children: PropTypes.array,
  highlightStyle: PropTypes.string,
};

/**
 * This function returns a function  to be called with an array of words which returns a decorator object for draft js
 *
 * @param {string[]} words words to search for
 * @param {object} highlightStyle a CSS style to apply to matching words
 */
function createWordHighlightPlugin(words, highlightStyle) {
  return {
    strategy: createWordHighlightStrategy(words),
    component: WordHighlightComponent,
    props: { highlightStyle },
  };
}

/**
 * @param {string[]} words keywords to look for
 * @returns {Object} DraftJS decorator
 */
export const keywordsHighlightPlugin = createSelector(
  (words) => words,
  (words) => createWordHighlightPlugin(words, styles.keyword)
);

/**
 * @param {string[]} words banned words to look for
 * @returns {Object} DraftJS decorator
 */
export const bannedWordsHighlightPlugin = createSelector(
  (words) => words,
  (words) => createWordHighlightPlugin(words, styles.bannedWord)
);

export const glossaryWordsHighlightPlugin = createSelector(
  (words) => words,
  (words) => createWordHighlightPlugin(words, styles.glossaryWord)
);
