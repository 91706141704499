import { connect } from "react-redux";
import BriefingFieldContainer from "../components/BriefingFieldContainer";
import { download } from "../../../modules/download";
import { showModal, hideModal, ModalType } from "../../../modules/modal";
import { updateAssignments } from "../../../modules/assignments";
import {
  showSuccessMessage,
  showWarningMessage,
  showErrorMessage,
} from "../../../modules/messages";
import { checkForUnconfirmedTMSegments } from "../../../modules/taskFieldContent";
import {
  personById,
  assignmentsByGroupSelector,
} from "../../../utils/entitySelector";
import {
  fromUTCString,
  toInputString,
  toMysqlFormat,
} from "../../../utils/date";
import { shouldShowTM } from "../../../modules/translations";
import { runWorkflowRevert } from "../../../modules/deliverables";
import { get } from "lodash/fp";
import { processTransitionGrammarCheck } from "../../../modules/webSpellChecker";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    checkForUnconfirmedTMSegments: (assignmentIds) =>
      dispatch(checkForUnconfirmedTMSegments(assignmentIds)),
    download: (data, type) => dispatch(download(data, type)),
    showModal: () => dispatch(showModal({ type: ModalType.UpdateAssignments })),
    hideModal: () => dispatch(hideModal()),
    updateAssignments: (
      assignmentIds,
      { originalDeadline, deadline, rate }
    ) => {
      const updateValues = {};

      if (deadline !== originalDeadline) {
        updateValues.deadline = toMysqlFormat(new Date(deadline));
      }

      if (rate) updateValues.rate = rate;

      return dispatch(updateAssignments(assignmentIds, updateValues));
    },
    runWorkflowRevert: (request) => dispatch(runWorkflowRevert(request)),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    showWarningMessage: (message) => dispatch(showWarningMessage(message)),
    processTransitionGrammarCheck: (deliverableIds) =>
      dispatch(processTransitionGrammarCheck(deliverableIds, ownProps)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { assignmentGroupId, baseUrl, data, isInProduction, projectId } =
    ownProps;

  const {
    assignments: { entities: assignmentEntities },
    me,
    messages,
    modal,
    stages: { entities: stageEntities },
  } = state;
  const { personType } = personById(state, me);
  const isFreelancer = personType === "Freelancer";
  const isInHouse = personType === "In-house";

  /* ownProps.data is an object with keys of assignmentIds (although this is only true for pages which have the download icon visible)
   * this could potentially be a problem if we have more pages which render this and pass deliverableIds instead of assignmentIds */
  const assignmentIds = data.map(({ id }) => id);

  const assignments = assignmentIds
    // convert ids to assignments and add to our deliverableId array
    .map((id) => assignmentEntities[id])
    // remove blanks if for some reason we can't map them
    .filter((id) => id);
  const stageId = assignments.length > 0 && assignments[0].stageId;

  /* This will be an empty array on 'Completed' pages which use BriefingFieldContainer
   * but a list of deliverableIds from assignmentIds if we're on AssignmentList (group) pages */
  const deliverableIds = assignments.map(({ deliverableId }) => deliverableId);

  // sometimes we want to allow assignments to have their deadlines changed but not the rates (as they aren't chargeable stages)
  const includeRates =
    assignments.some(({ stageId }) => stageEntities[stageId].chargeable) &&
    isInProduction;
  const canUpdateRates = isInHouse && includeRates;
  const canUpdateAssignments = isInHouse && isInProduction;

  const form = get("form.updateAssignmentsForm.values", state);

  // show a "placeholder" deadline (and store an original one so we know things have been changed)
  const assignmentDefaultValues = {};
  if (canUpdateAssignments && assignments.length > 0) {
    const deadline = toInputString(fromUTCString(assignments[0].deadline));
    assignmentDefaultValues.originalDeadline = deadline;
    assignmentDefaultValues.deadline = deadline;
  }

  const [assignmentByGroup] = assignmentsByGroupSelector(
    state,
    Number(assignmentGroupId)
  );
  const isAtQA =
    assignmentByGroup &&
    (stageEntities[assignmentByGroup.stageId].stageName.includes("QA") ||
      stageEntities[assignmentByGroup.stageId].stageName.includes("amends"));

  return {
    assignmentDefaultValues,
    baseUrl,
    canUpdateRates,
    canUpdateAssignments,
    deliverableIds,
    form,
    isAtQA,
    includeRates,
    isFreelancer,
    isInHouse,
    messages,
    projectId,
    shouldShowTM: shouldShowTM(state, projectId, stageId),
    modal,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefingFieldContainer);
