import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.scss";
import useOutsideClick from "../../../hooks/useOutsideClick";
import fuzzysort from "fuzzysort";
import Icon from "../../Icon";

const normalizeStr = (str) => str.replace(/_/g, " ");
const MAX_CHARS = 30;
const MAX_CHARS_SMALL = 15;

const Dropdown = (props) => {
  const {
    options,
    optionsArr,
    disabled,
    onClick,
    openUpwards,
    hideSelectAll,
    filterText,
    rightAligned,
    small,
    mainButtonStyles,
    titleExtraStyles,
    mainContainerExtraStyles,
  } = props;

  // give the title a max char limit
  const title = useMemo(() => {
    const { title } = props;

    if (small && title.length >= MAX_CHARS_SMALL) {
      return title.substring(0, MAX_CHARS_SMALL - 3) + "...";
    } else if (!small && title.length > MAX_CHARS) {
      return title.substring(0, MAX_CHARS - 3) + "...";
    }
    return title;
  }, [props, small]);

  const node = useRef();
  const filterNode = useRef();

  const [hidden, setHidden] = useState(true);
  const containerOnClick = (e) => {
    e.preventDefault();

    if (disabled) return;

    setHidden(!hidden);
  };

  const processedOptions = useMemo(() => {
    if (optionsArr) return optionsArr;

    return Object.entries(options).map(([id, value]) => ({
      id,
      value,
      normalized: normalizeStr(value),
    }));
  }, [options, optionsArr]);

  // refactor needed to pass in arrays everywhere instead of object gubbins
  const [filteredOptions, setFilteredOptions] = useState(
    optionsArr || processedOptions
  );

  useOutsideClick(node, () => {
    setHidden(true);
  });

  const inputFocus = (e) => {
    e.preventDefault();
  };

  const onItemClick = ({
    target: {
      dataset: { id },
    },
  }) => {
    setHidden(true);
    onClick(isNaN(id) ? id : Number(id));
  };

  const onChange = ({ target: { value } }) => {
    if (value.length < 1) {
      return setFilteredOptions(processedOptions);
    }

    const normalizedSearchStr = normalizeStr(value);

    const results = fuzzysort
      .go(normalizedSearchStr, processedOptions, { key: "normalized" })
      .map((r) => r.obj);

    setFilteredOptions(results);
  };

  // if the dropdown is opened and we have a filter search input then focus it
  useEffect(() => {
    if (!hidden && filterNode && filterNode.current) {
      filterNode.current.focus();
    }
  }, [hidden]);

  const mainContainerStyles = classNames({
    [styles.mainContainerStyles]: true,
    [styles.active]: !hidden,
    [styles.openUpwards]: openUpwards,
    [styles.disabled]: disabled,
    [styles.filterText]: !!filterText,
    [mainContainerExtraStyles]: true,
  });
  const containerStyles = classNames({
    [styles.containerStyles]: true,
    [styles.active]: !hidden,
    [mainButtonStyles]: true,
  });

  const dropdownStyles = classNames({
    [styles.dropdown]: true,
    [styles.hidden]: hidden,
    [styles.rightAligned]: rightAligned,
    [styles.small]: small,
  });

  const titleStyles = classNames({
    [styles.titleStyles]: true,
    [styles.disabled]: disabled,
    [titleExtraStyles]: true,
  });

  return (
    <span ref={node} className={mainContainerStyles}>
      <span className={containerStyles} onClick={containerOnClick}>
        <span className={titleStyles}>{title}</span>
        <Icon name="DropdownArrow" />
      </span>

      <div className={dropdownStyles}>
        {!!filterText && (
          <div className={styles.dropdownItem}>
            <input
              ref={filterNode}
              className={styles.dropdownFilter}
              onChange={onChange}
              onClick={inputFocus}
              placeholder={filterText}
            />
          </div>
        )}

        {!hideSelectAll && (
          <div className={styles.selectAll} onClick={onItemClick}>
            Select all
          </div>
        )}

        {filteredOptions.map(({ id, value }) => (
          <div
            key={id}
            className={styles.dropdownItem}
            data-id={id}
            onClick={onItemClick}
          >
            {value}
          </div>
        ))}
      </div>
    </span>
  );
};

Dropdown.propTypes = {
  filterText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object,
  optionsArr: PropTypes.array,
  rightAligned: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Dropdown;
