import { getStageMaximums } from "./stageMaximums";

export const getAllocationUpdates = (options) => {
  const {
    languageCode,
    stages,
    defaultAssignees,
    numParentDeliverables,
    assignmentsFormValues: { assignees, assistedDistributions },
  } = options;

  const stageIds = Object.keys(assignees).map(Number);

  const stageMaximumValues = getStageMaximums({
    assignees,
    defaultAssignees,
    languageCode,
    stages,
    numParentDeliverables,
  });

  // create an array of redux-form update values
  const formValueUpdates = stageIds.reduce((acc, stageId) => {
    if (!assistedDistributions[stageId]) {
      return acc;
    }

    const stageDefaultAssignees = defaultAssignees[stageId]?.[languageCode];
    if (!stageDefaultAssignees) return acc;

    const stageAssignees = assignees?.[stageId]?.[languageCode];

    // filter the default assignees to those that are "available"
    const availableFreelancers = stageDefaultAssignees.filter(
      ({ personId }) => {
        const available = stageAssignees?.[personId]?.available;

        if (typeof available === "undefined") return true;

        return available;
      }
    );

    // training stages don't have the maximum deliverables to assign
    const stageMaximum = stageMaximumValues[stageId];

    // to split correctly we need to find the minimum amount of deliverables to
    // assign to each user before later correcting with addition tasks
    const lowerSplit = Math.floor(stageMaximum / availableFreelancers.length);
    const allocationSplit = availableFreelancers.reduce((acc, { personId }) => {
      acc[personId] = lowerSplit;
      return acc;
    }, {});

    // correct the totals (some freelancers need an extra task)
    let curAllocationTotal = availableFreelancers.length * lowerSplit;
    availableFreelancers.forEach(({ personId }) => {
      if (curAllocationTotal < stageMaximum) {
        allocationSplit[personId]++;
        curAllocationTotal++;
      }
    });

    // for each freelancer check if we need to update their allocation total
    stageDefaultAssignees.forEach(({ personId }) => {
      const isAvailable = !!availableFreelancers.find(
        (p) => p.personId === personId
      );

      const curAllocation = stageAssignees[personId].allocation;
      const allocation = isAvailable ? allocationSplit[personId] : 0;

      // only request a change if the new allocation is different from the last (infinite loop)
      if (curAllocation !== allocation) {
        acc.push([
          "assignmentsForm",
          `assignees[${stageId}][${languageCode}][${personId}][allocation]`,
          allocation,
        ]);
      }
    });

    return acc;
  }, []);

  return formValueUpdates;
};
