import { checkValidations, isNotBlank } from "../../../../../utils/validations";

const validate = (values) => {
  const errors = {};
  const accountIdErrors = checkValidations(values.accountId, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const entityErrors = checkValidations(values.entity, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const orderFormNumberErrors = checkValidations(values.orderFormNumber, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  const orderFormNameErrors = checkValidations(values.orderFormName, [
    { validate: isNotBlank, msg: "Required" },
  ]);
  if (accountIdErrors) errors.accountId = accountIdErrors;
  if (entityErrors) errors.entity = entityErrors;
  if (orderFormNumberErrors) errors.orderFormNumber = orderFormNumberErrors;
  if (orderFormNameErrors) errors.orderFormName = orderFormNameErrors;

  return errors;
};

export default validate;
