import React from "react";
import PropTypes from "prop-types";
import styles from "./LoginButton.module.scss";
import { useAuth0 } from "@auth0/auth0-react";

function LoginButton({ auth0Login, disabled }) {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      className={styles.loginButton}
      disabled={disabled}
    >
      Log In
    </button>
  );
}

LoginButton.propTypes = {
  auth0Login: PropTypes.bool,
};

export default LoginButton;
