import Completed from "../components/Completed";
import WithData from "../../../../../../../decorators/WithData";
import { connect } from "react-redux";
import { getInitialData } from "../modules/getInitialData";

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
});

const ClientProjectDashboardCompleted = connect(
  null,
  mapDispatchToProps
)(WithData(Completed));
export default ClientProjectDashboardCompleted;
