import { createAssignmentsSuccess } from "../../../../../../../modules/assignments";
import { updateDeliverablesSuccess } from "../../../../../../../modules/deliverables";
import { convertDate, formatDateObject } from "../../../../../../../utils/date";
import { postGraphQL } from "../../../../../../../../utils/fetch";
import handleErrors from "../../../../../../../utils/handleErrors";
import { get } from "lodash";

const AI_WORKFLOW_NAMES = [
  "AI Creation (1 Client Review)",
  "AI Creation (2 Client Reviews)",
  "AI / AMT Localisation (1 Client Review)",
];

function getRate({
  deliverableRatesMap,
  quotedRates = {},
  deliverableId,
  personId,
  stageId,
  langCode,
  language,
}) {
  // first see if a quoted rate exists
  const { rateBandId } = deliverableRatesMap[deliverableId] || {};
  const quotedRate = get(quotedRates, [
    langCode,
    stageId,
    personId,
    rateBandId,
  ]);
  if (quotedRate) {
    return quotedRate;
  }

  // fallback to rate band rate
  return get(
    deliverableRatesMap,
    [deliverableId, stageId, langCode],
    language[personId].rate
  );
}

export function createAssignments(data, history, params, options) {
  const assignmentsTree = {};
  const { assignees, deadlines, filteredProjectLanguages } = data;
  const {
    deliverableIds,
    stages,
    deliverables,
    deliverableRatesMap,
    quotedRates,
  } = options;
  const { projectId } = params;

  // loop over the stages object which is sorted by stagePosition so we can count training deliverables
  stages.forEach((stage, index) => {
    const stageId = stage.stageId;
    const isTrainingStage = stage.stageType === "Training";

    // build a tree object for the current stage
    if (!assignmentsTree[stageId]) {
      assignmentsTree[stageId] = {
        assignments: [],
        trainingDeliverableIds: [],
      };
    }

    Object.keys(assignees?.[stageId] || [])
      .filter((langCode) => filteredProjectLanguages.has(langCode))
      .forEach((langCode) => {
        const language = assignees[stageId][langCode];
        const unallocated = [...deliverableIds.unallocated[langCode]];
        Object.keys(language).forEach((personId) => {
          // extract the data from the redux form object
          const { training, allocation } = language[personId];

          for (let i = 0; i < allocation; i++) {
            let deliverableId;
            if (isTrainingStage) {
              // if training, splice from the previous stages available training deliverable ids
              deliverableId = assignmentsTree[
                stages[index - 1].stageId
              ].trainingDeliverableIds.splice(0, 1)[0];
            } else {
              // if not training then take from the unallocated
              deliverableId = unallocated.splice(0, 1)[0];
            }

            // if the deliverableId/stageId/langCode exists in the ratesMap then use that (basically feature toggled)
            const rate = getRate({
              deliverableRatesMap,
              deliverableId,
              quotedRates,
              deliverables,
              personId,
              stageId,
              langCode,
              language,
            });

            // add to the assignments object for the current stage
            assignmentsTree[stageId].assignments.push({
              deliverableId,
              personId,
              rate,
              stageId,
              deadline: formatDateObject(convertDate(deadlines[stageId], true)),
              inTraining: training,
              languageCode: langCode,
            });

            // if the current assignment is not training and it has training flag, then add this deliverableId for the training stage
            if (!isTrainingStage && training) {
              assignmentsTree[stageId].trainingDeliverableIds.push(
                deliverableId
              );
            }
          }
        });
      });
  });

  // build an array of assignments from the tree
  const stageIds = Object.keys(assignmentsTree);
  const assignments = stageIds.reduce((acc, key) => {
    acc.push(...assignmentsTree[key].assignments);
    return acc;
  }, []);

  return async (dispatch, getState) => {
    const state = getState();
    const { workflowId } = state.projects.entities[projectId] || {};
    const { workflowName, workflowType } =
      state.workflows.entities[workflowId] || {};

    const query = `mutation createAssignments($assignments: [AssignmentInput]) {
      createAssignments(assignments: $assignments) {
        assignmentId, stageId, personId, deliverableId, rate, deadline, status
      }
    }`;

    try {
      const json = await postGraphQL(
        query,
        { assignments },
        "createAssignments"
      );
      dispatch(createAssignmentsSuccess(json));

      const tree = assignments.reduce((acc, { deliverableId }) => {
        acc[deliverableId] = true;
        return acc;
      }, {});
      const deliverableIds = Object.keys(tree);

      const allocatedDeliverables = deliverableIds.map((deliverableId) => ({
        deliverableId: Number(deliverableId),
        allocated: true,
      }));
      dispatch(updateDeliverablesSuccess(allocatedDeliverables));

      if (AI_WORKFLOW_NAMES.includes(workflowName)) {
        const newQuery = `mutation generateContent($deliverableIds: [Int!], $workflowType: String) {
          generateContent(deliverableIds: $deliverableIds, workflowType: $workflowType)
        }`;
        const json = await postGraphQL(
          newQuery,
          { deliverableIds, workflowType },
          "generateContent"
        );
        dispatch(createAssignmentsSuccess(json));
      }

      const url = `/admin/projects/${projectId}`;
      history.push(url);
    } catch (err) {
      handleErrors(err);
    }
  };
}
