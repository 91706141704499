import React from "react";
import { QuillForm, QuillFormField } from "../../../../../components/QuillForm";
import {
  InputWithErrors,
  SelectWithErrors,
} from "../../../../../decorators/WithErrors";
import { reduxForm, Field } from "redux-form";
import ReduxMultiSelect from "../../../Projects/Id/Fields/components/FieldsForm/MultiSelect";
import validate from "./validation";

const fields = [
  { label: "Teams", value: "default_assignee" },
  { label: "Briefing Fields", value: "briefing_field" },
  { label: "Task fields", value: "task_field" },
  { label: "Batch names", value: "batch" },
  { label: "Rate bands", value: "rate_band" },
  { label: "Keyword groups", value: "keyword_group" },
  { label: "Word rates", value: "word_rate" },
  { label: "Export format", value: "export_format" },
  { label: "Rate band items", value: "rate_band_item" },
  { label: "Batch default deadlines", value: "batch_default_deadline" },
  { label: "Client team", value: "project_team" },
];

let ProjectCopy = (props) => {
  return (
    <QuillForm
      header={props.header}
      cancelText="Cancel"
      cancelHandler={props.hideModal}
      submitText="Save"
      disabled={props.submitting || !props.valid}
      handleSubmit={props.handleSubmit}
      error={props.error}
    >
      <QuillFormField
        labelText="Select Order Form"
        required
        name="orderFormId"
        options={props.orderForms}
        component={SelectWithErrors}
        type="text"
      />
      <QuillFormField
        labelText="Select project to copy from"
        required
        name="projectId"
        options={props.projects}
        component={SelectWithErrors}
        type="text"
      />
      <QuillFormField
        labelText="Target order form"
        required
        name="targetOrderFormId"
        options={props.orderForms}
        component={SelectWithErrors}
        type="text"
      />
      <QuillFormField
        labelText="New project name"
        required
        name="projectName"
        component={InputWithErrors}
        type="text"
      />
      <Field
        copyProject
        clearable={false}
        component={ReduxMultiSelect}
        name={"fields"}
        options={fields}
        type="text"
      />
    </QuillForm>
  );
};
ProjectCopy = reduxForm({
  form: "projectCopy",
  validate,
})(ProjectCopy);

export default ProjectCopy;
