import { connect } from "react-redux";
import { createSelector } from "reselect";
import WithData from "../../../../../../../decorators/WithData";
import { displayGradingFormIfApplicable } from "../../../../../../../modules/grades";
import {
  assertContentSaved,
  saveLocalContentToDBAndCreateTransitionLogs,
} from "../../../../../../../modules/taskFieldContent";
import List from "../components/List";
import { getInitialData } from "../modules/getInitialData";
import {
  assignmentGroupParentDeliverableEntitiesSelector,
  assignmentsByGroupSelector,
  briefingFieldValuesSelector,
  priorAndNextTransitionsSelector,
  projectBriefingFieldsSelector,
  stageById,
} from "../../../../../../../utils/entitySelector";
import { BriefingFieldFormat } from "../../../../../../../modules/briefingFields";

const dataSelector = createSelector(
  (state, assignments) => assignments,
  (state, assignments, briefingFieldValues) => briefingFieldValues,
  (state) => state.deliverables.entities,
  (state) => state.parentDeliverables.entities,
  (state) => state.featureToggles,
  (
    assignments,
    briefingFieldValues,
    deliverableEntities,
    parentDeliverableEntities,
    featureToggles
  ) => {
    const data = assignments
      .map(
        ({
          assignmentId,
          archived,
          deliverableId,
          actionable,
          previousAssignee,
          rate,
          status,
          wasAmended,
        }) => {
          const { parentDeliverableId } =
            deliverableEntities[deliverableId] || {};

          return {
            actionable,
            archived,
            briefingFieldValues: briefingFieldValues[parentDeliverableId],
            deliverableId,
            id: assignmentId,
            previousAssignee,
            rate,
            selectable: actionable, // only actionable assignments are selectable assignments for freelancers
            status,
            viewable: status !== "Not Actioned" && status !== "Rejected",
            wasAmended,
          };
        }
      )
      // we only show rows if they are not archived or both archived and approved
      .filter(
        ({ archived, status }) =>
          !archived || (archived && status === "Approved")
      );

    if (featureToggles.QCC_1396_splitActionableAssignments) {
      // sort by actionable, then by id
      data.sort(
        (a, b) =>
          a.actionable !== b.actionable
            ? b.actionable - a.actionable // actionable first
            : a.id - b.id // then id ascending
      );
    }

    return data;
  }
);

const projectFromAssignmentsSelector = createSelector(
  (state, assignments) => assignments[0],
  (state) => state.deliverables.entities,
  (state) => state.parentDeliverables.entities,
  (state) => state.projects.entities,
  (assignment, deliverables, parentDeliverables, projects) => {
    if (!assignment || !deliverables || !parentDeliverables || !projects)
      return {};

    const { deliverableId } = assignment || {};
    const { parentDeliverableId } = deliverables[deliverableId] || {};
    const { projectId } = parentDeliverables[parentDeliverableId] || {};
    return projects[projectId] || {};
  }
);

const accountSelector = createSelector(
  (state, orderFormId) => state.orderForms.entities?.[orderFormId],
  (state) => state.accounts.entities,
  (orderForm, accounts) => {
    return accounts?.[orderForm?.accountId] || {};
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: { params },
  } = ownProps;

  return {
    getInitialData: () => dispatch(getInitialData(ownProps.match.params)),
    transitionAssignments: async (transition, assignmentIds, callbackObj) => {
      return dispatch(async (_, getState) => {
        const {
          assignments: { entities },
        } = getState();

        const deliverableIds = assignmentIds
          .map((assignmentId) => {
            const assignment = entities[assignmentId];
            return assignment && assignment.deliverableId;
          })
          .filter((id) => id);

        const assignmentGroupId = Number(params.assignmentGroupId);

        await dispatch(assertContentSaved(assignmentIds, callbackObj));

        await dispatch(
          displayGradingFormIfApplicable(
            transition,
            assignmentGroupId,
            deliverableIds
          )
        );

        const transitionLogsResponse = await dispatch(
          saveLocalContentToDBAndCreateTransitionLogs(
            transition,
            assignmentIds,
            callbackObj
          )
        );

        return transitionLogsResponse;
      });
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const assignmentGroupId = Number(ownProps.match.params.assignmentGroupId);
  const assignments = assignmentsByGroupSelector(state, assignmentGroupId);
  const project = projectFromAssignmentsSelector(state, assignments);

  const { projectId, projectName, orderFormId, workflowId } = project;
  const briefingFields = projectId
    ? projectBriefingFieldsSelector(state, projectId).filter(
        (bf) => bf.briefingFieldFormat !== BriefingFieldFormat["AI attribute"]
      )
    : [];

  const assignmentGroupParentDeliverableEntities =
    assignmentGroupParentDeliverableEntitiesSelector(state, assignments);
  const briefingFieldValues = briefingFieldValuesSelector(
    state,
    assignmentGroupParentDeliverableEntities,
    briefingFields
  );
  const data = dataSelector(state, assignments, briefingFieldValues);

  const [assignment = {}] = assignments;
  const { deliverableId, stageId } = assignment;

  const transitions = assignment
    ? priorAndNextTransitionsSelector(state, stageId, deliverableId, workflowId)
    : [];

  const { accountName = "" } = accountSelector(state, orderFormId);
  const stage = stageById(state, stageId);

  const errors = state.errors;

  return {
    assignmentGroupId,
    data,
    briefingFields,
    transitions,
    stage,
    projectId,
    projectName,
    accountName,
    errors,
  };
};

const FreelancerAssignments = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(List));
export default FreelancerAssignments;
