import { connect } from "react-redux";
import { createSelector } from "reselect";
import { formValueSelector } from "redux-form";
import {
  accountById,
  batchesDropdownSelector,
  batchParentDeliverableEntitiesSelector,
  defaultAssigneesByStageAndLanguageSelector,
  isProjectLocalisation,
  orderFormById,
  parentAccountById,
  projectById,
  projectLanguagesWithFullNameSelector,
  rateBandItemsSelector,
  rateBandsByProjectIdSelector,
  deliverablesSelector,
} from "../../../../../../../utils/entitySelector";
import {
  AI_STAGES,
  getAssignableWorkflowStages,
} from "../../../../../../../modules/stages";
import { createAssignments } from "../modules/createAssignments";
import { getInitialData } from "../modules/getInitialData";
import AssignmentsNew from "../components/AssignmentsNew";
import WithData from "../../../../../../../decorators/WithData";
import { clearAssignmentsForm } from "../../../../../../../modules/assignments";
import { quotedRatesByProjectId } from "../../../../../../../modules/quotedRates";
import { unallocatedParentDeliverables } from "./selectors";
import { fetchDeliverablesByBatchId } from "../../../../../../../modules/deliverables";

const mapDispatchToProps = (dispatch) => ({
  getInitialData: (params) => dispatch(getInitialData(params)),
  createAssignments: (data, history, params, options) =>
    dispatch(createAssignments(data, history, params, options)),
  clearAssignmentsForm: () => dispatch(clearAssignmentsForm()),
  fetchDeliverablesByBatchId: (batchId) =>
    dispatch(fetchDeliverablesByBatchId(batchId)),
});

/*
  maps deliverables into an object of languages and array of deliverableIds:
  {
    en-GB: [id1, id2 ...]
  }
  // this is needed for assigning the id to a person by language
*/
const deliverableIdsSelector = createSelector(
  (state) => state.deliverables.entities,
  (state) => state.deliverables.result,
  (_s, parentDeliverables) => parentDeliverables,
  (entities, result, parentDeliverables) => {
    const deliverables = {
      allocated: {},
      unallocated: {},
    };

    result.forEach((id) => {
      const { parentDeliverableId, allocated, languageCode } = entities[id];

      if (parentDeliverables[parentDeliverableId]) {
        const allocatedKey = allocated ? "allocated" : "unallocated";

        if (!deliverables[allocatedKey][languageCode]) {
          deliverables[allocatedKey][languageCode] = [];
        }
        deliverables[allocatedKey][languageCode].push(id);
      }
    });

    return deliverables;
  }
);

const filterProjectLanguagesSelector = createSelector(
  batchParentDeliverableEntitiesSelector,
  deliverablesSelector,
  (parentDeliverables, deliverables) => {
    const languageCodes = new Set();
    for (const deliverable of deliverables) {
      if (
        deliverable.parentDeliverableId in parentDeliverables &&
        !deliverable.allocated
      ) {
        languageCodes.add(deliverable.languageCode);
      }
    }
    return languageCodes;
  }
);

const getDeliverableRateBands = createSelector(
  (deliverables) => deliverables,
  (_, parentDeliverables) => parentDeliverables,
  (_, _pd, rateBandItems) => rateBandItems,
  (deliverables, parentDeliverables, rateBandItems) => {
    return deliverables.reduce(
      (acc, { deliverableId, parentDeliverableId }) => {
        const { rateBandId } = parentDeliverables[parentDeliverableId] || {};

        acc[deliverableId] =
          {
            ...rateBandItems[rateBandId],
            rateBandId,
          } || {};
        return acc;
      },
      {}
    );
  }
);

const getUnallocatedLanguages = createSelector(
  (projectLanguages) => projectLanguages,
  (_pl, parentDeliverables) => parentDeliverables,
  (_pl, _pd, deliverables) => deliverables,
  (_pl, _pd, _d, isLocalisation) => isLocalisation,
  (projectLanguages, parentDeliverables, deliverables, isLocalisation) => {
    return projectLanguages.reduce((obj, { languageCode }) => {
      obj[languageCode] = unallocatedParentDeliverables(
        parentDeliverables,
        deliverables,
        languageCode,
        isLocalisation
      );
      return obj;
    }, {});
  }
);

const batchFormSelector = formValueSelector("batchForm");

const mapStateToProps = (state, ownProps) => {
  const {
    form: {
      assignmentsForm: { values: { assignees = {} } = {} } = {},
      batchForm,
    },
  } = state;
  const projectId = Number(ownProps.match.params.projectId);

  const { numParentDeliverables, batchId: selectedBatchId } = batchFormSelector(
    state,
    "numParentDeliverables",
    "batchId"
  );

  const project = projectById(state, projectId);
  const batches = batchesDropdownSelector(state, projectId);
  const stages = getAssignableWorkflowStages(state, project.workflowId);
  const orderForm = orderFormById(state, project.orderFormId);
  const account = accountById(state, orderForm.accountId);
  const parentAccount = parentAccountById(state, account.parentAccountId);

  const isAiContentWorkflow = stages?.some((st) =>
    AI_STAGES.includes(st.stageName)
  );

  const projectLanguages = projectLanguagesWithFullNameSelector(
    state,
    projectId
  );

  const filteredProjectLanguages = filterProjectLanguagesSelector(
    state,
    selectedBatchId
  );

  const parentDeliverables = batchParentDeliverableEntitiesSelector(
    state,
    selectedBatchId
  );

  const defaultAssignees = defaultAssigneesByStageAndLanguageSelector(
    state,
    projectId
  );

  const rateBands = rateBandsByProjectIdSelector(state, projectId);
  const rateBandItems = rateBandItemsSelector(state, rateBands, projectId);

  const deliverableIds = deliverableIdsSelector(state, parentDeliverables);
  const deliverables = deliverablesSelector(state);

  const isLocalisation = isProjectLocalisation(state, projectId);

  const languageCode = isLocalisation
    ? batchForm?.values?.projectLanguages
    : projectLanguages?.[0]?.languageCode;

  const maxNumParentDeliverables = unallocatedParentDeliverables(
    parentDeliverables,
    deliverables,
    languageCode,
    isLocalisation
  );

  const unallocatedLanguages = getUnallocatedLanguages(
    projectLanguages,
    parentDeliverables,
    deliverables,
    isLocalisation
  );

  const deliverableRatesMap = getDeliverableRateBands(
    deliverables,
    parentDeliverables,
    rateBandItems
  );

  const quotedRates = quotedRatesByProjectId(state, projectId);

  return {
    account,
    assignees,
    batches,
    defaultAssignees,
    deliverableIds,
    deliverableRatesMap,
    deliverables,
    featureToggles: state.featureToggles,
    filteredProjectLanguages,
    isLocalisation,
    languageCode,
    maxNumParentDeliverables,
    numParentDeliverables: Number(numParentDeliverables) || 0,
    orderForm,
    parentAccount,
    parentDeliverables,
    people: state.people.entities,
    project,
    projectLanguages,
    quotedRates,
    selectedBatchId,
    stages,
    unallocatedLanguages,
    isAiContentWorkflow,
  };
};

const ProjectAssignmentsNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithData(AssignmentsNew));
export default ProjectAssignmentsNew;
