import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../components/PageHeader";
import OverlayLoading from "../../../../../../components/OverlayLoading/v1";
import styles from "./ExportForm.module.scss";

import ExportFormatForm from "./ExportFormatForm";

class ExportFormat extends Component {
  render() {
    const {
      account: { accountName },
      dataReady,
      isLocalisationProject,
      history,
      orderForm: { orderFormNumber },
      project: { projectId, projectName },
      parentAccount: { parentAccountName },
    } = this.props;
    const baseUrl = `/admin/projects/${projectId}`;

    if (!dataReady) return <OverlayLoading />;

    const onSubmit = (values) => {
      this.props.updateExportFormat({ projectId, ...values }, history);
    };

    return (
      <div>
        <Helmet>
          <title>Export format</title>
        </Helmet>

        <PageHeader
          breadCrumbItems={[
            { url: `/admin`, text: "Parent accounts" },
            {
              text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
            },
            { url: `${baseUrl}`, text: projectName },
            { text: "Export format" },
          ]}
          title={`${projectName} - Export format`}
        />

        <div className={styles.filterContainer}>
          <ExportFormatForm
            initialValues={this.props.initialValues}
            isLocalisationProject={isLocalisationProject}
            onSubmit={onSubmit}
            projectId={projectId}
          />
        </div>
      </div>
    );
  }
}

ExportFormat.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
  dataReady: PropTypes.bool,
  initialValues: PropTypes.object,
  isLocalisationProject: PropTypes.bool,
  messages: PropTypes.object,
  orderForm: PropTypes.shape({
    orderFormNumber: PropTypes.number,
  }),
  parentAccount: PropTypes.shape({
    parentAccountName: PropTypes.string,
  }),
  project: PropTypes.shape({
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
  updateExportFormat: PropTypes.func,
};

export default ExportFormat;
