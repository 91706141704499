import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { toMysqlFormat } from "../utils/date";
import { upsertData } from "../utils/normalize";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import { toggleLoadingState } from "./editor";
import { RESET_INITIAL_STATE } from "./me";
import { createTransitionLogsFromDeliverables } from "./transitionLogs";
import { showErrors } from "./errors";
import { extractErrorFromObject } from "../utils/errors";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const fields =
  "personId, taskFieldId, deliverableId, taskFieldContentId, content, stageId, rawContent, createDate, personType";

export const taskFieldContentByAssignmentQuery = `taskFieldContent (assignmentId: $assignmentId) {
  ${fields}
}`;

export const taskFieldContentByAssignmentGroupQuery = `taskFieldContent (assignmentGroupId: $assignmentGroupId) {
  ${fields}
}`;

export const taskFieldContentByDeliverableQuery = `taskFieldContent (deliverableId: $deliverableId, stageId: $stageId) {
  ${fields}
}`;

export const taskFieldContentByBatchQuery = `taskFieldContent (batchId: $batchId, stageId: $stageId) {
  ${fields}
}`;

export const taskFieldContentByDeliverableIdsQuery = `taskFieldContent (deliverableIds: $deliverableIds) {
  ${fields}
}`;

export const latestNonClientContentQuery = `latestNonClientContent (deliverableIds: $deliverableId) {
  ${fields}
}`;

export const taskFieldContentByDeliverableIdsQueryV2 = `taskFieldContentV2 (deliverableIds: $deliverableId) {
  ${fields}
}`;

// ------------------------------------
// Utility functions
// ------------------------------------

export const getLocalTaskFieldContent = (personId, deliverableIds) => {
  const localContent = JSON.parse(localStorage.getItem(personId));
  if (!localContent) return {};

  const localContentToReturn = {};

  Object.keys(localContent).forEach((deliverableAndStageId) => {
    Object.keys(localContent[deliverableAndStageId]).forEach((taskFieldId) => {
      const [deliverableId, stageId] = deliverableAndStageId.split(".");
      if (!deliverableIds.includes(Number(deliverableId))) return;

      if (!localContentToReturn[deliverableId])
        localContentToReturn[deliverableId] = {};
      localContentToReturn[deliverableId][taskFieldId] = {
        ...localContent[deliverableAndStageId][taskFieldId],
        taskFieldId,
        deliverableId,
        stageId,
        personId,
      };
    });
  });

  return localContentToReturn;
};

/**
 * @param   {Object} state
 * @param   {number} deliverableId
 * @returns {Object[]} array of taskFieldContent records with the given
 *  deliverableId
 */
export const taskFieldContentByDeliverableId = createSelector(
  (state) => state.taskFieldContent.entities,
  (_, deliverableId) => deliverableId,
  (taskFieldContent, deliverableId) => {
    return Object.values(taskFieldContent).filter(
      (field) => field.deliverableId === deliverableId
    );
  }
);

const latestNonClientContent = createSelector(
  taskFieldContentByDeliverableId,
  (_, deliverableId) => deliverableId,
  (_, _d, stageId) => stageId,
  (state) => state.people.entities,
  (dbTaskFieldContent, deliverableId, stageId) => {
    const allTaskFieldContent = [...dbTaskFieldContent];
    return allTaskFieldContent.reduce((acc, cur) => {
      const { taskFieldId, personType } = cur;

      if (
        !acc[taskFieldId] ||
        (cur.createDate > acc[taskFieldId].createDate &&
          personType !== "Client")
      ) {
        acc[taskFieldId] = cur;
      }
      return acc;
    }, {});
  }
);

/**
 * This function takes both the database task field content and the local
 * storage content and returns whichever is the most recent for the
 * deliverable/taskField. This is so we overwrite any old content with
 * newer versions.
 *
 * @param   {Object}  state
 * @param   {number}  deliverableId
 * @param   {number}  stageId
 * @returns {Object}  task field content indexed by taskFieldId
 */
export const getLatestTaskFieldContentV2 = createSelector(
  taskFieldContentByDeliverableId,
  (_, deliverableId) => deliverableId,
  (_, _d, stageId) => stageId,
  (state) => typeof window !== "undefined" && window.localStorage[state.me],
  (dbTaskFieldContent, deliverableId, stageId, localTFCString) => {
    const allTaskFieldContent = [...dbTaskFieldContent];

    // if no LS return any content we have from the DB
    if (typeof localTFCString !== "undefined") {
      const localTFC = JSON.parse(localTFCString);
      const lsTaskFieldContentObj =
        localTFC[`${deliverableId}.${stageId}`] || {};
      const lsTaskFieldContentArr = Object.entries(lsTaskFieldContentObj).map(
        ([taskFieldId, tfc]) => ({ taskFieldId, ...tfc })
      );

      allTaskFieldContent.push(...lsTaskFieldContentArr);
    }

    // build a map for each task field picking the latest created
    return allTaskFieldContent.reduce((acc, cur) => {
      const { taskFieldId } = cur;

      if (!acc[taskFieldId] || cur.createDate > acc[taskFieldId].createDate) {
        acc[taskFieldId] = cur;
      }
      return acc;
    }, {});
  }
);

export const wasModifiedByClient = createSelector(
  getLatestTaskFieldContentV2,
  latestNonClientContent,
  (tfc, tfcClient) => {
    return Object.values(tfc).some(({ content, taskFieldId }) => {
      const nonClientTfc =
        Object.values(tfcClient).find(
          (clientTfc) => clientTfc.taskFieldId === taskFieldId
        ) || {};

      if (!nonClientTfc.taskFieldId) return false;
      return nonClientTfc.content !== content;
    });
  }
);

export const tfcByDeliverables = createSelector(
  (state) => state.taskFieldContent.entities,
  (state) => state.deliverables.entities,
  (state) => state.assignments.entities,
  (state) => typeof window !== "undefined" && window.localStorage[state.me],
  (_state, assignmentGroupId) => assignmentGroupId,
  (tfc, deliverables, assignments, localTFCString, assignmentGroupId) => {
    const [assignment] = Object.values(assignments).filter(
      (ag) => ag.assignmentGroupId === Number(assignmentGroupId)
    );
    const stageId = assignment && assignment.stageId;

    const deliverablesByAssignment = Object.values(deliverables).reduce(
      (acc, cur) => {
        Object.values(assignments).forEach((ass) => {
          if (
            cur.deliverableId === Number(ass.deliverableId) &&
            ass.assignmentGroupId === Number(assignmentGroupId) &&
            ass.actionable
          ) {
            acc.push(cur);
          }
        });
        return acc;
      },
      []
    );

    const taskFieldContentByDeliverable = deliverablesByAssignment.map(
      ({ deliverableId, currentStageId }) => {
        return Object.values(tfc).filter(
          (field) =>
            field.deliverableId === deliverableId && currentStageId === stageId
        );
      }
    );

    return taskFieldContentByDeliverable.map((del) => {
      const allTaskFieldContent = [...del];
      const deliverableId = del.length > 0 && del[0].deliverableId;

      if (typeof localTFCString !== "undefined" && deliverableId) {
        const localTFC = JSON.parse(localTFCString);
        const lsTaskFieldContentObj =
          localTFC[`${deliverableId}.${stageId}`] || {};
        const lsTaskFieldContentArr = Object.entries(lsTaskFieldContentObj).map(
          ([taskFieldId, tfc]) => ({ taskFieldId, deliverableId, ...tfc })
        );

        allTaskFieldContent.push(...lsTaskFieldContentArr);
      }

      return allTaskFieldContent.reduce((acc, cur) => {
        const { taskFieldId, createDate } = cur;
        if (!acc[taskFieldId] || createDate > acc[taskFieldId].createDate) {
          acc[taskFieldId] = {
            ...cur,
            cellId: `${taskFieldId}-${cur.deliverableId}`,
          };
        }
        return acc;
      }, {});
    });
  }
);

// takes two objects of DeliverableIdTaskFieldId and merges them together with the task_field_content being put in an array
export const mergeDeliverableTaskFieldObjects = (a, b) => {
  return addToDeliverableTaskFieldObject(
    addToDeliverableTaskFieldObject({}, a),
    b
  );
};

// adds all deliverableIds/taskFieldIds in an object to a base object and puts the TFC in an array
export const addToDeliverableTaskFieldObject = (base, obj) => {
  if (typeof obj === "undefined") return base;

  Object.keys(obj).forEach((deliverableId) => {
    Object.keys(obj[deliverableId]).forEach((taskFieldId) => {
      if (!base[deliverableId]) base[deliverableId] = {};
      if (!base[deliverableId][taskFieldId])
        base[deliverableId][taskFieldId] = [];

      base[deliverableId][taskFieldId].push(obj[deliverableId][taskFieldId]);
    });
  });
  return base;
};

export const getLatestTaskFieldContent = (taskFieldContent) => {
  const ret = [];

  Object.keys(taskFieldContent).forEach((deliverableId) => {
    Object.keys(taskFieldContent[deliverableId]).forEach((taskFieldId) => {
      const arr = taskFieldContent[deliverableId][taskFieldId];
      // sort the array in order of date
      arr.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
      // add in the most recent task_field_content to the array
      ret.push(arr[0]);
    });
  });

  return ret;
};

/**
 * Assert the given deliverables don't have unsaved content in local storage
 *
 * @param {number[]}  assignmentIds
 * @param {Object}    [callbackObj]         object to show a message with a callback
 * @param {string}    [callbackObj.message] callback message to show
 * @param {Function}  [callbackObj.fn]      function to call after the callback messsage is clicked
 */
export const assertContentSaved = (assignmentIds, callbackObj) => {
  return (dispatch, getState) => {
    const {
      assignments: { entities },
      me,
    } = getState();

    // create an array of assignment and deliverable ids because some functions
    // rely on the assignment id whereas others use the deliverable id
    const assignments = assignmentIds.map(
      (assignmentId) => entities[assignmentId]
    );
    const deliverableIds = assignments.map(
      ({ deliverableId }) => deliverableId
    );
    const localStorageContent = getLocalTaskFieldContent(me, deliverableIds);

    const unsavedAssignments = assignments.filter(
      ({ deliverableId }) =>
        typeof localStorageContent[deliverableId] === "object" &&
        Object.keys(localStorageContent[deliverableId]).length
    );

    if (unsavedAssignments.length > 0) {
      const { unsavedDeliverableIds, unsavedAssignmentIds } =
        unsavedAssignments.reduce(
          (acc, { assignmentId, deliverableId }) => {
            acc.unsavedDeliverableIds.push(deliverableId);
            acc.unsavedAssignmentIds.push(assignmentId);

            return acc;
          },
          { unsavedDeliverableIds: [], unsavedAssignmentIds: [] }
        );

      const error =
        `You have unsaved content for the following deliverables: ${unsavedDeliverableIds.join(
          ", "
        )}. ` +
        "Please click through to these deliverables and submit them from their individual task pages";

      // base errors object
      const errors = {
        error,
      };

      // add a custom callback param if specified
      // currently only used for unselecting unsaved deliverables
      if (callbackObj) {
        errors.callback = {
          ...callbackObj,
          data: unsavedAssignmentIds,
        };
      }

      dispatch(showErrors(errors));
      throw new Error(error);
    }
  };
};

export const checkForUnconfirmedTMSegments = (assignmentIds) => {
  return async (_, getState) => {
    const { featureToggles } = getState();

    if (!featureToggles.QCC_1801_unconfirmedSegmentsWarning) {
      return { status: "success" };
    }

    const url = new URL(
      `${window.__API_GATEWAY__}/tm-success?assignmentIds=${assignmentIds.join(
        ","
      )}`
    );
    const data = await fetch(url);
    return data.json();
  };
};

/**
 * Save task field content (comparing to local storage) then transition the
 * deliverables.
 *
 * The task field content saving has been removed as part of
 * QCC-1828 to mitigate performance issues, but the function still checks for
 * relevant unsaved local storage content and will throw an error if any exists
 *
 * @param {Object} transition
 * @param {number[]} assignmentIds
 * @param {Object} [callbackObj] object to show a message with a callback
 * @param {string} [callbackObj.message] callback message to show
 * @param {Function} [callbackObj.fn] function to call after the callback messsage is clicked
 */
export const saveLocalContentToDBAndCreateTransitionLogs = (
  transition,
  assignmentIds,
  callbackObj
) => {
  return async (dispatch, getState) => {
    await assertContentSaved(assignmentIds, callbackObj);

    const {
      assignments: { entities: assignmentEntities },
    } = getState();

    // create an array of assignment and deliverable ids because some functions
    // rely on the assignment id whereas others use the deliverable id
    const deliverableIds = assignmentIds.map(
      (assignmentId) => assignmentEntities[assignmentId].deliverableId
    );

    try {
      return dispatch(
        createTransitionLogsFromDeliverables(transition, deliverableIds)
      );
    } catch (err) {
      dispatch(showErrors(extractErrorFromObject(err)));
      throw err;
    }
  };
};

export function discardClientChanges({ deliverableId, stageId }) {
  return async (dispatch) => {
    const query = `mutation discardClientChanges($input: DiscardClientChangesInput){
    discardClientChanges(input: $input) {
      personId, taskFieldId, deliverableId, taskFieldContentId, content, rawContent, stageId, createDate
    }
  }`;
    const input = { deliverableId };

    const taskFieldContent = await postGraphQL(
      query,
      { input },
      "discardClientChanges"
    );
    dispatch(fetchTaskFieldContentSuccess(taskFieldContent));
  };
}

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_TASK_FIELD_CONTENT = "FETCH_TASK_FIELD_CONTENT";
export const CREATE_TASK_FIELD_CONTENT = "CREATE_TASK_FIELD_CONTENT";

// ------------------------------------
// Actions
// ------------------------------------
export const fetchTaskFieldContentSuccess = createAction(
  FETCH_TASK_FIELD_CONTENT
);
export const createTaskFieldContentSuccess = createAction(
  CREATE_TASK_FIELD_CONTENT
);

export function autosaveContent(params) {
  const { assignmentId } = params; // Freelancer / Inhouse screen's
  let { deliverableId, stageId } = params; // Client screen

  return (dispatch, getState) => {
    /* eslint-disable no-unused-vars */
    const {
      editor: { loading, ...editor },
      assignments: { entities },
      me,
      deliverables,
    } = getState();
    /* eslint-enable no-unused-vars */

    // If there is assignment we know how to get the deliverableId and stageId
    if (assignmentId) {
      const assignment = entities[assignmentId];
      deliverableId = assignment.deliverableId;
      stageId = assignment.stageId;
    }

    // Unique key for this page
    const deliverableStageKey = `${deliverableId}.${stageId}`;

    const localItems = JSON.parse(window.localStorage.getItem(me)) || {};
    const deliverableStageLocalItem = localItems[deliverableStageKey] || {};

    let changed = false;

    Object.keys(editor).forEach((taskFieldId) => {
      const task = editor[taskFieldId];
      const content = task.content;
      const rawContent = task.rawContent;

      const taskFieldLocalItem = deliverableStageLocalItem[taskFieldId] || {};

      if (taskFieldLocalItem.content !== content) {
        changed = true;
        if (!localItems[deliverableStageKey]) {
          localItems[deliverableStageKey] = {};
        }
        localItems[deliverableStageKey][taskFieldId] = {
          content,
          rawContent,
          createDate: toMysqlFormat(new Date()),
        };
      }
    });

    const isEmpty = Object.keys(localItems).length === 0;

    if (!isEmpty && changed) {
      window.localStorage.setItem(me, JSON.stringify(localItems));
    }
  };
}

export function saveTaskFieldContentAutosave(
  params,
  taskFieldId = params.taskFieldId
) {
  return async (dispatch, getState) => {
    const {
      editor: { loading, ...editor },
      assignments: { entities },
      me,
    } = getState();

    let stageId, deliverableId, rawContent, content;

    if (params.assignmentId) {
      const assignment = entities[params.assignmentId];
      stageId = assignment.stageId;
      deliverableId = assignment.deliverableId;
    } else if (params.stageId && params.deliverableId) {
      stageId = params.stageId;
      deliverableId = params.deliverableId;
    }

    if (
      typeof params.content === "undefined" ||
      typeof params.rawContent === "undefined"
    ) {
      const taskFieldContent = editor[taskFieldId] || {};
      content = taskFieldContent.content;
      rawContent = taskFieldContent.rawContent;
    } else {
      content = params.content;
      rawContent = params.rawContent;
    }

    const data = [
      {
        stageId,
        deliverableId,
        taskFieldId,
        content,
        rawContent,
        personId: me,
      },
    ];

    try {
      return await dispatch(createTaskFieldContentAtServer(data, false));
    } catch (err) {
      throw err;
    }
  };
}

export function createTaskFieldContentForAllTaskFields(params, transition) {
  return (dispatch, getState) => {
    /* eslint-disable no-unused-vars */
    const {
      editor: { loading, ...editor },
      assignments: { entities },
      me,
      commentGroups,
    } = getState();
    /* eslint-enable no-unused-vars */

    let stageId, deliverableId;

    if (params.assignmentId) {
      const assignment = entities[params.assignmentId];
      stageId = assignment.stageId;
      deliverableId = assignment.deliverableId;
    } else if (params.stageId && params.deliverableId) {
      stageId = params.stageId;
      deliverableId = params.deliverableId;
    }

    const data = [];

    // TODO: Check if transition.clear_stage_id exists and
    // then remove all comments in the rawContent.blocks[N].inlineStyles
    // which match a comment_group which has at_stage set to the clear_stage_id

    Object.keys(editor).forEach((taskFieldId) => {
      let rawContent = editor[taskFieldId].rawContent || "";

      // If the transition has a clear stage then remove all comments that match the stage
      if (transition && transition.clearStageId && rawContent !== "") {
        // We need to check all of the inlineStyles in the blocks
        const parsedContent = JSON.parse(rawContent);

        // Map over the blocks and update any if necessary
        parsedContent.blocks = parsedContent.blocks.map((block) => {
          const newInlineStyleRanges = [];

          // Map over the inlineStyleRanges to check all of the styles
          block.inlineStyleRanges.map((inlineStyle) => {
            // Check if first 8 characters are 'COMMENT-'
            if (inlineStyle.style.substring(0, 8) !== "COMMENT-") {
              return newInlineStyleRanges.push(inlineStyle);
            }

            // We know that it is a comment style, so extract the ID
            const id = parseInt(inlineStyle.style.substring(8));

            // Now find the comment group and check the stage id
            const commentGroup =
              commentGroups.entities[
                commentGroups.result.filter(
                  (r) => commentGroups.entities[r].commentGroupId === id
                )[0]
              ];

            if (typeof commentGroup !== "undefined") {
              if (commentGroup.atStage !== transition.clearStageId) {
                newInlineStyleRanges.push(inlineStyle);
              }
            }
            return inlineStyle;
          });
          // Update the block styles to be the new style without current stage comments
          block.inlineStyleRanges = newInlineStyleRanges;

          return block;
        });

        rawContent = JSON.stringify(parsedContent);
      }

      data.push({
        stageId,
        deliverableId,
        taskFieldId,
        content: editor[taskFieldId].content,
        rawContent,
        personId: me,
      });
    });

    return dispatch(createTaskFieldContentAtServer(data, true));
  };
}

/**
 * We now allow rawContent to be NULL for TM tasks to update the database when
 * all segments in a TM task are unconfirmed (to update the source of truth)
 *
 * @param {Object[]} tfcRows array of task field content rows
 */
const removeNullTaskFieldContent = (tfcRows) =>
  tfcRows.map((tfc) => {
    const { rawContent, ...rest } = tfc;

    if (!rawContent) return rest;

    return tfc;
  });

export function createTaskFieldContentAtServer(
  data,
  isTransitioning,
  showLoadingState = true
) {
  return async (dispatch, getState) => {
    const query = `mutation createTaskFieldContent($input: [TaskFieldContentInput]){
      createTaskFieldContent(input: $input) {
        personId, taskFieldId, deliverableId, taskFieldContentId, content, rawContent, stageId, createDate
      }
    }`;

    if (showLoadingState) {
      dispatch(toggleLoadingState(true));
    }

    try {
      const json = await postGraphQL(
        query,
        { input: removeNullTaskFieldContent(data) },
        "createTaskFieldContent"
      );
      dispatch(createTaskFieldContentSuccess(json));

      if (isTransitioning) {
        const { me } = getState();
        const storageObj = window.localStorage.getItem(me);

        // only if the object exists in localStorage we should try to process it
        if (storageObj) {
          const storageObjParsed = JSON.parse(storageObj);

          // for each result of taskFieldContent we need to delete the assignment (deliverableId.stageId) entry from the localStorage
          for (const content of json) {
            const { deliverableId, stageId } = content;
            const deliverableAndStageId = `${deliverableId}.${stageId}`;
            delete storageObjParsed[deliverableAndStageId];
          }

          // replace the current object with the removed assignments
          window.localStorage.setItem(me, JSON.stringify(storageObjParsed));
        }
      }

      return json;
    } catch (err) {
      throw err;
    } finally {
      if (showLoadingState) {
        dispatch(toggleLoadingState(false));
      }
    }
  };
}

export function getTaskFieldContentByAssignmentGroupId(params) {
  return (dispatch, getState) => {
    const query = `query getTaskFieldContentByAssignmentGroupId ($assignmentGroupId: String) {
      ${taskFieldContentByAssignmentGroupQuery}
    }`;

    return getGraphQL(query, params)
      .then((json) => {
        const { me: personId } = getState();
        const { taskFieldContent } = json;

        dispatch(fetchTaskFieldContentSuccess(taskFieldContent));
        return { personId, taskFieldContent };
      })
      .catch((err) => err);
  };
}

export function getLatestContentByDeliverableId(deliverableId, stageId) {
  return async (dispatch, getState) => {
    const query = `query getTaskFieldContentByDeliverableId ($deliverableId: String, $stageId: String) {
      ${taskFieldContentByDeliverableQuery}
    }`;

    const { taskFieldContent } = await getGraphQL(query, {
      deliverableId,
      stageId,
    });
    dispatch(fetchTaskFieldContentSuccess(taskFieldContent));
  };
}
// ------------------------------------
// Action Handlers
// ------------------------------------
export const taskFieldContentActionHandlers = {
  [RESET_INITIAL_STATE]: () => taskFieldContentInitialState,
  [FETCH_TASK_FIELD_CONTENT]: (state, { payload }) =>
    upsertData(state, payload, "taskFieldContentId"),
  [CREATE_TASK_FIELD_CONTENT]: (state, { payload }) =>
    upsertData(state, payload, "taskFieldContentId"),
};

export const taskFieldContentInitialState = { entities: {}, result: [] };
