import React from "react";
import PropTypes from "prop-types";
import OverlayLoading from "../../../../../../../../components/OverlayLoading/v1";
import ProgressTab from "../../../../../../../../components/ProgressTab/v3";

export const Completed = ({ dataReady, history, location, match }) => {
  if (!dataReady) return <OverlayLoading />;

  const projectId = Number(match.params.projectId);
  const baseUrl = `/admin/projects/${projectId}`;

  return (
    <ProgressTab
      baseUrl={baseUrl}
      history={history}
      location={location}
      projectId={projectId}
      selectedStageTypes={["Client"]}
      showFinal
    />
  );
};

Completed.propTypes = {
  dataReady: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }),
};

export default Completed;
